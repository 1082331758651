import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import styles from "./SpaceAdventures.module.scss";

export default function SpaceAdventures() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState("Engineering");
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [selectedRightTab, setSelectedRightTab] = useState("inventory");
  const [playerInventory, setPlayerInventory] = useState([]);
  const [skillData, setSkillData] = useState({});
  const [allSkillActions, setAllSkillActions] = useState({});
  const [itemInfo, setItemInfo] = useState({});
  const [activeAction, setActiveAction] = useState(null);
  const [localProgress, setLocalProgress] = useState(0);
  const [offlineReport, setOfflineReport] = useState(null);
  const [isPortraitMobile, setIsPortraitMobile] = useState(false);
  const [showMobileInventory, setShowMobileInventory] = useState(false);
  const [skillsCollapsed, setSkillsCollapsed] = useState(false);

  useEffect(() => {
    function handleResize() {
      const portrait = window.innerHeight > window.innerWidth;
      const mobile = window.innerWidth <= 768;
      if (portrait && mobile) {
        setIsPortraitMobile(true);
      } else {
        setIsPortraitMobile(false);
        setShowMobileInventory(false);
        setSkillsCollapsed(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    let newWs;
    switch (environment) {
      case "localhost":
        newWs = new WebSocket("ws://localhost:5000/space-adventures");
        break;
      case "localNetwork":
        newWs = new WebSocket("ws://192.168.0.7:5000/space-adventures");
        break;
      case "production":
        newWs = new WebSocket("wss://2nguyen.dev/space-adventures");
        break;
      default:
        return;
    }
    setWs(newWs);
    newWs.onopen = () => {
      newWs.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );
      newWs.send(
        JSON.stringify({
          type: "skill",
          action: "getAllActions",
        })
      );
    };
    newWs.onclose = () => {
      setWs(null);
    };
    newWs.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "chat" && data.action === "message") {
        setChatMessages(prev => [...prev, data.message]);
      }
      if (data.type === "skill" && data.action === "allActions") {
        setAllSkillActions(data.actions);
        setItemInfo(data.itemsInfo || {});
      }
      if (data.type === "skill" && data.action === "updatePlayerData") {
        setSkillData(data.skills);
        setPlayerInventory(data.inventory);
        if (data.activeAction) {
          setActiveAction({ ...data.activeAction });
        } else {
          setActiveAction(null);
          setLocalProgress(0);
        }
        if (data.offlineReport) {
          setOfflineReport(data.offlineReport);
        }
      }
      if (data.type === "account" && data.action === "boot") {
        Swal.fire({
          title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">System Boot</span>`,
          text: data.message,
          background: "#000000",
          backdrop: "rgba(0,0,0,0.8)",
          confirmButtonColor: "#1b5e20",
        });
      }
      if (data.type === "skill" && data.action === "error") {
        Swal.fire({
          title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">Error</span>`,
          text: data.message,
          background: "#000000",
          backdrop: "rgba(0,0,0,0.8)",
          confirmButtonColor: "#1b5e20",
          icon: "error",
        });
      }
    };
    return () => {
      if (newWs) newWs.close();
    };
  }, [user]);

  useEffect(() => {
    if (!activeAction) {
      setLocalProgress(0);
      return;
    }
    let intervalId = setInterval(() => {
      const now = Date.now();
      const { accumulatedTime, lastUpdate, timeMs } = activeAction;
      const delta = now - lastUpdate;
      const total = accumulatedTime + delta;
      let fraction = (total % timeMs) / timeMs;
      setLocalProgress(Math.floor(fraction * 100));
    }, 100);
    return () => clearInterval(intervalId);
  }, [activeAction]);

  useEffect(() => {
    if (offlineReport) {
      let itemsText = offlineReport.itemsGained
        .map(item => `+${item.quantity} ${item.name}`)
        .join("<br/>");
      let levelText = "";
      if (offlineReport.gainedLevels > 0) {
        levelText = `and your level went from ${offlineReport.oldLevel} to ${offlineReport.newLevel}`;
      }
      Swal.fire({
        title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">Offline Progress</span>`,
        html: `
          While you were away, your ${offlineReport.skill} action yielded:
          <br/>+${offlineReport.xpGained} XP ${
          levelText ? `<br/>${levelText}` : ""
        }
          <br/>${itemsText}
        `,
        icon: "info",
        confirmButtonText: "OK",
        background: "#000000",
        backdrop: "rgba(0,0,0,0.8)",
        confirmButtonColor: "#1b5e20",
      });
      setOfflineReport(null);
    }
  }, [offlineReport]);

  function handleSkillClick(skill) {
    setSelectedSkill(skill);
    setShowMobileInventory(false);
  }

  function handleChatSend() {
    if (!chatInput.trim()) return;
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "chat",
          action: "message",
          message: `${user.username}: ${chatInput}`,
        })
      );
    }
    setChatInput("");
  }

  function handleChatKeyDown(e) {
    if (e.key === "Enter") {
      handleChatSend();
    }
  }

  function findItemRequirements(itemName) {
    let results = [];
    Object.keys(allSkillActions).forEach(skillName => {
      allSkillActions[skillName].forEach(action => {
        if (
          action.requiredItems &&
          action.requiredItems.some(r => r.name === itemName)
        ) {
          results.push({ skillName, actionName: action.name });
        }
      });
    });
    return results;
  }

  function findSkillActionByName(actionName) {
    for (let skillName in allSkillActions) {
      let found = allSkillActions[skillName].find(a => a.name === actionName);
      if (found) {
        return { skillName, action: found };
      }
    }
    return null;
  }

  function openItemLibraryModal(itemName) {
    const info = itemInfo[itemName] || {};
    let skillLink = info.obtainedFromSkill
      ? `<span class="obtainedSkillLink" data-skill="${info.obtainedFromSkill}" style="text-decoration:underline;cursor:pointer;color:cyan;">${info.obtainedFromSkill}</span>`
      : "Unknown";
    let actionLink = info.obtainedFrom
      ? `<span class="obtainedActionLink" data-action="${info.obtainedFrom}" style="text-decoration:underline;cursor:pointer;color:cyan;">${info.obtainedFrom}</span>`
      : "Unknown";
    let reqs = findItemRequirements(itemName);
    let reqsText = "";
    if (reqs.length > 0) {
      reqsText = reqs
        .map(
          r => `
            <li>
              <span class="obtainedSkillLink" data-skill="${r.skillName}" style="color:cyan;text-decoration:underline;cursor:pointer;">${r.skillName}</span>
              : 
              <span class="requiredActionLink" data-skill="${r.skillName}" data-action="${r.actionName}" style="color:cyan;text-decoration:underline;cursor:pointer;">${r.actionName}</span>
            </li>`
        )
        .join("");
      reqsText = `<ul style="margin-top:5px; list-style:none; padding-left:0;">${reqsText}</ul>`;
    } else {
      reqsText = "None";
    }
    Swal.fire({
      title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">${itemName}</span>`,
      html: `
        <p><strong>Sell Value:</strong> ${info.value || 0}</p>
        <p><strong>Description:</strong> ${
          info.description || "No description"
        }</p>
        <p><strong>Acquired From Skill:</strong> ${skillLink}</p>
        <p><strong>Acquired From Action:</strong> ${actionLink}</p>
        <p><strong>Required For:</strong> ${reqsText}</p>
      `,
      showCloseButton: true,
      showConfirmButton: false,
      background: "#000000",
      backdrop: "rgba(0,0,0,0.8)",
      willOpen: popup => {
        popup.querySelectorAll(".obtainedSkillLink").forEach(el => {
          el.addEventListener("click", () => {
            Swal.close();
            handleSkillClick(el.getAttribute("data-skill"));
          });
        });
        popup.querySelectorAll(".requiredActionLink").forEach(el => {
          el.addEventListener("click", () => {
            const skill = el.getAttribute("data-skill");
            const action = el.getAttribute("data-action");
            Swal.close();
            let actions = allSkillActions[skill] || [];
            let found = actions.find(a => a.name === action);
            if (found) {
              openActionModal(found);
            }
          });
        });
        popup.querySelectorAll(".obtainedActionLink").forEach(el => {
          el.addEventListener("click", () => {
            const actionName = el.getAttribute("data-action");
            const res = findSkillActionByName(actionName);
            if (res) {
              Swal.close();
              openActionModal(res.action);
            }
          });
        });
      },
    });
  }

  function openItemSellModal(item) {
    const info = itemInfo[item.name] || {};
    Swal.fire({
      title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">${item.name}</span>`,
      html: `
        <div style="font-family: 'Orbitron', sans-serif; color: #ffffff;">
          <p>Sell Value: ${info.value || 0} credits each</p>
          <p>Description: ${info.description || "No description"}</p>
          <p>Quantity to sell: <input id="sellQty" type="number" min="1" max="${
            item.quantity
          }" value="1" style="width:60px;" /></p>
        </div>
      `,
      background: "#000000",
      backdrop: "rgba(0,0,0,0.8)",
      showCancelButton: true,
      confirmButtonText: "Sell",
      confirmButtonColor: "#1b5e20",
      cancelButtonColor: "#b71c1c",
      preConfirm: () => {
        const qtyInput = document.getElementById("sellQty");
        if (!qtyInput) return 0;
        const qty = parseInt(qtyInput.value);
        if (isNaN(qty) || qty < 1) return 0;
        return qty;
      },
    }).then(result => {
      if (result.isConfirmed) {
        const quantityToSell = result.value;
        if (!quantityToSell || quantityToSell <= 0) return;
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(
            JSON.stringify({
              type: "skill",
              action: "sellItem",
              itemName: item.name,
              quantity: quantityToSell,
            })
          );
        }
      }
    });
  }

  function openItemOptions(itemName) {
    let invItem = playerInventory.find(i => i.name === itemName);
    if (!invItem) {
      Swal.fire({
        title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">${itemName}</span>`,
        text: "You have none of this item. View the item library?",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "View Library",
        background: "#000000",
        backdrop: "rgba(0,0,0,0.8)",
        confirmButtonColor: "#1b5e20",
        cancelButtonColor: "#b71c1c",
      }).then(result => {
        if (result.isConfirmed) {
          openItemLibraryModal(itemName);
        }
      });
    } else {
      Swal.fire({
        title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">${itemName}</span>`,
        text: "Choose an option",
        showDenyButton: true,
        denyButtonText: "View Library",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Sell",
        confirmButtonColor: "#1b5e20",
        denyButtonColor: "#00bcd4",
        cancelButtonColor: "#b71c1c",
        background: "#000000",
        backdrop: "rgba(0,0,0,0.8)",
      }).then(result => {
        if (result.isConfirmed) {
          openItemSellModal(invItem);
        } else if (result.isDenied) {
          openItemLibraryModal(itemName);
        }
      });
    }
  }

  function openActionModal(action) {
    const xpReward = action.xpReward || 0;
    const itemReward = action.itemReward || "None";
    const requiredItems = action.requiredItems || [];
    const requiredList = requiredItems
      .map(
        req =>
          `<li><span class="requiredItemDetail" data-item="${req.name}" style="color:cyan;text-decoration:underline;cursor:pointer;">${req.name}</span> x ${req.quantity}</li>`
      )
      .join("");
    Swal.fire({
      title: `<span style="font-family: 'Orbitron', sans-serif; color: #00faff; text-shadow: 0 0 5px #00faff;">${action.name}</span>`,
      html: `
        <div style="text-align: left;">
          <p><strong>XP Reward:</strong> ${xpReward}</p>
          <p><strong>Item Reward:</strong> ${
            itemReward !== "None"
              ? `<span class="openItemDetail" data-item="${itemReward}" style="color:cyan;text-decoration:underline;cursor:pointer;">${itemReward}</span>`
              : "None"
          }</p>
          ${
            requiredItems.length
              ? `<p><strong>Required Items:</strong></p><ul style="list-style:none; padding-left:0;">${requiredList}</ul>`
              : ""
          }
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      background: "#000000",
      backdrop: "rgba(0,0,0,0.8)",
      confirmButtonColor: "#1b5e20",
      cancelButtonColor: "#b71c1c",
      didOpen: popup => {
        popup.querySelectorAll(".openItemDetail").forEach(el => {
          el.addEventListener("click", () => {
            const item = el.getAttribute("data-item");
            Swal.close();
            openItemOptions(item);
          });
        });
        popup.querySelectorAll(".requiredItemDetail").forEach(el => {
          el.addEventListener("click", () => {
            const item = el.getAttribute("data-item");
            Swal.close();
            openItemOptions(item);
          });
        });
      },
    }).then(result => {
      if (result.isConfirmed) {
        handleActionClick(action);
      }
    });
  }

  function handleActionClick(action) {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "skill",
          action: "startAction",
          skill: selectedSkill,
          actionName: action.name,
        })
      );
    }
  }

  function canAffordAction(action) {
    if (!action.requiredItems || action.requiredItems.length === 0) return true;
    for (let req of action.requiredItems) {
      let invItem = playerInventory.find(i => i.name === req.name);
      if (!invItem || invItem.quantity < req.quantity) return false;
    }
    return true;
  }

  function renderSkillActions() {
    if (!allSkillActions[selectedSkill]) return null;
    return allSkillActions[selectedSkill].map((action, idx) => {
      let canAfford = canAffordAction(action);
      return (
        <button
          key={idx}
          style={{
            display: "block",
            marginBottom: "8px",
            opacity: canAfford ? 1 : 0.4,
          }}
          onClick={() => openActionModal(action)}>
          {action.name}
        </button>
      );
    });
  }

  function skillLevel(name) {
    return skillData[name]?.level ?? 1;
  }

  function skillXP(name) {
    return skillData[name]?.xp ?? 0;
  }

  function xpPercent(name) {
    let level = skillLevel(name);
    let xp = skillXP(name);
    let xpNeeded = level * 100;
    return (xp / xpNeeded) * 100;
  }

  function handleItemClick(item) {
    if (item.name === "Galactic Credits") return;
    openItemOptions(item.name);
  }

  const skillLevelDisplay = skillData[selectedSkill]?.level ?? 1;
  const currentXP = skillData[selectedSkill]?.xp ?? 0;
  const xpNeeded = skillLevelDisplay * 100;

  return (
    <div className={styles.gameContainer}>
      <div className={styles.topBar}>
        <div className={styles.actionName}>
          {activeAction
            ? `Action: ${activeAction.actionName}`
            : `Skill: ${selectedSkill}`}
        </div>
        <div className={styles.actionProgress}>
          <div
            className={styles.actionProgressFill}
            style={{ width: `${localProgress}%` }}
          />
        </div>
      </div>
      <div
        style={{ textAlign: "center", backgroundColor: "#222", color: "#fff" }}>
        <p>
          Level: {skillLevelDisplay} | XP: {currentXP}/{xpNeeded}
        </p>
      </div>
      <div className={styles.mainArea}>
        <div
          className={styles.sidebarLeft}
          style={{
            width: isPortraitMobile
              ? skillsCollapsed
                ? "50px"
                : "200px"
              : "200px",
          }}>
          {isPortraitMobile && (
            <div
              style={{
                padding: "10px",
                borderBottom: "1px solid #444",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#333",
                color: "#fff",
              }}
              onClick={() => setSkillsCollapsed(!skillsCollapsed)}>
              {skillsCollapsed ? ">" : "<"}
            </div>
          )}
          {isPortraitMobile && (
            <div
              onClick={() => setShowMobileInventory(!showMobileInventory)}
              style={{
                padding: "10px",
                borderBottom: "1px solid #444",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: showMobileInventory ? "#0f0f0f" : "#333",
                color: showMobileInventory ? "#00faff" : "#fff",
                boxShadow: showMobileInventory
                  ? "0 0 6px #00faff, 0 0 12px #00faff"
                  : "none",
                marginBottom: "8px",
              }}>
              Inventory & Equipment
            </div>
          )}
          <div
            onClick={() => handleSkillClick("Engineering")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Engineering" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Engineering</span>
                  <span>{skillLevel("Engineering")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Engineering")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>E</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Agriculture")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Agriculture" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Agriculture</span>
                  <span>{skillLevel("Agriculture")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Agriculture")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>A</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Combat")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Combat" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Combat</span>
                  <span>{skillLevel("Combat")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Combat")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>C</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Mining")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Mining" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Mining</span>
                  <span>{skillLevel("Mining")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Mining")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>M</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Terraforming")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Terraforming" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Terraforming</span>
                  <span>{skillLevel("Terraforming")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Terraforming")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>T</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Diplomacy")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Diplomacy" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Diplomacy</span>
                  <span>{skillLevel("Diplomacy")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Diplomacy")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>D</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Salvage")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Salvage" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Salvage</span>
                  <span>{skillLevel("Salvage")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Salvage")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>S</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Xenolinguistics")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Xenolinguistics" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Xenolinguistics</span>
                  <span>{skillLevel("Xenolinguistics")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Xenolinguistics")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>X</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Genetic Modification")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Genetic Modification"
                ? styles.selectedSkill
                : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Genetic Modification</span>
                  <span>{skillLevel("Genetic Modification")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{
                      width: `${xpPercent("Genetic Modification")}%`,
                    }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>G</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Energy Manipulation")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Energy Manipulation"
                ? styles.selectedSkill
                : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Energy Manipulation</span>
                  <span>{skillLevel("Energy Manipulation")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{
                      width: `${xpPercent("Energy Manipulation")}%`,
                    }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>E</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Artificial Intelligence")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Artificial Intelligence"
                ? styles.selectedSkill
                : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Artificial Intelligence</span>
                  <span>{skillLevel("Artificial Intelligence")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{
                      width: `${xpPercent("Artificial Intelligence")}%`,
                    }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>A</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Cartography")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Cartography" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Cartography</span>
                  <span>{skillLevel("Cartography")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Cartography")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>C</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Electronics")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Electronics" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Electronics</span>
                  <span>{skillLevel("Electronics")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Electronics")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>E</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Psionics")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Psionics" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Psionics</span>
                  <span>{skillLevel("Psionics")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Psionics")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>P</div>}
          </div>
          <div
            onClick={() => handleSkillClick("Temporal")}
            className={`${styles.skillContainer} ${
              selectedSkill === "Temporal" ? styles.selectedSkill : ""
            }`}
            style={{ height: skillsCollapsed ? "40px" : "auto" }}>
            {!skillsCollapsed && (
              <>
                <div className={styles.skillNameAndLevel}>
                  <span>Temporal</span>
                  <span>{skillLevel("Temporal")}</span>
                </div>
                <div className={styles.xpBar}>
                  <div
                    className={styles.xpFill}
                    style={{ width: `${xpPercent("Temporal")}%` }}
                  />
                </div>
              </>
            )}
            {skillsCollapsed && <div style={{ textAlign: "center" }}>T</div>}
          </div>
        </div>
        <div className={styles.centerContent}>
          {!isPortraitMobile && renderSkillActions()}
          {isPortraitMobile && !showMobileInventory && renderSkillActions()}
          {isPortraitMobile && showMobileInventory && (
            <div>
              <div className={styles.rightTabs}>
                <div
                  className={`${styles.rightTab} ${
                    selectedRightTab === "inventory" ? styles.activeTab : ""
                  }`}
                  onClick={() => setSelectedRightTab("inventory")}>
                  Inventory
                </div>
                <div
                  className={`${styles.rightTab} ${
                    selectedRightTab === "equipment" ? styles.activeTab : ""
                  }`}
                  onClick={() => setSelectedRightTab("equipment")}>
                  Equipment
                </div>
              </div>
              {selectedRightTab === "inventory" && (
                <div className={styles.rightTabContent}>
                  {playerInventory.map((item, index) => {
                    const info = itemInfo[item.name] || {};
                    return (
                      <div
                        className={styles.tooltipContainer}
                        key={index}>
                        <button
                          onClick={() => handleItemClick(item)}
                          style={{
                            display: "block",
                            marginBottom: "6px",
                            width: "100%",
                            backgroundColor: "#1a1a1a",
                            color: "#fff",
                            padding: "6px",
                            border: "1px solid #444",
                            borderRadius: "4px",
                            textAlign: "left",
                            cursor:
                              item.name === "Galactic Credits"
                                ? "default"
                                : "pointer",
                          }}>
                          {item.name} ({item.quantity})
                        </button>
                        {item.name !== "Galactic Credits" && (
                          <span className={styles.tooltipText}>
                            {item.name} <br /> Sells for {info.value || 0}{" "}
                            credits each
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedRightTab === "equipment" && (
                <div className={styles.rightTabContent}>
                  <p>Equipment slots go here</p>
                </div>
              )}
            </div>
          )}
        </div>
        {!isPortraitMobile && (
          <div className={styles.sidebarRight}>
            <div className={styles.rightTabs}>
              <div
                className={`${styles.rightTab} ${
                  selectedRightTab === "inventory" ? styles.activeTab : ""
                }`}
                onClick={() => setSelectedRightTab("inventory")}>
                Inventory
              </div>
              <div
                className={`${styles.rightTab} ${
                  selectedRightTab === "equipment" ? styles.activeTab : ""
                }`}
                onClick={() => setSelectedRightTab("equipment")}>
                Equipment
              </div>
            </div>
            {selectedRightTab === "inventory" && (
              <div className={styles.rightTabContent}>
                {playerInventory.map((item, index) => {
                  const info = itemInfo[item.name] || {};
                  return (
                    <div
                      className={styles.tooltipContainer}
                      key={index}>
                      <button
                        onClick={() => handleItemClick(item)}
                        style={{
                          display: "block",
                          marginBottom: "6px",
                          width: "100%",
                          backgroundColor: "#1a1a1a",
                          color: "#fff",
                          padding: "6px",
                          border: "1px solid #444",
                          borderRadius: "4px",
                          textAlign: "left",
                          cursor:
                            item.name === "Galactic Credits"
                              ? "default"
                              : "pointer",
                        }}>
                        {item.name} ({item.quantity})
                      </button>
                      {item.name !== "Galactic Credits" && (
                        <span className={styles.tooltipText}>
                          {item.name} <br /> Sells for {info.value || 0} credits
                          each
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {selectedRightTab === "equipment" && (
              <div className={styles.rightTabContent}>
                <p>Equipment slots go here</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.bottomChat}>
        <div className={styles.chatMessages}>
          {chatMessages.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
        <div className={styles.chatInput}>
          <input
            type="text"
            value={chatInput}
            onChange={e => setChatInput(e.target.value)}
            onKeyDown={handleChatKeyDown}
            placeholder="Type a message..."
          />
        </div>
      </div>
    </div>
  );
}
