import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import styles from "./DuelCards.module.scss";
import ICON_ALTHIAN from "./assets/ICON_ALTHIAN.svg";
import ICON_NATORAN from "./assets/ICON_NATORAN.svg";
import ICON_XENOZORIAN from "./assets/ICON_XENOZORIAN.svg";
import ICON_NECRONIAN from "./assets/ICON_NECRONIAN.svg";
import ICON_ACTION from "./assets/ICON_ACTION.svg";
import ICON_LIFE from "./assets/ICON_LIFE.svg";
import ICON_ATTACK from "./assets/ICON_ATTACK.svg";
import ICON_DEFENSE from "./assets/ICON_DEFENSE.svg";
import ICON_RANDOM from "./assets/ICON_RANDOM.svg";
import ICON_DISCARD_PILE from "./assets/ICON_DISCARD_PILE.svg";
import ICON_EXAMINE from "./assets/ICON_EXAMINE.svg";
import ICON_LEVEL_UP from "./assets/ICON_LEVEL_UP.svg";
import ICON_RECYCLE from "./assets/ICON_RECYCLE.svg";
import ICON_END_TURN from "./assets/ICON_END_TURN.svg";
import ICON_DISCARD_FOR_RESOURCE from "./assets/ICON_DISCARD_FOR_RESOURCE.svg";
import ICON_RESOURCES from "./assets/ICON_RESOURCES.svg";
import ICON_LEVEL_ONE from "./assets/ICON_LEVEL_ONE.svg";
import ICON_LEVEL_TWO from "./assets/ICON_LEVEL_TWO.svg";
import ICON_LEVEL_THREE from "./assets/ICON_LEVEL_THREE.svg";
import ICON_ADD_CARD from "./assets/ICON_ADD_CARD.svg";
import ICON_SELL from "./assets/ICON_SELL.svg";
import ExamineCard from "./ExamineCard";

export default function DeckBuilder({
  ws,
  cards,
  decks,
  setDecks,
  collection,
  gold,
  returnToMenu,
}) {
  const [selectedDeckIndex, setSelectedDeckIndex] = useState(null);
  const [newDeckName, setNewDeckName] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [creatingDeck, setCreatingDeck] = useState(null);
  const [factionFilter, setFactionFilter] = useState({
    althian: false,
    natoran: false,
    xenozorian: false,
    necronian: false,
  });
  const [cardTypeFilter, setCardTypeFilter] = useState("");
  const [costFilterLevel, setCostFilterLevel] = useState("1");
  const [costFilterMin, setCostFilterMin] = useState("");
  const [costFilterMax, setCostFilterMax] = useState("");
  const [healthFilterLevel, setHealthFilterLevel] = useState("1");
  const [healthFilterMin, setHealthFilterMin] = useState("");
  const [healthFilterMax, setHealthFilterMax] = useState("");
  const [attackFilterLevel, setAttackFilterLevel] = useState("1");
  const [attackFilterMin, setAttackFilterMin] = useState("");
  const [attackFilterMax, setAttackFilterMax] = useState("");
  const [defenseFilterLevel, setDefenseFilterLevel] = useState("1");
  const [defenseFilterMin, setDefenseFilterMin] = useState("");
  const [defenseFilterMax, setDefenseFilterMax] = useState("");
  const [sellConfirmationEnabled, setSellConfirmationEnabled] = useState(true);

  const factionIcons = {
    althian: ICON_ALTHIAN,
    natoran: ICON_NATORAN,
    xenozorian: ICON_XENOZORIAN,
    necronian: ICON_NECRONIAN,
  };

  function getSpellDamageValues(cardData) {
    if (!cardData.triggers) return null;
    const damageEffects = cardData.triggers
      .flatMap(t => t.effect)
      .filter(e => e.type === "damage");
    if (!damageEffects.length) return null;
    const damageValues = [];
    for (let level = 1; level <= 3; level++) {
      const found = damageEffects.find(e => e.level === level);
      damageValues.push(found ? found.value : 0);
    }
    return damageValues;
  }

  function handleFactionFilterChange(faction) {
    setFactionFilter(prev => ({
      ...prev,
      [faction]: !prev[faction],
    }));
  }

  function handleCardClick(card) {
    setSelectedCard(card);
  }

  function handleClose() {
    setSelectedCard(null);
  }

  function createDeck() {
    if (!newDeckName.trim()) return;
    const newDeck = {
      id: uuidv4(),
      name: newDeckName.trim(),
      cards: {},
      active: false,
    };
    setCreatingDeck(newDeck);
    setNewDeckName("");
    setSelectedDeckIndex(null);
  }

  function selectDeck(index) {
    setSelectedDeckIndex(index);
    setCreatingDeck(null);
  }

  function getSelectedDeck() {
    if (selectedDeckIndex === null) return null;
    return decks[selectedDeckIndex];
  }

  function addCardToDeck(cardKey) {
    if (!ws) return;
    let deckToModify;
    if (creatingDeck) {
      deckToModify = { ...creatingDeck };
    } else if (selectedDeckIndex !== null) {
      deckToModify = { ...decks[selectedDeckIndex] };
    } else {
      return;
    }
    const currentQtyInDeck = deckToModify.cards[cardKey] || 0;
    if (!collection[cardKey] || currentQtyInDeck >= collection[cardKey]) {
      Swal.fire({
        icon: "warning",
        title: "Not enough copies in your collection",
        text: "You cannot add more copies than you own.",
      });
      return;
    }
    const cardFaction = cards.find(c => c.key === cardKey)?.faction;
    if (!cardFaction) return;
    const deckCardFactions = new Set();
    for (const deckCardKey in deckToModify.cards) {
      if (deckToModify.cards[deckCardKey] > 0) {
        const deckCardFaction = cards.find(c => c.key === deckCardKey)?.faction;
        if (deckCardFaction) {
          deckCardFactions.add(deckCardFaction.toLowerCase());
        }
      }
    }
    if (!deckCardFactions.has(cardFaction.toLowerCase())) {
      if (deckCardFactions.size >= 3) {
        Swal.fire({
          icon: "warning",
          title: "Too many factions!",
          text: "You can only have up to 3 different factions in a deck.",
        });
        return;
      }
    }
    deckToModify.cards[cardKey] = (deckToModify.cards[cardKey] || 0) + 1;
    if (creatingDeck) {
      setCreatingDeck(deckToModify);
    } else {
      const updatedDecks = [...decks];
      updatedDecks[selectedDeckIndex] = deckToModify;
      setDecks(updatedDecks);
    }
  }

  function removeCardFromDeck(cardKey) {
    if (creatingDeck) {
      const updatedNewDeck = {
        ...creatingDeck,
        cards: { ...creatingDeck.cards },
      };
      if (updatedNewDeck.cards[cardKey]) {
        updatedNewDeck.cards[cardKey]--;
        if (updatedNewDeck.cards[cardKey] <= 0) {
          delete updatedNewDeck.cards[cardKey];
        }
      }
      setCreatingDeck(updatedNewDeck);
    } else if (selectedDeckIndex !== null) {
      const updatedDecks = [...decks];
      const deck = { ...updatedDecks[selectedDeckIndex] };
      if (deck.cards[cardKey]) {
        deck.cards[cardKey]--;
        if (deck.cards[cardKey] <= 0) {
          delete deck.cards[cardKey];
        }
      }
      updatedDecks[selectedDeckIndex] = deck;
      setDecks(updatedDecks);
    }
  }

  function saveDeck() {
    if (!ws) return;
    const deckInView = creatingDeck || getSelectedDeck();
    if (!deckInView) return;
    let totalCards = 0;
    Object.values(deckInView.cards).forEach(qty => {
      totalCards += qty;
    });
    if (totalCards !== 12) {
      Swal.fire({
        icon: "warning",
        title: "Deck must have exactly 12 cards!",
        text: "Please adjust your deck to have exactly 12 cards.",
      });
      return;
    }
    const deckFactions = new Set();
    for (const deckCardKey in deckInView.cards) {
      if (deckInView.cards[deckCardKey] > 0) {
        const deckCardFaction = cards.find(c => c.key === deckCardKey)?.faction;
        if (deckCardFaction) {
          deckFactions.add(deckCardFaction.toLowerCase());
        }
      }
    }
    if (deckFactions.size > 3) {
      Swal.fire({
        icon: "warning",
        title: "Too many factions!",
        text: "You can only have up to 3 different factions in a deck.",
      });
      return;
    }
    ws.send(
      JSON.stringify({
        type: "DECK",
        action: "SAVE",
        deck: deckInView,
      })
    );
    if (creatingDeck) {
      const updatedDecks = [...decks, deckInView];
      setDecks(updatedDecks);
      setCreatingDeck(null);
      setSelectedDeckIndex(updatedDecks.length - 1);
    }
  }

  function setActiveDeck(deckId) {
    if (!ws) return;
    setDecks(prev =>
      prev.map(d => {
        return { ...d, active: d.id === deckId };
      })
    );
    ws.send(
      JSON.stringify({
        type: "DECK",
        action: "SET_ACTIVE",
        deckId,
      })
    );
  }

  function handleDeleteDeck(deckId) {
    if (!ws) return;
    if (decks.length <= 1) {
      Swal.fire({
        icon: "error",
        title: "Cannot delete last deck!",
        text: "You must keep at least one deck.",
      });
      return;
    }
    ws.send(
      JSON.stringify({
        type: "DECK",
        action: "DELETE",
        deckId,
      })
    );
  }

  function filterCards(cardsList) {
    let filtered = cardsList.filter(
      card => collection[card.key] && collection[card.key] > 0
    );
    const deckInView = creatingDeck || getSelectedDeck();
    if (deckInView) {
      filtered = filtered.filter(
        card => collection[card.key] - (deckInView.cards[card.key] || 0) > 0
      );
    }
    const activeFactions = Object.entries(factionFilter)
      .filter(([_, value]) => value)
      .map(([key]) => key);
    if (activeFactions.length > 0) {
      filtered = filtered.filter(card =>
        activeFactions.includes(card.faction?.toLowerCase())
      );
    }
    if (cardTypeFilter) {
      filtered = filtered.filter(card => card.cardType === cardTypeFilter);
    }
    const costLvl = parseInt(costFilterLevel, 10);
    const costMin = costFilterMin === "" ? null : parseInt(costFilterMin, 10);
    const costMax = costFilterMax === "" ? null : parseInt(costFilterMax, 10);
    if (costMin != null || costMax != null) {
      filtered = filtered.filter(card => {
        if (!Array.isArray(card.cost)) return false;
        const costForLevel = card.cost.find(c => c.level === costLvl);
        if (!costForLevel) return false;
        if (costMin != null && costForLevel.value < costMin) return false;
        if (costMax != null && costForLevel.value > costMax) return false;
        return true;
      });
    }
    const healthLvl = parseInt(healthFilterLevel, 10);
    const healthMin =
      healthFilterMin === "" ? null : parseInt(healthFilterMin, 10);
    const healthMax =
      healthFilterMax === "" ? null : parseInt(healthFilterMax, 10);
    if (healthMin != null || healthMax != null) {
      filtered = filtered.filter(card => {
        if (!Array.isArray(card.health)) return false;
        const healthForLevel = card.health.find(h => h.level === healthLvl);
        if (!healthForLevel) return false;
        if (healthMin != null && healthForLevel.value < healthMin) return false;
        if (healthMax != null && healthForLevel.value > healthMax) return false;
        return true;
      });
    }
    const attackLvl = parseInt(attackFilterLevel, 10);
    const attackMin =
      attackFilterMin === "" ? null : parseInt(attackFilterMin, 10);
    const attackMax =
      attackFilterMax === "" ? null : parseInt(attackFilterMax, 10);
    if (attackMin != null || attackMax != null) {
      filtered = filtered.filter(card => {
        if (!Array.isArray(card.attack) && card.cardType !== "spell")
          return false;
        if (card.cardType === "spell") {
          const dmg = getSpellDamageValues(card);
          if (!dmg) return false;
          const val = dmg[attackLvl - 1] || 0;
          if (attackMin != null && val < attackMin) return false;
          if (attackMax != null && val > attackMax) return false;
          return true;
        } else {
          const attackForLevel = card.attack.find(a => a.level === attackLvl);
          if (!attackForLevel) return false;
          if (attackMin != null && attackForLevel.value < attackMin)
            return false;
          if (attackMax != null && attackForLevel.value > attackMax)
            return false;
          return true;
        }
      });
    }
    const defenseLvl = parseInt(defenseFilterLevel, 10);
    const defenseMin =
      defenseFilterMin === "" ? null : parseInt(defenseFilterMin, 10);
    const defenseMax =
      defenseFilterMax === "" ? null : parseInt(defenseFilterMax, 10);
    if (defenseMin != null || defenseMax != null) {
      filtered = filtered.filter(card => {
        if (!Array.isArray(card.defense)) return false;
        const defenseForLevel = card.defense.find(d => d.level === defenseLvl);
        if (!defenseForLevel) return false;
        if (defenseMin != null && defenseForLevel.value < defenseMin)
          return false;
        if (defenseMax != null && defenseForLevel.value > defenseMax)
          return false;
        return true;
      });
    }
    return filtered;
  }

  function countDeckCards(deck) {
    if (!deck) return 0;
    return Object.values(deck.cards).reduce((sum, qty) => sum + qty, 0);
  }

  function handleSellCard(cardKey) {
    if (!ws) return;
    if (sellConfirmationEnabled) {
      Swal.fire({
        icon: "warning",
        title: "Sell Card?",
        text: "Are you sure you want to sell this card permanently for 5 gold?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sell",
        cancelButtonText: "No",
      }).then(result => {
        if (result.isConfirmed) {
          ws.send(
            JSON.stringify({
              type: "SHOP",
              action: "SELL",
              cardKey,
            })
          );
        }
      });
    } else {
      ws.send(
        JSON.stringify({
          type: "SHOP",
          action: "SELL",
          cardKey,
        })
      );
    }
  }

  const activeDeck = decks.find(d => d.active);
  const deckInView = creatingDeck || getSelectedDeck();
  const deckTitle = creatingDeck
    ? `Currently Creating a New Deck: ${creatingDeck.name}`
    : deckInView
    ? `Selected Deck: ${deckInView.name}`
    : "";
  const currentDeckCount = countDeckCards(deckInView);
  const difference = 12 - currentDeckCount;

  return (
    <div>
      <h2>Deck Builder</h2>
      <button onClick={returnToMenu}>Return to Menu</button>
      <h2>Decks</h2>
      <div>
        {decks.map((deck, index) => (
          <div
            key={deck.id}
            style={{ marginBottom: "8px" }}>
            <button onClick={() => selectDeck(index)}>{deck.name}</button>
            {deck.active && <span> (Active)</span>}
            {!deck.active && (
              <button
                style={{ marginLeft: "8px" }}
                onClick={() => setActiveDeck(deck.id)}>
                Set Active
              </button>
            )}
            {decks.length > 1 && (
              <button
                style={{ marginLeft: "8px" }}
                onClick={() => handleDeleteDeck(deck.id)}>
                Delete
              </button>
            )}
          </div>
        ))}
        {!activeDeck && <div>No active deck</div>}
      </div>
      <div>
        <input
          value={newDeckName}
          onChange={e => setNewDeckName(e.target.value)}
          placeholder="Deck Name"
        />
        <button onClick={createDeck}>Create Deck</button>
      </div>
      {deckInView && <button onClick={saveDeck}>Save Deck</button>}
      {deckInView && (
        <div>
          <h3>{deckTitle}</h3>
          <div>
            Cards in Deck: {currentDeckCount}/12{" "}
            {difference > 0
              ? `(need ${difference} more)`
              : difference < 0
              ? `(remove ${Math.abs(difference)})`
              : ""}
          </div>
          <div>
            {Object.entries(deckInView.cards).map(([cardKey, qty]) => {
              const deckCard = cards.find(c => c.key === cardKey);
              return (
                <div
                  key={cardKey}
                  className={styles.deckCardSliverContainer}>
                  <img
                    src={
                      factionIcons[deckCard?.faction?.toLowerCase()] ||
                      ICON_RANDOM
                    }
                    alt={deckCard?.faction}
                    className={styles.factionIcon}
                    style={{ marginRight: "8px" }}
                  />
                  <div
                    className={styles.deckCardSliver}
                    style={{
                      backgroundImage: `url(${deckCard?.image})`,
                    }}>
                    {deckCard ? deckCard.name : cardKey}
                  </div>
                  <button onClick={() => removeCardFromDeck(cardKey)}>-</button>
                  <div>{qty}x</div>
                  <button onClick={() => addCardToDeck(cardKey)}>+</button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div style={{ margin: "8px 0" }}>
        <label style={{ marginRight: "8px" }}>
          <input
            type="checkbox"
            checked={factionFilter.althian}
            onChange={() => handleFactionFilterChange("althian")}
          />
          <img
            src={ICON_ALTHIAN}
            alt="Althian"
            style={{ width: "24px", height: "24px" }}
          />
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="checkbox"
            checked={factionFilter.natoran}
            onChange={() => handleFactionFilterChange("natoran")}
          />
          <img
            src={ICON_NATORAN}
            alt="Natoran"
            style={{ width: "24px", height: "24px" }}
          />
        </label>
        <label style={{ marginRight: "8px" }}>
          <input
            type="checkbox"
            checked={factionFilter.xenozorian}
            onChange={() => handleFactionFilterChange("xenozorian")}
          />
          <img
            src={ICON_XENOZORIAN}
            alt="Xenozorian"
            style={{ width: "24px", height: "24px" }}
          />
        </label>
        <label>
          <input
            type="checkbox"
            checked={factionFilter.necronian}
            onChange={() => handleFactionFilterChange("necronian")}
          />
          <img
            src={ICON_NECRONIAN}
            alt="Necronian"
            style={{ width: "24px", height: "24px" }}
          />
        </label>
      </div>
      <div style={{ margin: "8px 0" }}>
        <span style={{ marginRight: "2px" }}>Card Type:</span>
        <select
          value={cardTypeFilter}
          onChange={e => setCardTypeFilter(e.target.value)}
          style={{ marginRight: "2px" }}>
          <option value="">All</option>
          <option value="entity">Entity</option>
          <option value="spell">Spell</option>
        </select>
      </div>
      <div style={{ margin: "2px 0" }}>
        <span style={{ marginRight: "2px" }}>Cost:</span>
        <select
          value={costFilterLevel}
          onChange={e => setCostFilterLevel(e.target.value)}
          style={{ marginRight: "2px" }}>
          <option value="1">LV 1</option>
          <option value="2">LV 2</option>
          <option value="3">LV 3</option>
        </select>
        <span style={{ marginRight: "2px" }}>Min:</span>
        <input
          type="number"
          value={costFilterMin}
          onChange={e => setCostFilterMin(e.target.value)}
          style={{ width: "40px", marginRight: "2px" }}
        />
        <span style={{ marginRight: "2px" }}>Max:</span>
        <input
          type="number"
          value={costFilterMax}
          onChange={e => setCostFilterMax(e.target.value)}
          style={{ width: "40px" }}
        />
      </div>
      <div style={{ margin: "2px 0" }}>
        <span style={{ marginRight: "2px" }}>Health:</span>
        <select
          value={healthFilterLevel}
          onChange={e => setHealthFilterLevel(e.target.value)}
          style={{ marginRight: "2px" }}>
          <option value="1">LV 1</option>
          <option value="2">LV 2</option>
          <option value="3">LV 3</option>
        </select>
        <span style={{ marginRight: "2px" }}>Min:</span>
        <input
          type="number"
          value={healthFilterMin}
          onChange={e => setHealthFilterMin(e.target.value)}
          style={{ width: "40px", marginRight: "2px" }}
        />
        <span style={{ marginRight: "2px" }}>Max:</span>
        <input
          type="number"
          value={healthFilterMax}
          onChange={e => setHealthFilterMax(e.target.value)}
          style={{ width: "40px" }}
        />
      </div>
      <div style={{ margin: "2px 0" }}>
        <span style={{ marginRight: "2px" }}>Attack:</span>
        <select
          value={attackFilterLevel}
          onChange={e => setAttackFilterLevel(e.target.value)}
          style={{ marginRight: "2px" }}>
          <option value="1">LV 1</option>
          <option value="2">LV 2</option>
          <option value="3">LV 3</option>
        </select>
        <span style={{ marginRight: "2px" }}>Min:</span>
        <input
          type="number"
          value={attackFilterMin}
          onChange={e => setAttackFilterMin(e.target.value)}
          style={{ width: "40px", marginRight: "2px" }}
        />
        <span style={{ marginRight: "2px" }}>Max:</span>
        <input
          type="number"
          value={attackFilterMax}
          onChange={e => setAttackFilterMax(e.target.value)}
          style={{ width: "40px" }}
        />
      </div>
      <div style={{ margin: "2px 0" }}>
        <span style={{ marginRight: "2px" }}>Defense:</span>
        <select
          value={defenseFilterLevel}
          onChange={e => setDefenseFilterLevel(e.target.value)}
          style={{ marginRight: "2px" }}>
          <option value="1">LV 1</option>
          <option value="2">LV 2</option>
          <option value="3">LV 3</option>
        </select>
        <span style={{ marginRight: "2px" }}>Min:</span>
        <input
          type="number"
          value={defenseFilterMin}
          onChange={e => setDefenseFilterMin(e.target.value)}
          style={{ width: "40px", marginRight: "2px" }}
        />
        <span style={{ marginRight: "2px" }}>Max:</span>
        <input
          type="number"
          value={defenseFilterMax}
          onChange={e => setDefenseFilterMax(e.target.value)}
          style={{ width: "40px" }}
        />
      </div>
      <div style={{ margin: "8px 0" }}>
        <label>
          <input
            type="checkbox"
            checked={sellConfirmationEnabled}
            onChange={e => setSellConfirmationEnabled(e.target.checked)}
          />
          Show Sell Confirmation
        </label>
      </div>
      <div className={styles.cardContainer}>
        {filterCards(cards).map(card => {
          const dmgVals = getSpellDamageValues(card);
          const attackDisplay = dmgVals
            ? dmgVals.join("/")
            : Array.isArray(card.attack)
            ? card.attack.map(a => a.value).join("/")
            : "-";
          return (
            <div
              key={card.key}
              className={styles.card}
              onClick={() => handleCardClick(card)}>
              <div
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  color: "#fff",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  fontSize: "0.7rem",
                  zIndex: 10,
                }}>
                {deckInView
                  ? collection[card.key] - (deckInView.cards[card.key] || 0)
                  : collection[card.key]}
                x
              </div>
              <div className={styles.imageWrapper}>
                <img
                  src={card.image}
                  alt={card.name}
                  className={styles.cardImg}
                />
                <div className={styles.cardOverlay}>
                  <div className={styles.overlayName}>{card.name}</div>
                  <div className={styles.overlayRow}>
                    <img
                      src={
                        factionIcons[card.faction?.toLowerCase()] || ICON_RANDOM
                      }
                      alt={card.faction}
                      className={styles.overlayIcon}
                    />
                    <span>
                      {Array.isArray(card.cost)
                        ? card.cost.map(c => c.value).join("/")
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.overlayRow}>
                    <img
                      src={ICON_LIFE}
                      alt="Health"
                      className={styles.overlayIcon}
                    />
                    <span>
                      {Array.isArray(card.health)
                        ? card.health.map(h => h.value).join("/")
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.overlayRow}>
                    <img
                      src={ICON_ATTACK}
                      alt="Attack"
                      className={styles.overlayIcon}
                    />
                    <span>{attackDisplay}</span>
                  </div>
                  <div className={styles.overlayRow}>
                    <img
                      src={ICON_DEFENSE}
                      alt="Defense"
                      className={styles.overlayIcon}
                    />
                    <span>
                      {Array.isArray(card.defense)
                        ? card.defense.map(d => d.value).join("/")
                        : "-"}
                    </span>
                  </div>
                  {card.effectDescription && (
                    <div className={styles.overlayRow}>
                      {card.effectDescription}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.fullSizeButton}>
                  <img
                    src={ICON_EXAMINE}
                    alt="Examine"
                  />
                </button>
                {(creatingDeck || getSelectedDeck()) && card.canAddToDeck && (
                  <button
                    className={styles.addCardButton}
                    onClick={e => {
                      e.stopPropagation();
                      addCardToDeck(card.key);
                    }}>
                    <img
                      src={ICON_ADD_CARD}
                      alt="Add to Deck"
                    />
                  </button>
                )}
                <button
                  className={styles.addCardButton}
                  onClick={e => {
                    e.stopPropagation();
                    handleSellCard(card.key);
                  }}>
                  <img
                    src={ICON_SELL}
                    alt="Sell"
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {selectedCard && (
        <ExamineCard
          cardData={selectedCard}
          onClose={handleClose}
        />
      )}
    </div>
  );
}
