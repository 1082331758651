import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MechanicalFactionSymbol from "./assets/mechanical_faction_symbol.png";
import NatureFactionSymbol from "./assets/nature_faction_symbol.png";
import VanguardFactionSymbol from "./assets/vanguard_faction_symbol.png";
import ATTACK_SYMBOL from "./assets/attack_symbol.svg";
import HEALTH_SYMBOL from "./assets/health_symbol.svg";
import COOLDOWN_SYMBOL from "./assets/cooldown_symbol.svg";
import COIN_SYMBOL from "./assets/coin_symbol.svg";

function DeckBuilder({ ws, cardDatabase }) {
  const [decks, setDecks] = useState([]);
  const [activeDeck, setActiveDeck] = useState(null);

  const [selectedDeckIndex, setSelectedDeckIndex] = useState(-1);
  const [tempName, setTempName] = useState("");
  const [tempCards, setTempCards] = useState({});

  const [hoveredDeck, setHoveredDeck] = useState(null);
  const [hoveredAddCard, setHoveredAddCard] = useState(null);
  const [hoveredCardItem, setHoveredCardItem] = useState(null);
  const [hoveredButton, setHoveredButton] = useState({
    deckIndex: null,
    type: null,
  });

  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [saveNotice, setSaveNotice] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [errorNotice, setErrorNotice] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterNature, setFilterNature] = useState(false);
  const [filterVanguard, setFilterVanguard] = useState(false);
  const [filterMechanical, setFilterMechanical] = useState(false);
  const [minCost, setMinCost] = useState("");
  const [maxCost, setMaxCost] = useState("");

  const [columnsPerRow, setColumnsPerRow] = useState(4);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const FactionSymbol = ({ type, size = 25 }) => {
    const imageStyles = {
      width: `${size}px`,
      height: `${size}px`,
      display: "inline-block",
    };

    const getSymbolSource = () => {
      switch (type) {
        case "mechanical":
          return MechanicalFactionSymbol;
        case "nature":
          return NatureFactionSymbol;
        case "vanguard":
          return VanguardFactionSymbol;
        default:
          return null;
      }
    };

    const source = getSymbolSource();

    return source ? (
      <img
        src={source}
        style={imageStyles}
        alt={`${type.charAt(0).toUpperCase() + type.slice(1)} Faction`}
      />
    ) : null;
  };

  useEffect(() => {
    if (errorNotice) {
      requestAnimationFrame(() => {
        setIsErrorVisible(true);
      });

      const timeout = setTimeout(() => {
        setIsErrorVisible(false);
        setTimeout(() => setErrorNotice(null), 300);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [errorNotice]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 600);

      let columns = 4;
      if (width >= 1200) {
        columns = 4;
      } else if (width >= 900) {
        columns = 3;
      } else if (width >= 600) {
        columns = 2;
      } else {
        columns = 1;
      }
      setColumnsPerRow(columns);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!ws) return;

    ws.send(
      JSON.stringify({
        type: "deck",
        action: "getAll",
      })
    );

    ws.addEventListener("message", handleDeckMessages);

    return () => {
      ws.removeEventListener("message", handleDeckMessages);
    };
  }, [ws]);

  const handleOpenModal = cardType => {
    const cardDetails = cardDatabase[cardType];
    setSelectedCard({ type: cardType, ...cardDetails });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
    setIsModalOpen(false);
  };

  const handleAddCardFromModal = () => {
    if (selectedCard) {
      handleAddCard(selectedCard.type, 1);
      handleCloseModal();
    }
  };

  const handleDeckMessages = event => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (err) {
      return;
    }
    if (data.type !== "deck") return;

    switch (data.action) {
      case "getAll":
        setDecks(data.decks || []);
        setActiveDeck(data.activeDeck || 0);
        break;

      case "saveSuccess":
        setDecks(data.decks || []);
        setActiveDeck(data.index || 0);
        setSelectedDeckIndex(data.index || 0);
        Swal.fire({
          icon: "success",
          title: "Deck saved successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        break;

      case "setActiveSuccess":
        setActiveDeck(data.activeDeck);
        break;

      case "deleteSuccess":
        setDecks(data.decks);
        setActiveDeck(data.activeDeck);
        Swal.fire({
          icon: "success",
          title: "Deck deleted successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        break;

      case "error":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message || "An unknown error occurred",
          timer: 3000,
          showConfirmButton: false,
        });
        break;

      default:
        break;
    }
  };

  const handleSelectDeck = idx => {
    setSelectedDeckIndex(idx);
    if (idx >= 0 && idx < decks.length) {
      const d = decks[idx];
      setTempName(d.name);
      setTempCards({ ...d.cards });
    }
  };

  const handleCreateNewDeck = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will create a new deck and discard any unsaved changes. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, create a new deck!",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        setSelectedDeckIndex(-1);
        setTempName("");
        setTempCards({});
      }
    });
  };

  const handleAddCard = (cardType, quantity = 1) => {
    if (!quantity || quantity <= 0) return;
    setTempCards(prev => ({
      ...prev,
      [cardType]: (prev[cardType] || 0) + quantity,
    }));
  };

  const handleIncrementCard = cardType => {
    handleAddCard(cardType, 1);
  };

  const handleDecrementCard = cardType => {
    setTempCards(prev => {
      const currentQty = prev[cardType] || 0;
      if (currentQty <= 1) {
        const newCards = { ...prev };
        delete newCards[cardType];
        return newCards;
      }
      return {
        ...prev,
        [cardType]: currentQty - 1,
      };
    });
  };

  const handleSaveDeck = () => {
    if (!ws) return;

    const deckExists = decks.some(
      (d, i) => d.name === tempName && i !== selectedDeckIndex
    );
    if (deckExists) {
      Swal.fire({
        title: "Deck Name Already Exists",
        text: "You already have a deck with this name. Please choose a different name.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const index = selectedDeckIndex === -1 ? -1 : selectedDeckIndex;
    const deckToSave = { name: tempName, cards: tempCards };

    ws.send(
      JSON.stringify({
        type: "deck",
        action: "save",
        index,
        deck: deckToSave,
      })
    );
  };

  const handleSetActive = idx => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "deck",
        action: "setActive",
        index: idx,
      })
    );
  };

  const handleDeleteDeck = index => {
    if (activeDeck === index) {
      Swal.fire({
        icon: "error",
        title: "Cannot Delete Active Deck",
        text: "Cannot delete the active deck. Set a different deck as active first.",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    if (decks.length <= 1) {
      Swal.fire({
        icon: "error",
        title: "Cannot Delete Last Deck",
        text: "Cannot delete your last remaining deck.",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    Swal.fire({
      title: `Delete deck "${decks[index].name}"?`,
      text: "Are you sure you want to delete this deck?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, I'm sure.",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        if (!ws) return;
        ws.send(
          JSON.stringify({
            type: "deck",
            action: "delete",
            index: index,
          })
        );
      }
    });
  };

  const darkenColor = (hex, factor) => {
    hex = hex.replace(/^#/, "");
    const num = parseInt(hex, 16);
    const r = (num >> 16) * (1 - factor);
    const g = ((num >> 8) & 0x00ff) * (1 - factor);
    const b = (num & 0x0000ff) * (1 - factor);
    return `#${(
      (1 << 24) +
      (Math.round(r) << 16) +
      (Math.round(g) << 8) +
      Math.round(b)
    )
      .toString(16)
      .slice(1)
      .padStart(6, "0")}`;
  };

  const totalCardsInDeck = Object.values(tempCards).reduce(
    (acc, cur) => acc + cur,
    0
  );
  const cardsNeeded = 50 - totalCardsInDeck;

  const filteredCardTypes = cardDatabase
    ? Object.keys(cardDatabase)
        .filter(cardType => cardDatabase[cardType].canAddToDeck)
        .filter(cardType => {
          const card = cardDatabase[cardType];

          if (searchTerm) {
            const cardName = cardType.replace(/_/g, " ").toLowerCase();
            if (!cardName.includes(searchTerm.toLowerCase())) {
              return false;
            }
          }

          const atLeastOneFilterActive =
            filterNature || filterVanguard || filterMechanical;
          if (atLeastOneFilterActive) {
            const resourceType = card.resourceType;
            const matchNature = filterNature && resourceType === "nature";
            const matchVanguard = filterVanguard && resourceType === "vanguard";
            const matchMechanical =
              filterMechanical && resourceType === "mechanical";

            if (!matchNature && !matchVanguard && !matchMechanical) {
              return false;
            }
          }

          const cost = card.cost ?? 0;

          if (minCost !== "" && !isNaN(Number(minCost))) {
            if (cost < Number(minCost)) {
              return false;
            }
          }

          if (maxCost !== "" && !isNaN(Number(maxCost))) {
            if (cost > Number(maxCost)) {
              return false;
            }
          }

          return true;
        })
        .sort((a, b) => {
          const nameA = a.replace(/_/g, " ").toLowerCase();
          const nameB = b.replace(/_/g, " ").toLowerCase();
          return nameA.localeCompare(nameB);
        })
    : [];

  const styles = {
    deckInfo: {
      marginTop: "0.5rem",
      marginBottom: "1rem",
      fontWeight: "bold",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    modalContent: {
      position: "relative",
      width: "320px",
      height: "450px",
      borderRadius: "15px",
      overflow: "hidden",
      boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
    },
    cardImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    cardTitle: {
      position: "absolute",
      top: "5px",
      left: "98%",
      width: "60%",
      transform: "translateX(-100%)",
      color: "white",
      fontSize: "1.0em",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
      margin: 0,
      textAlign: "center",
      fontWeight: "bold",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "4px 12px",
      borderRadius: "8px",
    },
    topLeftInfo: {
      position: "absolute",
      top: "5px",
      left: "5px",
      color: "white",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    },
    resourceType: {
      fontSize: "1.5em",
      fontWeight: "bold",
      display: "inline-flex",
      alignItems: "center",
      gap: "2px",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "2px 4px",
      borderRadius: "6px",
    },
    costCardLabel: {
      fontSize: "1.5em",
    },
    middleText: {
      position: "absolute",
      bottom: "15%",
      left: "50%",
      transform: "translateX(-50%)",
      width: "80%",
      color: "white",
      textAlign: "center",
      fontSize: "0.6em",
      lineHeight: "1.2",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.8)",
      padding: "2px 8px",
      borderRadius: "6px",
    },
    bottomStats: {
      position: "absolute",
      bottom: "5px",
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: "0 25px",
      color: "white",
      fontSize: "1.4em",
      fontWeight: "bold",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    },

    attackStat: {
      fontSize: "1.5em",
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "2px 8px",
      borderRadius: "6px",
    },

    cooldownStat: {
      fontSize: "1.5em",
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "2px 8px",
      borderRadius: "6px",
    },

    healthStat: {
      fontSize: "1.5em",
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "2px 8px",
      borderRadius: "6px",
    },
    modalButtons: {
      marginTop: "25px",
      display: "flex",
      gap: "15px",
    },
    addToDeckButton: {
      padding: "10px 20px",
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    closeModalButton: {
      padding: "10px 20px",
      backgroundColor: "#f44336",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    saveNotice: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "1rem 2rem",
      borderRadius: "4px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
      zIndex: 1001,
      fontSize: "1.2rem",
      textAlign: "center",
      opacity: isVisible ? 1 : 0,
      transform: `translate(-50%, -50%) scale(${isVisible ? 1 : 0.8})`,
      transition: "all 0.3s ease-in-out",
      pointerEvents: "none",
    },
    errorNotice: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ff4444",
      color: "white",
      padding: "1rem 2rem",
      borderRadius: "4px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
      zIndex: 1002,
      fontSize: "1.2rem",
      textAlign: "center",
      opacity: isErrorVisible ? 1 : 0,
      transform: `translate(-50%, -50%) scale(${isErrorVisible ? 1 : 0.8})`,
      transition: "all 0.3s ease-in-out",
      pointerEvents: "none",
      maxWidth: "80%",
      wordWrap: "break-word",
    },
    deckBuilderContainer: {
      width: "99%",
      border: "1px solid #ccc",
      padding: "1rem",
      margin: "1rem auto",
      overflowY: "auto",
      borderRadius: "8px",
      backgroundColor: "#DAC8AE",
      color: "#000",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      marginBottom: "0.5rem",
      paddingRight: "1rem",
      fontWeight: "bold",
    },
    sectionsWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    deckListSection: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      border: "3px solid grey",
      borderRadius: "4px",
      padding: "0.5rem",
    },
    deckList: {
      border: "1px solid #ccc",
      padding: "0.5rem",
      marginBottom: "0.5rem",
      maxHeight: "150px",
      overflowY: "auto",
      borderRadius: "4px",
      backgroundColor: "#EFDECD",
      width: "97%",
      color: "#000",
      scrollbarColor: "#888 #EFDECD",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
        "&:hover": {
          background: "#555",
        },
      },
    },
    shopListSection: {
      width: "48%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      border: "3px solid grey",
      borderRadius: "4px",
      padding: "0.5rem",
    },
    shopList: {
      border: "1px solid #ccc",
      padding: "0.5rem",
      marginBottom: "0.5rem",
      maxHeight: "150px",
      overflowY: "auto",
      borderRadius: "4px",
      backgroundColor: "#EFDECD",
      width: "98%",
      color: "#000",
    },
    deckItem: {
      cursor: "pointer",
      padding: "0.5rem",
      borderRadius: "4px",
      marginBottom: "0.2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#000",
      transition: "background-color 0.3s",
    },
    createButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "orange",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      height: "fit-content",
      transition: "background-color 0.3s",
      marginBottom: "0.5rem",
    },
    deckEditor: {
      border: "3px solid grey",
      borderRadius: "4px",
      padding: "0.5rem",

      backgroundColor: "#DAC8AE",
      color: "#000",
    },
    statusSubHeader: {
      marginBottom: "0.5rem",
      paddingLeft: "1rem",
      fontWeight: "bold",
    },
    subHeader: {
      marginBottom: "0.5rem",
      // paddingLeft: "1rem",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.5rem",
    },
    label: {
      marginRight: "0.5rem",
      color: "#000",
      width: "110px",
    },
    input: {
      flex: "1",
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
      height: "20px",
      fontSize: "1rem",
      backgroundColor: "#EFDECD",
    },
    cardList: {
      pointer: "pointer",
      listStyleType: "none",
      padding: 0,
      marginTop: "0.5rem",
      color: "#000",
      maxHeight: "300px",
      overflowY: "auto",
      border: "1px solid #ddd",
      padding: "0.5rem",
      borderRadius: "4px",
      backgroundColor: "#EFDECD",
    },
    cardItem: {
      marginBottom: "0.5rem",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    cardName: {
      color: "#000",
    },
    cardButtons: {
      display: "flex",
      alignItems: "center",
      gap: "0.3rem",
    },
    incrementButton: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      width: "30px",
      height: "30px",
      fontSize: "1rem",
      textAlign: "center",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    decrementButton: {
      backgroundColor: "#f44336",
      color: "#fff",
      width: "30px",
      height: "30px",
      fontSize: "1rem",
      textAlign: "center",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginRight: "1rem",
    },
    cardQuantity: {
      minWidth: "1.5rem",
      textAlign: "center",
    },
    cardOptions: {
      marginTop: "1rem",
      color: "#000",
    },
    subSubHeader: {
      color: "#000",
      marginBottom: "0.5rem",
    },
    addCardsContainer: {
      display: "grid",
      gridTemplateColumns: `repeat(${columnsPerRow}, 1fr)`,
      gap: "0.5rem",
      overflowY: "auto",
      maxHeight: "200px",
      marginTop: "1rem",
      border: "1px solid #ddd",
      padding: "0.5rem",
      borderRadius: "4px",
      backgroundColor: "#EFDECD",
      scrollbarColor: "#888 #EFDECD",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
        "&:hover": {
          background: "#555",
        },
      },
    },
    addCardButton: {
      padding: "0.5rem",
      backgroundColor: "#008CBA",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    saveButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      height: "fit-content",
      transition: "background-color 0.3s",
      marginBottom: "0.5rem",
    },
    setActiveButton: {
      marginTop: "1rem",
      marginLeft: "0.5rem",
      padding: "0.5rem 1rem",
      backgroundColor: "#2196F3",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    filterToggles: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      marginRight: isMobile ? "0" : "2rem",
      flexWrap: "wrap",
      marginBottom: isMobile ? "1rem" : "0",
    },
    costFilters: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      gap: isMobile ? "0.5rem" : "1rem",
      flexShrink: 0,
      width: isMobile ? "100%" : "auto",
    },
    costFilterGroup: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      marginBottom: isMobile ? "0.5rem" : "0",
    },
    costLabel: {
      marginRight: isMobile ? "0" : "0.5rem",
      marginBottom: isMobile ? "0.3rem" : "0",
      color: "#000",
      width: isMobile ? "auto" : "100px",
      textAlign: isMobile ? "left" : "right",
    },
    costInput: {
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
      width: isMobile ? "100%" : "80px",
      backgroundColor: "#EFDECD",
    },
    factionsLabel: {
      marginRight: "10px",
      // fontWeight: "bold",
      color: "#000",
    },
    toggleLabel: {
      marginRight: isMobile ? "0" : "15px",
      marginBottom: isMobile ? "0.5rem" : "0",
      display: "flex",
      alignItems: "center",
      color: "#000",
      gap: "2px",
    },
    allFilters: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      justifyContent: isMobile ? "flex-start" : "space-between",
      flexWrap: "wrap",
      marginBottom: "1rem",
    },
    filterGroup: {
      display: "flex",
      alignItems: "center",
      flex: "1",
      minWidth: "0",
    },
    searchInput: {
      flex: isMobile ? "1 1 100%" : "0 1 300px",
      padding: "0.3rem",
      border: "1px solid #ccc",
      borderRadius: "4px",
      color: "#000",
      width: isMobile ? "100%" : "300px",
      boxSizing: "border-box",
      backgroundColor: "#EFDECD",
    },
    searchContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "flex-start" : "center",
      width: "100%",
    },
    searchLabel: {
      marginRight: "0",
      marginBottom: isMobile ? "0.3rem" : "0",
      color: "#000",
      width: isMobile ? "auto" : "150px",
      textAlign: "left",
    },
    modalHeader: {
      textAlign: "center",
    },
    cardDetailsList: {
      listStyleType: "none",
      padding: 0,
    },
    cardDetailsListItem: {},
    setEditDeckButton: {
      marginLeft: "1rem",
      cursor: "pointer",
      backgroundColor: "#8A2BE2",
      border: "none",
      color: "white",
      padding: "0.4rem 0.8rem",
      borderRadius: "4px",
    },
    setEdit: {
      color: "purple",
      fontWeight: "bold",
      marginLeft: "1rem",
      cursor: "pointer",
      border: "none",
      padding: "0.4rem 1rem",
      borderRadius: "4px",
    },
    setActiveDeckButton: {
      marginLeft: "1rem",
      cursor: "pointer",
      backgroundColor: "#2196F3",
      border: "none",
      color: "white",
      padding: "0.4rem 0.8rem",
      borderRadius: "4px",
    },
    deleteDeckButton: {
      marginLeft: "1rem",
      cursor: "pointer",
      backgroundColor: "#f44336",
      border: "none",
      color: "white",
      padding: "0.4rem 0.8rem",
      borderRadius: "4px",
    },
    setActive: {
      color: "orange",
      fontWeight: "bold",
      marginLeft: "1rem",
      cursor: "pointer",
      border: "none",
      padding: "0.4rem 1rem",
      borderRadius: "4px",
    },
  };

  return (
    <div style={styles.deckBuilderContainer}>
      <div style={styles.sectionsWrapper}>
        <div style={styles.deckListSection}>
          <span style={styles.header}>Deck Builder</span>
          <div style={styles.deckList}>
            {decks.map((deck, idx) => {
              const isActive = idx === activeDeck;
              const isSelected = idx === selectedDeckIndex;
              const isHovered = hoveredDeck === idx;

              return (
                <div
                  key={idx}
                  style={{
                    ...styles.deckItem,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: isSelected
                      ? "#bde"
                      : isHovered
                      ? "#3A6EA5"
                      : "inherit",
                  }}
                  onMouseEnter={() => setHoveredDeck(idx)}
                  onMouseLeave={() => setHoveredDeck(null)}
                  tabIndex={0}
                  onKeyPress={e => {
                    if (e.key === "Enter") handleSelectDeck(idx);
                  }}
                  aria-label={`Deck ${deck.name} with ${Object.values(
                    deck.cards
                  ).reduce((a, b) => a + b, 0)} cards`}>
                  <span>
                    {deck.name} (
                    {Object.values(deck.cards).reduce((a, b) => a + b, 0)}{" "}
                    cards)
                  </span>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isSelected && (
                      <span
                        style={styles.setEdit}
                        title="Editing...">
                        Editing...
                      </span>
                    )}
                    {!isSelected && (
                      <button
                        style={{
                          ...styles.setEditDeckButton,
                          backgroundColor:
                            hoveredButton.deckIndex === idx &&
                            hoveredButton.type === "edit"
                              ? darkenColor(
                                  styles.setEditDeckButton.backgroundColor,
                                  0.3
                                )
                              : styles.setEditDeckButton.backgroundColor,
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          handleSelectDeck(idx);
                        }}
                        onMouseEnter={() =>
                          setHoveredButton({ deckIndex: idx, type: "edit" })
                        }
                        onMouseLeave={() =>
                          setHoveredButton({ deckIndex: null, type: null })
                        }
                        aria-label={`Edit deck: ${deck.name}`}>
                        Edit Deck
                      </button>
                    )}

                    <button
                      style={{
                        ...styles.deleteDeckButton,
                        backgroundColor:
                          hoveredButton.deckIndex === idx &&
                          hoveredButton.type === "delete"
                            ? darkenColor(
                                styles.deleteDeckButton.backgroundColor,
                                0.3
                              )
                            : styles.deleteDeckButton.backgroundColor,
                      }}
                      onClick={() => handleDeleteDeck(idx)}
                      onMouseEnter={() =>
                        setHoveredButton({ deckIndex: idx, type: "delete" })
                      }
                      onMouseLeave={() =>
                        setHoveredButton({ deckIndex: null, type: null })
                      }>
                      Delete
                    </button>

                    {isActive && (
                      <span
                        style={styles.setActive}
                        title="Active Deck">
                        Active
                      </span>
                    )}
                    {!isActive && (
                      <button
                        style={{
                          ...styles.setActiveDeckButton,
                          backgroundColor:
                            hoveredButton.deckIndex === idx &&
                            hoveredButton.type === "active"
                              ? darkenColor(
                                  styles.setActiveDeckButton.backgroundColor,
                                  0.3
                                )
                              : styles.setActiveDeckButton.backgroundColor,
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          handleSetActive(idx);
                        }}
                        onMouseEnter={() =>
                          setHoveredButton({ deckIndex: idx, type: "active" })
                        }
                        onMouseLeave={() =>
                          setHoveredButton({ deckIndex: null, type: null })
                        }
                        aria-label={`Set ${deck.name} as active`}>
                        Set Active
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={styles.shopListSection}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}>
            <div style={styles.header}>Shop</div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}>
              0
              <img
                src={COIN_SYMBOL}
                alt="Coin"
                style={{
                  width: "24px",
                  height: "24px",
                  verticalAlign: "middle",
                  marginLeft: "4px",
                }}
              />
            </div>
          </div>
          <div style={styles.shopList}>
            <span>Items For Sale Go Here</span>
          </div>
        </div>
      </div>

      <div style={styles.deckEditor}>
        <button
          style={styles.createButton}
          onClick={handleCreateNewDeck}
          onMouseEnter={() => setHoveredAddCard("create")}
          onMouseLeave={() => setHoveredAddCard(null)}>
          Create New Deck
        </button>

        <button
          onClick={handleSaveDeck}
          style={styles.saveButton}>
          Save Deck and Set As Active
        </button>

        <span style={styles.statusSubHeader}>
          {selectedDeckIndex === -1
            ? "Working on a New and Unsaved Deck..."
            : "Editing an Existing Deck..."}
        </span>

        <br />
        <div style={styles.inputGroup}>
          <label style={styles.label}>Deck Name:</label>
          <input
            type="text"
            value={tempName}
            onChange={e => setTempName(e.target.value)}
            style={styles.input}
            aria-label="Deck Name"
          />
        </div>

        <div style={{ marginTop: "1rem" }}>
          <label style={styles.label}>Cards in Deck:</label>
          <div style={styles.deckInfo}>
            {totalCardsInDeck} total cards
            {totalCardsInDeck < 50 && (
              <span style={{ color: "red", marginLeft: "0.5rem" }}>
                (Need {cardsNeeded} more cards)
              </span>
            )}
          </div>
          <div style={styles.cardList}>
            {Object.entries(tempCards).map(([cardType, qty]) => {
              const isHovered = hoveredCardItem === cardType;

              return (
                <div
                  key={cardType}
                  style={{
                    ...styles.cardItem,
                    backgroundColor: isHovered ? "#3A6EA5" : "inherit",
                  }}
                  onMouseEnter={() => setHoveredCardItem(cardType)}
                  onMouseLeave={() => setHoveredCardItem(null)}>
                  <div style={styles.cardButtons}>
                    <button
                      onClick={() => handleIncrementCard(cardType)}
                      style={styles.incrementButton}
                      aria-label={`Increase quantity of ${cardType.replace(
                        /_/g,
                        " "
                      )}`}>
                      +
                    </button>
                    <span style={styles.cardQuantity}> {qty} </span>
                    <button
                      onClick={() => handleDecrementCard(cardType)}
                      style={styles.decrementButton}
                      aria-label={`Decrease quantity of ${cardType.replace(
                        /_/g,
                        " "
                      )}`}>
                      -
                    </button>
                    <span style={styles.cardName}>
                      {cardType.replace(/_/g, " ")}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={styles.cardOptions}>
            <div style={styles.subHeader}>Your Library</div>
            <div style={{ marginBottom: "0.5rem" }}>
              <div style={styles.searchContainer}>
                <label
                  htmlFor="cardSearch"
                  style={styles.searchLabel}>
                  Search Cards:
                </label>
                <input
                  id="cardSearch"
                  type="text"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  style={styles.searchInput}
                  placeholder="Type card name..."
                  aria-label="Card Search"
                />
              </div>
            </div>
            <div>
              <div style={styles.allFilters}>
                <div style={styles.filterToggles}>
                  <span style={styles.factionsLabel}>Factions:</span>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterNature}
                      onChange={() => setFilterNature(!filterNature)}
                    />
                    <FactionSymbol
                      size={15}
                      type="nature"
                    />
                    Nature
                  </label>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterVanguard}
                      onChange={() => setFilterVanguard(!filterVanguard)}
                    />
                    <FactionSymbol
                      size={15}
                      type="vanguard"
                    />
                    Vanguard
                  </label>

                  <label style={styles.toggleLabel}>
                    <input
                      type="checkbox"
                      checked={filterMechanical}
                      onChange={() => setFilterMechanical(!filterMechanical)}
                    />
                    <FactionSymbol
                      size={15}
                      type="mechanical"
                    />
                    Mechanical
                  </label>
                </div>

                <div style={styles.costFilters}>
                  <div style={styles.costFilterGroup}>
                    <label
                      htmlFor="minCost"
                      style={styles.costLabel}>
                      Min Cost:
                    </label>
                    <input
                      id="minCost"
                      type="number"
                      value={minCost}
                      onChange={e => setMinCost(e.target.value)}
                      style={styles.costInput}
                      placeholder="Min Cost"
                    />
                  </div>

                  <div style={styles.costFilterGroup}>
                    <label
                      htmlFor="maxCost"
                      style={styles.costLabel}>
                      Max Cost:
                    </label>
                    <input
                      id="maxCost"
                      type="number"
                      value={maxCost}
                      onChange={e => setMaxCost(e.target.value)}
                      style={styles.costInput}
                      placeholder="Max Cost"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={styles.addCardsContainer}>
            {filteredCardTypes.map(cardType => {
              const isHovered = hoveredAddCard === cardType;
              const card = cardDatabase[cardType];
              const baseColor = {
                nature: "#228B22",
                vanguard: "#B31B1B",
                mechanical: "#0095B6",
              }[card.resourceType];
              const hoverColor = darkenColor(baseColor, 0.3);

              return (
                <button
                  key={cardType}
                  onClick={() => handleOpenModal(cardType)}
                  style={{
                    ...styles.addCardButton,
                    backgroundColor: isHovered ? hoverColor : baseColor,
                  }}
                  onMouseEnter={() => setHoveredAddCard(cardType)}
                  onMouseLeave={() => setHoveredAddCard(null)}
                  aria-label={`View details of ${cardType.replace(/_/g, " ")}`}>
                  {`${cardType.replace(/_/g, " ")} (${card.cost})`}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      {isModalOpen && selectedCard && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <img
              src={selectedCard.image}
              alt={selectedCard.name}
              style={styles.cardImage}
            />

            <h4 style={styles.cardTitle}>{selectedCard.name}</h4>

            <span style={styles.topLeftInfo}>
              <span style={styles.resourceType}>
                <FactionSymbol
                  size={30}
                  type={selectedCard.resourceType.toLowerCase()}
                />{" "}
                <span style={styles.costCardLabel}>{selectedCard.cost}</span>
              </span>
            </span>

            <div style={styles.middleText}>
              {selectedCard.effectDescription}
              {selectedCard.effectDescription && (
                <>
                  <br />
                  <br />
                </>
              )}
              <i>{selectedCard.flavorText}</i>
            </div>

            <div style={styles.bottomStats}>
              <div style={styles.attackStat}>
                <img
                  src={ATTACK_SYMBOL}
                  alt="Attack"
                  style={{ width: "24px", height: "24px", marginRight: "4px" }}
                />
                {selectedCard.maximumAttack}
              </div>
              <div style={styles.cooldownStat}>
                <img
                  src={COOLDOWN_SYMBOL}
                  alt="Cooldown"
                  style={{ width: "24px", height: "24px", marginRight: "4px" }}
                />
                {selectedCard.maximumCooldown}
              </div>
              <div style={styles.healthStat}>
                <img
                  src={HEALTH_SYMBOL}
                  alt="Health"
                  style={{ width: "24px", height: "24px", marginRight: "4px" }}
                />
                {selectedCard.maximumHealth}
              </div>
            </div>
          </div>

          <div style={styles.modalButtons}>
            <button
              onClick={handleAddCardFromModal}
              style={styles.addToDeckButton}
              aria-label={`Add ${selectedCard.name} to deck`}>
              Add to Deck
            </button>
            <button
              onClick={handleCloseModal}
              style={styles.closeModalButton}
              aria-label="Close card details">
              Put Card Away
            </button>
          </div>
        </div>
      )}

      {saveNotice && (
        <div style={styles.saveNotice}>Deck saved successfully!</div>
      )}
      {errorNotice && (
        <div style={styles.errorNotice}>Error: {errorNotice}</div>
      )}
    </div>
  );
}

export default DeckBuilder;
