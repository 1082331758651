import { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import bisonModel from "./assets/animal-bison.glb";
import dogModel from "./assets/animal-dog.glb";
import flagModel from "./assets/flag.glb";
import cubeModel from "./assets/shape-cube.glb";
import colormap from "./assets/Textures/colormap.png";

export default function ThreeDeeTime() {
  const mountRef = useRef(null);
  const cameraRef = useRef(null);
  const pinchDistanceRef = useRef(null);
  const isDragging = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);
  const rotateLeftRef = useRef(false);
  const rotateRightRef = useRef(false);

  let bison = null;
  let dog = null;
  let flag = null;

  function getRandomState() {
    const r = Math.random();
    if (r < 0.5) return "forward";
    if (r < 0.65) return "turnLeft";
    if (r < 0.8) return "turnRight";
    if (r < 0.85) return "backward";
    return "idle";
  }

  const bisonData = {
    angle: Math.random() * Math.PI * 2,
    state: getRandomState(),
    stateTimer: 0,
    stateDuration: 0,
    mixer: null,
    actions: {
      idle: null,
      startMove: null,
      move: null,
    },
    currentAction: null,
  };

  const dogData = {
    angle: Math.random() * Math.PI * 2,
    state: getRandomState(),
    stateTimer: 0,
    stateDuration: 0,
    mixer: null,
    actions: {
      idle: null,
      startMove: null,
      move: null,
    },
    currentAction: null,
  };

  const speedForwardBison = 1.0;
  const speedForwardDog = 1.2;
  const speedBackward = 0.5;
  const turnSpeed = 1.5;
  const mixers = [];

  const bisonRadius = 0.5;
  const dogRadius = 0.5;
  const flagRadius = 0.5;
  const cubeRadius = 0.5;

  const cubes = [];

  function checkCollision(objA, radiusA, objB, radiusB) {
    if (!objA || !objB) return false;
    const dx = objA.position.x - objB.position.x;
    const dz = objA.position.z - objB.position.z;
    const dist = Math.sqrt(dx * dx + dz * dz);
    return dist < radiusA + radiusB;
  }

  useEffect(() => {
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    const camera = new THREE.PerspectiveCamera(100, width / height, 0.1, 1000);
    camera.position.set(0, 1.5, 5);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    renderer.gammaOutput = true;
    renderer.gammaFactor = 2.2;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
    scene.add(ambientLight);

    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.6);
    scene.add(hemisphereLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
    directionalLight.position.set(0, 5, 10);
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 20;
    directionalLight.shadow.camera.left = -10;
    directionalLight.shadow.camera.right = 10;
    directionalLight.shadow.camera.top = 10;
    directionalLight.shadow.camera.bottom = -10;
    scene.add(directionalLight);

    const floorGeo = new THREE.PlaneGeometry(50, 50);
    const floorMat = new THREE.MeshStandardMaterial({ color: 0xcccccc });
    const floor = new THREE.Mesh(floorGeo, floorMat);
    floor.rotation.x = -Math.PI / 2;
    floor.position.y = -1.5;
    floor.receiveShadow = true;
    scene.add(floor);

    const textureLoader = new THREE.TextureLoader();
    const textureMap = textureLoader.load(colormap, tex => {
      tex.flipY = false;
    });

    const loader = new GLTFLoader();

    function setupActions(animations, mixer, data) {
      if (animations.length < 4) return;
      const idleClip = animations[2];
      const idleAction = mixer.clipAction(idleClip);
      idleAction.loop = THREE.LoopOnce;
      idleAction.clampWhenFinished = true;

      const startMoveClip = animations[3];
      const startMoveAction = mixer.clipAction(startMoveClip);
      startMoveAction.loop = THREE.LoopOnce;
      startMoveAction.clampWhenFinished = true;

      const moveClip = animations[0];
      const moveAction = mixer.clipAction(moveClip);
      moveAction.loop = THREE.LoopRepeat;

      data.actions.idle = idleAction;
      data.actions.startMove = startMoveAction;
      data.actions.move = moveAction;
      data.currentAction = moveAction;
      moveAction.play();
    }

    function playAction(data, newAction) {
      const oldAction = data.currentAction;
      if (oldAction === newAction) return;
      data.currentAction = newAction;
      newAction.reset().play();
      if (oldAction) {
        oldAction.crossFadeTo(newAction, 0.3, false);
      }
    }

    const loadModel = (url, onLoad) => {
      loader.load(url, gltf => {
        const model = gltf.scene;
        scene.add(model);
        onLoad(model, gltf.animations);
      });
    };

    loadModel(bisonModel, (model, animations) => {
      bison = model;
      bison.traverse(child => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          child.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            map: textureMap,
          });
        }
      });
      bison.position.set(-2, -1.5, 0);
      const mixer = new THREE.AnimationMixer(bison);
      mixers.push(mixer);
      bisonData.mixer = mixer;
      setupActions(animations, mixer, bisonData);
    });

    loadModel(dogModel, (model, animations) => {
      dog = model;
      dog.traverse(child => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          child.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            map: textureMap,
          });
        }
      });
      dog.position.set(2, -1.5, 0);
      const mixer = new THREE.AnimationMixer(dog);
      mixers.push(mixer);
      dogData.mixer = mixer;
      setupActions(animations, mixer, dogData);
    });

    loadModel(flagModel, (model, animations) => {
      flag = model;
      flag.traverse(child => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          child.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            map: textureMap,
          });
        }
      });
      flag.position.set(0, -1.5, -2);
      if (animations && animations.length > 0) {
        const mixer = new THREE.AnimationMixer(flag);
        mixer.clipAction(animations[0]).play();
        mixers.push(mixer);
      }
    });

    const minX = -4;
    const maxX = 4;
    const minZ = -4;
    const maxZ = 4;
    const gridRows = 4;
    const gridCols = 5;
    const spacing = 5;

    loader.load(cubeModel, gltf => {
      for (let i = 0; i < gridRows * gridCols; i++) {
        const cube = gltf.scene.clone();
        cube.traverse(child => {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            child.material = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              map: textureMap,
            });
          }
        });
        const row = Math.floor(i / gridCols);
        const col = i % gridCols;
        cube.position.set(minX + col * spacing, -1.5, minZ + row * spacing);
        cube.rotation.y = 0;
        cubes.push(cube);
        scene.add(cube);
      }
    });

    function pickNewState(creatureData) {
      creatureData.state = getRandomState();
      creatureData.stateDuration = 2 + Math.random() * 3;
      creatureData.stateTimer = 0;
      const { idle, startMove, move } = creatureData.actions;
      if (!idle || !startMove || !move) return;
      if (creatureData.state === "idle") {
        playAction(creatureData, idle);
      } else {
        if (creatureData.currentAction === idle) {
          startMove.reset();
          playAction(creatureData, startMove);
          startMove.onFinished = () => {
            playAction(creatureData, move);
          };
        } else {
          playAction(creatureData, move);
        }
      }
    }

    const clock = new THREE.Clock();
    let animationFrameId;

    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      const delta = clock.getDelta();
      mixers.forEach(m => m.update(delta));
      updateCreature(bison, bisonData, delta, speedForwardBison, cubes);
      updateCreature(dog, dogData, delta, speedForwardDog, cubes);
      if (rotateLeftRef.current && cameraRef.current) {
        cameraRef.current.rotation.y -= 0.02;
      }
      if (rotateRightRef.current && cameraRef.current) {
        cameraRef.current.rotation.y += 0.02;
      }
      renderer.render(scene, camera);
    };
    animate();

    function updateCreature(
      object,
      creatureData,
      delta,
      forwardSpeed,
      allCubes
    ) {
      if (!object) return;
      creatureData.stateTimer += delta;
      if (creatureData.stateTimer > creatureData.stateDuration) {
        pickNewState(creatureData);
      }
      const oldX = object.position.x;
      const oldZ = object.position.z;
      if (creatureData.state === "idle") return;

      if (creatureData.state === "turnLeft") {
        creatureData.angle -= turnSpeed * delta;
      } else if (creatureData.state === "turnRight") {
        creatureData.angle += turnSpeed * delta;
      }

      let speed = 0;
      if (creatureData.state === "forward") {
        speed = forwardSpeed;
      } else if (creatureData.state === "backward") {
        speed = speedBackward;
      }

      const dirX = Math.sin(creatureData.angle);
      const dirZ = Math.cos(creatureData.angle);
      object.position.x += dirX * speed * delta;
      object.position.z += dirZ * speed * delta;
      object.rotation.y = -creatureData.angle;

      if (object.position.x < minX) {
        object.position.x = minX;
        creatureData.angle += Math.PI;
      } else if (object.position.x > maxX) {
        object.position.x = maxX;
        creatureData.angle += Math.PI;
      }
      if (object.position.z < minZ) {
        object.position.z = minZ;
        creatureData.angle += Math.PI;
      } else if (object.position.z > maxZ) {
        object.position.z = maxZ;
        creatureData.angle += Math.PI;
      }

      if (object === bison) {
        if (checkCollision(bison, bisonRadius, dog, dogRadius)) {
          object.position.x = oldX;
          object.position.z = oldZ;
          creatureData.angle += Math.PI;
        }
        if (checkCollision(bison, bisonRadius, flag, flagRadius)) {
          object.position.x = oldX;
          object.position.z = oldZ;
          creatureData.angle += Math.PI;
        }
      }
      if (object === dog) {
        if (checkCollision(dog, dogRadius, bison, bisonRadius)) {
          object.position.x = oldX;
          object.position.z = oldZ;
          creatureData.angle += Math.PI;
        }
        if (checkCollision(dog, dogRadius, flag, flagRadius)) {
          object.position.x = oldX;
          object.position.z = oldZ;
          creatureData.angle += Math.PI;
        }
      }

      const creatureRadius = object === bison ? bisonRadius : dogRadius;

      for (let i = 0; i < allCubes.length; i++) {
        const cube = allCubes[i];
        const dx = object.position.x - cube.position.x;
        const dz = object.position.z - cube.position.z;
        const dist = Math.sqrt(dx * dx + dz * dz);
        const overlap = creatureRadius + cubeRadius - dist;
        if (overlap > 0 && dist > 0) {
          const nx = dx / dist;
          const nz = dz / dist;
          object.position.x += nx * overlap;
          object.position.z += nz * overlap;
          creatureData.angle += Math.PI;
          break;
        }
      }
    }

    function handleMouseDown(event) {
      isDragging.current = true;
      lastX.current = event.clientX;
      lastY.current = event.clientY;
    }

    function handleMouseMove(event) {
      if (!isDragging.current) return;
      const deltaX = event.clientX - lastX.current;
      const deltaY = event.clientY - lastY.current;
      camera.position.x -= deltaX * 0.01;
      camera.position.y += deltaY * 0.01;
      lastX.current = event.clientX;
      lastY.current = event.clientY;
    }

    function handleMouseUp() {
      isDragging.current = false;
    }

    function handleWheel(event) {
      camera.position.z += event.deltaY * 0.01;
    }

    function handleTouchStart(event) {
      if (event.touches.length === 2) {
        const dx = event.touches[0].clientX - event.touches[1].clientX;
        const dy = event.touches[0].clientY - event.touches[1].clientY;
        pinchDistanceRef.current = Math.sqrt(dx * dx + dy * dy);
      } else if (event.touches.length === 1) {
        isDragging.current = true;
        lastX.current = event.touches[0].clientX;
        lastY.current = event.touches[0].clientY;
      }
    }

    function handleTouchMove(event) {
      if (event.touches.length === 2) {
        event.preventDefault();
        const dx = event.touches[0].clientX - event.touches[1].clientX;
        const dy = event.touches[0].clientY - event.touches[1].clientY;
        const newDistance = Math.sqrt(dx * dx + dy * dy);
        camera.position.z += (pinchDistanceRef.current - newDistance) * 0.01;
        pinchDistanceRef.current = newDistance;
      } else if (event.touches.length === 1 && isDragging.current) {
        event.preventDefault();
        const deltaX = event.touches[0].clientX - lastX.current;
        const deltaY = event.touches[0].clientY - lastY.current;
        camera.position.x -= deltaX * 0.01;
        camera.position.y += deltaY * 0.01;
        lastX.current = event.touches[0].clientX;
        lastY.current = event.touches[0].clientY;
      }
    }

    function handleTouchEnd(event) {
      if (event.touches.length < 1) {
        isDragging.current = false;
      }
    }

    function handleResize() {
      const newWidth = mountRef.current.clientWidth;
      const newHeight = mountRef.current.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    }

    renderer.domElement.addEventListener("mousedown", handleMouseDown);
    renderer.domElement.addEventListener("mousemove", handleMouseMove);
    renderer.domElement.addEventListener("mouseup", handleMouseUp);
    renderer.domElement.addEventListener("wheel", handleWheel);
    renderer.domElement.addEventListener("touchstart", handleTouchStart);
    renderer.domElement.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    renderer.domElement.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("resize", handleResize);

    return () => {
      cancelAnimationFrame(animationFrameId);
      renderer.domElement.removeEventListener("mousedown", handleMouseDown);
      renderer.domElement.removeEventListener("mousemove", handleMouseMove);
      renderer.domElement.removeEventListener("mouseup", handleMouseUp);
      renderer.domElement.removeEventListener("wheel", handleWheel);
      renderer.domElement.removeEventListener("touchstart", handleTouchStart);
      renderer.domElement.removeEventListener("touchmove", handleTouchMove);
      renderer.domElement.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("resize", handleResize);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <div
        ref={mountRef}
        style={{ width: "100%", height: "100%" }}
      />
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}>
        <button
          onMouseDown={() => (rotateLeftRef.current = true)}
          onMouseUp={() => (rotateLeftRef.current = false)}
          onTouchStart={() => (rotateLeftRef.current = true)}
          onTouchEnd={() => (rotateLeftRef.current = false)}>
          Rotate Left
        </button>
        <button
          onMouseDown={() => (rotateRightRef.current = true)}
          onMouseUp={() => (rotateRightRef.current = false)}
          onTouchStart={() => (rotateRightRef.current = true)}
          onTouchEnd={() => (rotateRightRef.current = false)}>
          Rotate Right
        </button>
      </div>
    </div>
  );
}
