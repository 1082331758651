import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./FoodAndExercise.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FoodAndExercise = () => {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [entryType, setEntryType] = useState("food");
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [calories, setCalories] = useState("");
  const [foodData, setFoodData] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [dataByWeek, setDataByWeek] = useState({});
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [expandedDays, setExpandedDays] = useState({});
  const [timeSeriesData, setTimeSeriesData] = useState({
    labels: [],
    food: [],
    exercise: [],
    foodCalories: [],
    exerciseCalories: [],
  });

  useEffect(() => {
    let socket;
    let environment;

    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        socket = new WebSocket("ws://localhost:5000/food-and-exercise");
        break;
      case "localNetwork":
        socket = new WebSocket("ws://192.168.0.7:5000/food-and-exercise");
        break;
      case "production":
        socket = new WebSocket("wss://2nguyen.dev/food-and-exercise");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    if (!socket) return;
    setWs(socket);

    socket.onopen = () => {
      setIsConnected(true);
      if (user && user.username) {
        socket.send(
          JSON.stringify({
            type: "account",
            action: "user",
            user: user.username,
          })
        );

        socket.send(
          JSON.stringify({
            type: "data",
            action: "get",
          })
        );
      }
    };

    socket.onclose = () => {
      setIsConnected(false);
      setWs(null);
    };

    socket.onmessage = event => {
      const data = JSON.parse(event.data);

      // Only log messages relevant to this component (not gameState messages)
      if (data.type !== "gameState") {
        console.log("Received data:", data);
      }

      switch (`${data.type}:${data.action}`) {
        case "data:update":
          setFoodData(data.food || []);
          setExerciseData(data.exercise || []);
          organizeFoodAndExerciseByWeek(data.food || [], data.exercise || []);
          prepareTimeSeriesData(data.food || [], data.exercise || []);
          break;
        default:
          break;
      }
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user]);

  // Prepare time series data for the chart
  const prepareTimeSeriesData = (foodItems, exerciseItems) => {
    // Get all unique dates from both food and exercise entries
    const allDates = new Set();
    foodItems.forEach(item =>
      allDates.add(new Date(item.date).toLocaleDateString())
    );
    exerciseItems.forEach(item =>
      allDates.add(new Date(item.date).toLocaleDateString())
    );

    // Convert to array and sort
    const sortedDates = Array.from(allDates).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    // Count entries and sum calories for each date
    const foodCounts = [];
    const exerciseCounts = [];
    const foodCalories = [];
    const exerciseCalories = [];

    sortedDates.forEach(date => {
      const foodEntriesForDate = foodItems.filter(
        item => new Date(item.date).toLocaleDateString() === date
      );

      const exerciseEntriesForDate = exerciseItems.filter(
        item => new Date(item.date).toLocaleDateString() === date
      );

      const foodCount = foodEntriesForDate.length;
      const exerciseCount = exerciseEntriesForDate.length;

      const totalFoodCalories = foodEntriesForDate.reduce(
        (sum, item) => sum + (item.calories || 0),
        0
      );

      const totalExerciseCalories = exerciseEntriesForDate.reduce(
        (sum, item) => sum + (item.calories || 0),
        0
      );

      foodCounts.push(foodCount);
      exerciseCounts.push(exerciseCount);
      foodCalories.push(totalFoodCalories);
      exerciseCalories.push(totalExerciseCalories);
    });

    setTimeSeriesData({
      labels: sortedDates,
      food: foodCounts,
      exercise: exerciseCounts,
      foodCalories,
      exerciseCalories,
    });
  };

  // Helper function to get week identifier (YYYY-WW format)
  const getWeekIdentifier = date => {
    const d = new Date(date);
    // Set to the first day of week (Monday)
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    const monday = new Date(d.setDate(diff));

    // Get week number
    const firstDayOfYear = new Date(monday.getFullYear(), 0, 1);
    const dayNum = Math.ceil((monday - firstDayOfYear) / (24 * 60 * 60 * 1000));
    const weekNum = Math.ceil((dayNum + firstDayOfYear.getDay()) / 7);

    // Format: YYYY-WW
    return `${monday.getFullYear()}-W${weekNum.toString().padStart(2, "0")}`;
  };

  // Helper function to get dates in a given week
  const getDatesInWeek = weekId => {
    const [year, weekCode] = weekId.split("-W");
    const weekNum = parseInt(weekCode, 10);

    // Get first day of year
    const firstDayOfYear = new Date(parseInt(year, 10), 0, 1);

    // Get first day of week (Monday)
    const daysOffset = 1 - firstDayOfYear.getDay(); // Adjust when day is not Monday
    const firstDayOfFirstWeek = new Date(firstDayOfYear);
    firstDayOfFirstWeek.setDate(firstDayOfYear.getDate() + daysOffset);

    // Get first day of our target week
    const targetDay = new Date(firstDayOfFirstWeek);
    targetDay.setDate(firstDayOfFirstWeek.getDate() + (weekNum - 1) * 7);

    // Get all days in the week
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(targetDay);
      day.setDate(targetDay.getDate() + i);
      dates.push(day);
    }

    return dates;
  };

  // Format week identifier for display
  const formatWeekDisplay = weekId => {
    const dates = getDatesInWeek(weekId);
    const startDate = dates[0].toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    });
    const endDate = dates[6].toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${startDate} - ${endDate}`;
  };

  const organizeFoodAndExerciseByWeek = (foodItems, exerciseItems) => {
    const byWeek = {};

    // First, organize by date
    const byDate = {};

    // Process food items
    foodItems.forEach(item => {
      const date = new Date(item.date).toLocaleDateString();
      if (!byDate[date]) {
        byDate[date] = { food: [], exercise: [], date: new Date(item.date) };
      }
      byDate[date].food.push(item);
    });

    // Process exercise items
    exerciseItems.forEach(item => {
      const date = new Date(item.date).toLocaleDateString();
      if (!byDate[date]) {
        byDate[date] = { food: [], exercise: [], date: new Date(item.date) };
      }
      byDate[date].exercise.push(item);
    });

    // Now organize by week
    Object.values(byDate).forEach(dateData => {
      const weekId = getWeekIdentifier(dateData.date);
      if (!byWeek[weekId]) {
        byWeek[weekId] = {};
      }
      const dateStr = dateData.date.toLocaleDateString();
      byWeek[weekId][dateStr] = {
        food: dateData.food,
        exercise: dateData.exercise,
        date: dateData.date,
      };
    });

    setDataByWeek(byWeek);

    // Initialize expanded days state for all new days that don't already have a state
    const newExpandedDays = { ...expandedDays };
    Object.keys(byWeek).forEach(weekId => {
      Object.keys(byWeek[weekId]).forEach(dateStr => {
        const dayKey = `${weekId}-${dateStr}`;
        if (newExpandedDays[dayKey] === undefined) {
          newExpandedDays[dayKey] = false; // Default to collapsed
        }
      });
    });
    setExpandedDays(newExpandedDays);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!name.trim()) {
      alert("Please enter a name for your " + entryType);
      return;
    }

    if (!ws || !isConnected) {
      alert("Not connected to server. Please try again later.");
      return;
    }

    const entry = {
      date: date.toISOString(),
      name: name.trim(),
      unit: unit.trim() || null,
      calories: calories.trim() ? Number(calories.trim()) : null,
    };

    ws.send(
      JSON.stringify({
        type: "data",
        action: "add",
        dataType: entryType,
        entry,
      })
    );

    // Reset form fields
    setName("");
    setUnit("");
    setCalories("");
  };

  const handleDelete = (itemId, dataType) => {
    if (!ws || !isConnected) {
      alert("Not connected to server. Please try again later.");
      return;
    }

    if (confirm("Are you sure you want to delete this entry?")) {
      ws.send(
        JSON.stringify({
          type: "data",
          action: "delete",
          dataType,
          itemId,
        })
      );
    }
  };

  const toggleWeekExpansion = weekId => {
    setExpandedWeeks(prev => ({
      ...prev,
      [weekId]: !prev[weekId],
    }));
  };

  const toggleDayExpansion = (weekId, dateStr) => {
    const dayKey = `${weekId}-${dateStr}`;
    setExpandedDays(prev => ({
      ...prev,
      [dayKey]: !prev[dayKey],
    }));
  };

  // Chart configuration
  const calorieChartData = {
    labels: timeSeriesData.labels,
    datasets: [
      {
        label: "Calories Consumed",
        data: timeSeriesData.foodCalories,
        borderColor: "#8b7355",
        backgroundColor: "rgba(139, 115, 85, 0.2)",
        tension: 0.1,
      },
      {
        label: "Calories Burned",
        data: timeSeriesData.exerciseCalories,
        borderColor: "#c19a6b",
        backgroundColor: "rgba(193, 154, 107, 0.2)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Calories Consumed vs. Burned Over Time",
        color: "#000",
      },
    },
  };

  return (
    <div className={styles.foodAndExercise}>
      <h1>Food and Exercise Tracker</h1>

      {user && user.username ? (
        <>
          <div className={styles.addEntrySection}>
            <h2>Add New Entry</h2>
            <div className={styles.typeSelector}>
              <button
                className={entryType === "food" ? styles.active : ""}
                onClick={() => setEntryType("food")}>
                Food
              </button>
              <button
                className={entryType === "exercise" ? styles.active : ""}
                onClick={() => setEntryType("exercise")}>
                Exercise
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label>Date:</label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showPreviousMonths
                  previousMonthButtonLabel="<<"
                  nextMonthButtonLabel=">>"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        type="button"
                        className={styles.navButton}>
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}>
                        {Array.from(
                          { length: 10 },
                          (_, i) => new Date().getFullYear() - 5 + i
                        ).map(year => (
                          <option
                            key={year}
                            value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }) =>
                          changeMonth(value)
                        }>
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, i) => (
                          <option
                            key={month}
                            value={i}>
                            {month}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        type="button"
                        className={styles.navButton}>
                        {">"}
                      </button>
                    </div>
                  )}
                />
              </div>

              <div className={styles.formGroup}>
                <label>Name of {entryType}:</label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={`Enter ${entryType} name`}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label>Unit (optional):</label>
                <input
                  type="text"
                  value={unit}
                  onChange={e => setUnit(e.target.value)}
                  placeholder={
                    entryType === "food"
                      ? "e.g. grams, servings"
                      : "e.g. minutes, reps"
                  }
                />
              </div>

              <div className={styles.formGroup}>
                <label>
                  Calories {entryType === "food" ? "in" : "burned"} (optional):
                </label>
                <input
                  type="number"
                  value={calories}
                  onChange={e => setCalories(e.target.value)}
                  placeholder="Enter calories"
                  min="0"
                />
              </div>

              <button type="submit">Add {entryType}</button>
            </form>
          </div>

          <div className={styles.entriesByWeek}>
            <h2>Your Entries</h2>
            {Object.keys(dataByWeek).length > 0 ? (
              Object.keys(dataByWeek)
                .sort((a, b) => b.localeCompare(a)) // Sort weeks in descending order
                .map(weekId => (
                  <div
                    key={weekId}
                    className={styles.weekEntry}>
                    <div className={styles.weekHeader}>
                      <div className={styles.weekHeaderContent}>
                        <button
                          className={`${styles.toggleButton} ${styles.weekToggle}`}
                          onClick={() => toggleWeekExpansion(weekId)}>
                          {expandedWeeks[weekId] ? "−" : "+"}
                        </button>
                        <h3 onClick={() => toggleWeekExpansion(weekId)}>
                          {formatWeekDisplay(weekId)}
                        </h3>
                      </div>
                    </div>

                    {expandedWeeks[weekId] && (
                      <div className={styles.weekContent}>
                        {Object.keys(dataByWeek[weekId])
                          .sort((a, b) => new Date(b) - new Date(a))
                          .map(dateStr => {
                            const dayKey = `${weekId}-${dateStr}`;
                            const isDayExpanded = expandedDays[dayKey];

                            return (
                              <div
                                key={dateStr}
                                className={styles.dayEntry}>
                                <div className={styles.dayHeader}>
                                  <button
                                    className={`${styles.toggleButton} ${styles.dayToggle}`}
                                    onClick={() =>
                                      toggleDayExpansion(weekId, dateStr)
                                    }>
                                    {isDayExpanded ? "−" : "+"}
                                  </button>
                                  <h4
                                    onClick={() =>
                                      toggleDayExpansion(weekId, dateStr)
                                    }>
                                    {new Date(
                                      dataByWeek[weekId][dateStr].date
                                    ).toLocaleDateString(undefined, {
                                      weekday: "long",
                                      month: "short",
                                      day: "numeric",
                                    })}
                                  </h4>
                                </div>

                                {isDayExpanded && (
                                  <div className={styles.dayContent}>
                                    {dataByWeek[weekId][dateStr].food.length >
                                      0 && (
                                      <div className={styles.foodEntries}>
                                        <h5>Food</h5>
                                        <ul>
                                          {dataByWeek[weekId][dateStr].food.map(
                                            (item, index) => (
                                              <li key={`food-${index}`}>
                                                <div
                                                  className={
                                                    styles.entryContent
                                                  }>
                                                  <span
                                                    className={
                                                      styles.entryName
                                                    }>
                                                    {item.name}{" "}
                                                    {item.unit
                                                      ? `(${item.unit})`
                                                      : ""}
                                                    {item.calories
                                                      ? ` - ${item.calories} calories`
                                                      : ""}
                                                  </span>
                                                  <button
                                                    className={
                                                      styles.deleteButton
                                                    }
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      handleDelete(
                                                        item.id,
                                                        "food"
                                                      );
                                                    }}>
                                                    ×
                                                  </button>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    )}

                                    {dataByWeek[weekId][dateStr].exercise
                                      .length > 0 && (
                                      <div className={styles.exerciseEntries}>
                                        <h5>Exercise</h5>
                                        <ul>
                                          {dataByWeek[weekId][
                                            dateStr
                                          ].exercise.map((item, index) => (
                                            <li key={`exercise-${index}`}>
                                              <div
                                                className={styles.entryContent}>
                                                <span
                                                  className={styles.entryName}>
                                                  {item.name}{" "}
                                                  {item.unit
                                                    ? `(${item.unit})`
                                                    : ""}
                                                  {item.calories
                                                    ? ` - ${item.calories} calories`
                                                    : ""}
                                                </span>
                                                <button
                                                  className={
                                                    styles.deleteButton
                                                  }
                                                  onClick={e => {
                                                    e.stopPropagation();
                                                    handleDelete(
                                                      item.id,
                                                      "exercise"
                                                    );
                                                  }}>
                                                  ×
                                                </button>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <p>
                No entries yet. Start tracking your food and exercise above!
              </p>
            )}
          </div>

          <div className={styles.entriesGraph}>
            <h2>Calorie History</h2>
            <div className={styles.chartContainer}>
              {timeSeriesData.labels.length > 0 ? (
                <Line
                  data={calorieChartData}
                  options={chartOptions}
                  height={300}
                />
              ) : (
                <p>No data available to display in the graph</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.loginPrompt}>
          <p>Please log in to track your food and exercise.</p>
        </div>
      )}
    </div>
  );
};

export default FoodAndExercise;
