import React, { useState, useEffect } from "react";
import styles from "./LocalCouchBattler.module.scss";

const PLAYER_CONTROLS = {
  1: { up: "w", down: "s", left: "a", right: "d", action: "q" },
  2: { up: "y", down: "h", left: "g", right: "j", action: "t" },
  3: { up: "o", down: "l", left: "k", right: ";", action: "i" },
  4: {
    up: "ArrowUp",
    down: "ArrowDown",
    left: "ArrowLeft",
    right: "ArrowRight",
    action: "/",
  },
};

const CHARACTER_CLASSES = {
  WARRIOR: {
    name: "Warrior",
    stats: {
      health: 150,
      attack: 15,
      defense: 10,
      speed: 3,
    },
  },
  ARCHER: {
    name: "Archer",
    stats: {
      health: 100,
      attack: 12,
      defense: 5,
      speed: 4,
    },
  },
  MAGE: {
    name: "Mage",
    stats: {
      health: 80,
      attack: 20,
      defense: 3,
      speed: 3,
    },
  },
};

const LocalCouchBattler = () => {
  const [players, setPlayers] = useState([
    {
      id: 1,
      controls: PLAYER_CONTROLS[1],
      selectedClass: null,
      stats: null,
      position: 0,
      menuPosition: 0,
    },
  ]);

  const [activeMenus, setActiveMenus] = useState({
    1: "class_select",
  });

  const [anyPlayerSelectedClass, setAnyPlayerSelectedClass] = useState(false);

  const handleKeyPress = event => {
    const key = event.key.toLowerCase();

    players.forEach(player => {
      const { controls, id } = player;
      const normalizedControls = {
        up: controls.up.toLowerCase(),
        down: controls.down.toLowerCase(),
        left: controls.left.toLowerCase(),
        right: controls.right.toLowerCase(),
        action: controls.action.toLowerCase(),
      };

      if (key === normalizedControls.action) {
        if (activeMenus[id] === "class_select") {
          const selectedClass =
            CHARACTER_CLASSES[["WARRIOR", "ARCHER", "MAGE"][player.position]];

          setPlayers(prev =>
            prev.map(p =>
              p.id === id
                ? {
                    ...p,
                    selectedClass: selectedClass.name,
                    stats: selectedClass.stats,
                    menuPosition: 0,
                  }
                : p
            )
          );

          setActiveMenus(prev => ({
            ...prev,
            [id]: "main_game",
          }));

          setAnyPlayerSelectedClass(true);
        } else if (activeMenus[id] === "main_game") {
          const actions = ["Combat", "Rest", "Upgrade"];
          const selectedAction = actions[player.menuPosition];

          console.log(`Player ${id} selected ${selectedAction}`);
        }
      }

      if (key === normalizedControls.right) {
        setPlayers(prev =>
          prev.map(p => {
            if (p.id === id) {
              if (activeMenus[id] === "class_select") {
                return { ...p, position: (p.position + 1) % 3 };
              } else if (activeMenus[id] === "main_game") {
                return { ...p, menuPosition: (p.menuPosition + 1) % 3 };
              }
              return p;
            }
            return p;
          })
        );
      }

      if (key === normalizedControls.left) {
        setPlayers(prev =>
          prev.map(p => {
            if (p.id === id) {
              if (activeMenus[id] === "class_select") {
                return { ...p, position: (p.position - 1 + 3) % 3 };
              } else if (activeMenus[id] === "main_game") {
                return { ...p, menuPosition: (p.menuPosition - 1 + 3) % 3 };
              }
              return p;
            }
            return p;
          })
        );
      }
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [players, activeMenus]);

  useEffect(() => {
    const hasClassSelected = players.some(
      player => player.selectedClass !== null
    );
    if (hasClassSelected) {
      setAnyPlayerSelectedClass(true);
    }
  }, [players]);

  const addPlayer = () => {
    if (players.length < 4) {
      const newPlayerId = players.length + 1;
      setPlayers(prev => [
        ...prev,
        {
          id: newPlayerId,
          controls: PLAYER_CONTROLS[newPlayerId],
          selectedClass: null,
          stats: null,
          position: 0,
          menuPosition: 0,
        },
      ]);
      setActiveMenus(prev => ({
        ...prev,
        [newPlayerId]: "class_select",
      }));
    }
  };

  return (
    <div className={styles.localCouchBattler}>
      <div className={styles.gameHeader}>
        {players.length < 4 && !anyPlayerSelectedClass && (
          <button onClick={addPlayer}>Add Player</button>
        )}
      </div>

      <div className={styles.gameBoards}>
        {players.map(player => (
          <div
            key={player.id}
            className={styles.playerBoard}>
            <h2>Player {player.id}</h2>
            {activeMenus[player.id] === "class_select" ? (
              <div className={styles.classSelect}>
                <h3>Choose Your Class</h3>
                <div className={styles.classOptions}>
                  {["WARRIOR", "ARCHER", "MAGE"].map((className, index) => (
                    <div
                      key={className}
                      className={`${styles.classOption} ${
                        player.position === index ? styles.selected : ""
                      }`}>
                      {CHARACTER_CLASSES[className].name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={styles.playerStats}>
                <h3>{player.selectedClass}</h3>
                <div className={styles.statsDisplay}>
                  <div>Health: {player.stats.health}</div>
                  <div>Attack: {player.stats.attack}</div>
                  <div>Defense: {player.stats.defense}</div>
                  <div>Speed: {player.stats.speed}</div>
                </div>
                <div className={styles.playerActions}>
                  <h4>Actions</h4>
                  <div className={styles.actionOptions}>
                    {["Combat", "Rest", "Upgrade"].map((action, index) => (
                      <div
                        key={action}
                        className={`${styles.actionOption} ${
                          player.menuPosition === index ? styles.selected : ""
                        }`}>
                        {action}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocalCouchBattler;
