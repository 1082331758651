import React from "react";
import styles from "./DuelCards.module.scss";
import ICON_LIFE from "./assets/ICON_LIFE.svg";
import ICON_ATTACK from "./assets/ICON_ATTACK.svg";
import ICON_DEFENSE from "./assets/ICON_DEFENSE.svg";
import ICON_RESOURCES from "./assets/ICON_RESOURCES.svg";
import ICON_RANDOM from "./assets/ICON_RANDOM.svg";
import ICON_ALTHIAN from "./assets/ICON_ALTHIAN.svg";
import ICON_NATORAN from "./assets/ICON_NATORAN.svg";
import ICON_XENOZORIAN from "./assets/ICON_XENOZORIAN.svg";
import ICON_NECRONIAN from "./assets/ICON_NECRONIAN.svg";

function getSpellDamageValues(cardData) {
  if (!cardData.triggers) return null;
  const damageEffects = cardData.triggers
    .flatMap(t => t.effect)
    .filter(e => e.type === "damage");
  if (!damageEffects.length) return null;
  const damageValues = [];
  for (let level = 1; level <= 3; level++) {
    const found = damageEffects.find(e => e.level === level);
    damageValues.push(found ? found.value : 0);
  }
  return damageValues;
}

const factionIcons = {
  althian: ICON_ALTHIAN,
  natoran: ICON_NATORAN,
  xenozorian: ICON_XENOZORIAN,
  necronian: ICON_NECRONIAN,
};

export default function ExamineCard({ cardData, onClose }) {
  const damageValues = getSpellDamageValues(cardData);

  return (
    <div
      className={styles.modalOverlay}
      onClick={onClose}>
      <div
        className={styles.modalContent}
        onClick={e => e.stopPropagation()}>
        <button
          className={styles.closeButton}
          onClick={onClose}>
          Close
        </button>
        <h2>{cardData.name}</h2>
        <img
          src={cardData.image}
          alt={cardData.name}
          className={styles.modalImg}
        />
        <div className={styles.factionIconWrapper}>
          <img
            src={factionIcons[cardData.faction?.toLowerCase()] || ICON_RANDOM}
            alt={cardData.faction}
            className={styles.factionIcon}
          />
        </div>
        <div className={styles.flavorText}>{cardData.flavorText}</div>
        <div className={styles.statsContainer}>
          <div className={styles.statsSections}>
            <div>
              <h4>
                <img
                  src={ICON_RESOURCES}
                  alt="Cost"
                  className={styles.factionIcon}
                />
              </h4>
              {Array.isArray(cardData.cost) &&
                cardData.cost.map((lvl, i) => (
                  <div key={i}>
                    LV{lvl.level}: {lvl.value}
                  </div>
                ))}
            </div>
            <div>
              <h4>
                <img
                  src={ICON_LIFE}
                  alt="Health"
                  className={styles.factionIcon}
                />
              </h4>
              {Array.isArray(cardData.health) &&
                cardData.health.map((lvl, i) => (
                  <div key={i}>
                    LV{lvl.level}: {lvl.value}
                  </div>
                ))}
            </div>
            <div>
              <h4>
                <img
                  src={ICON_ATTACK}
                  alt="Attack"
                  className={styles.factionIcon}
                />
              </h4>
              {cardData.cardType === "spell" && damageValues
                ? damageValues.map((val, i) => (
                    <div key={i}>
                      LV{i + 1}: {val}
                    </div>
                  ))
                : Array.isArray(cardData.attack) &&
                  cardData.attack.map((lvl, i) => (
                    <div key={i}>
                      LV{lvl.level}: {lvl.value}
                    </div>
                  ))}
            </div>
            <div>
              <h4>
                <img
                  src={ICON_DEFENSE}
                  alt="Defense"
                  className={styles.factionIcon}
                />
              </h4>
              {Array.isArray(cardData.defense) &&
                cardData.defense.map((lvl, i) => (
                  <div key={i}>
                    LV{lvl.level}: {lvl.value}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.effectsSection}>
          <h3>Effects</h3>
          <div>{cardData.effectDescription || "No effect"}</div>
        </div>
      </div>
    </div>
  );
}
