import React, { useEffect, useRef, useState } from "react";
import styles from "./LastPlayedCardQueue.module.scss";
import ICON_LIFE from "./assets/ICON_LIFE.svg";
import ICON_ATTACK from "./assets/ICON_ATTACK.svg";
import ICON_DEFENSE from "./assets/ICON_DEFENSE.svg";
import ICON_RESOURCES from "./assets/ICON_RESOURCES.svg";
import ICON_RANDOM from "./assets/ICON_RANDOM.svg";
import ICON_ALTHIAN from "./assets/ICON_ALTHIAN.svg";
import ICON_NATORAN from "./assets/ICON_NATORAN.svg";
import ICON_XENOZORIAN from "./assets/ICON_XENOZORIAN.svg";
import ICON_NECRONIAN from "./assets/ICON_NECRONIAN.svg";

const factionIcons = {
  althian: ICON_ALTHIAN,
  natoran: ICON_NATORAN,
  xenozorian: ICON_XENOZORIAN,
  necronian: ICON_NECRONIAN,
};

export default function LastPlayedCardQueue({
  lastPlayedCards,
  setLastPlayedCards,
  cards,
}) {
  const [currentCard, setCurrentCard] = useState(null);
  const [isManualClose, setIsManualClose] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (!currentCard && lastPlayedCards.length > 0) {
      setCurrentCard(lastPlayedCards[0]);
      setLastPlayedCards(prev => prev.slice(1));
    }
  }, [currentCard, lastPlayedCards, setLastPlayedCards]);

  useEffect(() => {
    if (currentCard) {
      timerRef.current = setTimeout(() => {
        setCurrentCard(null);
        setIsManualClose(false);
      }, 3000);
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [currentCard]);

  if (!currentCard) {
    return null;
  }

  const handleClick = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    if (currentCard.type === "TURN_INDICATOR") {
      setCurrentCard(null);
      setIsManualClose(false);
    } else {
      setIsManualClose(true);
    }
  };

  const handleAnimationEnd = () => {
    if (isManualClose) {
      setCurrentCard(null);
      setIsManualClose(false);
    }
  };

  if (currentCard.type === "TURN_INDICATOR") {
    return (
      <div
        className={styles.overlay}
        onClick={handleClick}>
        <div
          className={styles.turnIndicatorContainer}
          onAnimationEnd={handleAnimationEnd}>
          <div className={styles.turnIndicatorText}>Your Turn</div>
        </div>
      </div>
    );
  }

  const fullCardData = cards.find(c => c.key === currentCard.cardName);
  const displayName = fullCardData ? fullCardData.name : currentCard.cardName;
  const displayImage = fullCardData ? fullCardData.image : "";
  const displayEffect = fullCardData?.effect || "";
  const attackObj =
    fullCardData?.attack?.find(a => a.level === currentCard.level) || {};
  const defenseObj =
    fullCardData?.defense?.find(d => d.level === currentCard.level) || {};
  const healthObj =
    fullCardData?.health?.find(h => h.level === currentCard.level) || {};
  const attack = attackObj.value || 0;
  const defense = defenseObj.value || 0;
  const health = healthObj.value || 0;
  const factionIconSrc =
    factionIcons[fullCardData?.faction?.toLowerCase()] || ICON_RANDOM;

  return (
    <div
      className={styles.overlay}
      onClick={handleClick}>
      <div
        className={
          isManualClose
            ? `${styles.cardContainer} ${styles.manualFade}`
            : styles.cardContainer
        }
        onAnimationEnd={handleAnimationEnd}>
        <div className={styles.cardTitle}>{displayName}</div>
        <img
          src={factionIconSrc}
          alt={fullCardData?.faction}
          className={styles.factionIcon}
        />
        <img
          src={displayImage}
          alt={currentCard.cardName}
          className={styles.cardImage}
        />
        <div className={styles.statsRow}>
          {attack > 0 && (
            <div className={styles.stat}>
              <img
                src={ICON_ATTACK}
                alt="Attack"
                className={styles.icon}
              />
              {attack}
            </div>
          )}
          {defense > 0 && (
            <div className={styles.stat}>
              <img
                src={ICON_DEFENSE}
                alt="Defense"
                className={styles.icon}
              />
              {defense}
            </div>
          )}
          {health > 0 && (
            <div className={styles.stat}>
              <img
                src={ICON_LIFE}
                alt="Health"
                className={styles.icon}
              />
              {health}
            </div>
          )}
        </div>
        {displayEffect && (
          <div className={styles.effectText}>{displayEffect}</div>
        )}
        <div className={styles.playerLabel}>{currentCard.playedBy}</div>
      </div>
    </div>
  );
}
