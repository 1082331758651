import CREATURE_SLIME from "./assets/CREATURE_SLIME.svg";
import CREATURE_ZOMBIE from "./assets/CREATURE_ZOMBIE.svg";
import CREATURE_SKELETON from "./assets/CREATURE_SKELETON.svg";
import CREATURE_WITCH from "./assets/CREATURE_WITCH.svg";
import CREATURE_FAIRY from "./assets/CREATURE_FAIRY.svg";
import CREATURE_DJINN from "./assets/CREATURE_DJINN.svg";
import CREATURE_CENTAUR from "./assets/CREATURE_CENTAUR.svg";
import CREATURE_DRAGON from "./assets/CREATURE_DRAGON.svg";
import CREATURE_GARGOYLE from "./assets/CREATURE_GARGOYLE.svg";
import CREATURE_GHOST from "./assets/CREATURE_GHOST.svg";
import CREATURE_GOBLIN from "./assets/CREATURE_GOBLIN.svg";
import CREATURE_GOLEM from "./assets/CREATURE_GOLEM.svg";
import CREATURE_IMP from "./assets/CREATURE_IMP.svg";
import CREATURE_MIMIC from "./assets/CREATURE_MIMIC.svg";
import CREATURE_MIND_FLAYER from "./assets/CREATURE_MIND_FLAYER.svg";
import CREATURE_MINOTAUR from "./assets/CREATURE_MINOTAUR.svg";
import CREATURE_MUMMY from "./assets/CREATURE_MUMMY.svg";
import CREATURE_NAGA from "./assets/CREATURE_NAGA.svg";
import CREATURE_ONI from "./assets/CREATURE_ONI.svg";
import CREATURE_SPIDER from "./assets/CREATURE_SPIDER.svg";
import CREATURE_WEREWOLF from "./assets/CREATURE_WEREWOLF.svg";
import CREATURE_YETI from "./assets/CREATURE_YETI.svg";

export const creatureImages = {
  slime: CREATURE_SLIME,
  zombie: CREATURE_ZOMBIE,
  skeleton: CREATURE_SKELETON,
  witch: CREATURE_WITCH,
  fairy: CREATURE_FAIRY,
  djinn: CREATURE_DJINN,
  centaur: CREATURE_CENTAUR,
  dragon: CREATURE_DRAGON,
  gargoyle: CREATURE_GARGOYLE,
  ghost: CREATURE_GHOST,
  goblin: CREATURE_GOBLIN,
  golem: CREATURE_GOLEM,
  imp: CREATURE_IMP,
  mimic: CREATURE_MIMIC,
  mind_flayer: CREATURE_MIND_FLAYER,
  minotaur: CREATURE_MINOTAUR,
  mummy: CREATURE_MUMMY,
  naga: CREATURE_NAGA,
  oni: CREATURE_ONI,
  spider: CREATURE_SPIDER,
  werewolf: CREATURE_WEREWOLF,
  yeti: CREATURE_YETI,
};
