import Phaser from "phaser";

class NotForagerWorldScene extends Phaser.Scene {
  constructor() {
    super({ key: "NotForagerWorldScene" });
  }

  init(data) {
    this.username = data.username || "Guest";
  }

  create() {
    let environment = "";
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        this.ws = new WebSocket("ws://localhost:5000/not-forager");
        break;
      case "localNetwork":
        this.ws = new WebSocket("ws://192.168.0.7:5000/not-forager");
        break;
      case "production":
        this.ws = new WebSocket("wss://2nguyen.dev/not-forager");
        break;
      default:
        break;
    }

    this.players = {};
    this.dots = {};
    this.blocks = {};
    this.localPlayerId = null;

    this.inBuildMode = false;
    this.selectedBlockSize = null;
    this.ghostBlock = null;
    this.lastClickPos = null;
    this.cancelThreshold = 10;

    this.ws.onopen = () => {
      this.ws.send(JSON.stringify({ type: "join", username: this.username }));
    };

    this.ws.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "playerId") {
        this.localPlayerId = data.playerId;
      } else if (data.type === "gameState") {
        const newPlayers = data.players;
        const newDots = data.dots;
        const newBlocks = data.blocks;

        Object.keys(newPlayers).forEach(id => {
          const pd = newPlayers[id];
          if (!this.players[id]) {
            const box = this.add.rectangle(pd.x, pd.y, 20, 20, 0x007ba7);
            const scoreText = this.add
              .text(pd.x, pd.y - 30, pd.score.toString(), { color: "#00ff00" })
              .setOrigin(0.5);
            const usernameText = this.add
              .text(pd.x, pd.y + 30, pd.username, { color: "#ffffff" })
              .setOrigin(0.5);
            this.players[id] = {
              box,
              scoreText,
              usernameText,
              score: pd.score,
            };
          } else {
            this.players[id].score = pd.score;
            this.players[id].box.x = pd.x;
            this.players[id].box.y = pd.y;
            this.players[id].scoreText.x = pd.x;
            this.players[id].scoreText.y = pd.y - 30;
            this.players[id].scoreText.setText(pd.score.toString());
            this.players[id].usernameText.x = pd.x;
            this.players[id].usernameText.y = pd.y + 30;
            this.players[id].usernameText.setText(pd.username);
          }
        });

        Object.keys(this.players).forEach(id => {
          if (!newPlayers[id]) {
            this.players[id].box.destroy();
            this.players[id].scoreText.destroy();
            this.players[id].usernameText.destroy();
            delete this.players[id];
          }
        });

        Object.keys(newDots).forEach(dotId => {
          const dotData = newDots[dotId];
          if (!this.dots[dotId]) {
            const dot = this.add.circle(dotData.x, dotData.y, 5, 0xff00ff);
            this.dots[dotId] = { dot };
          } else {
            this.dots[dotId].dot.x = dotData.x;
            this.dots[dotId].dot.y = dotData.y;
          }
        });

        Object.keys(this.dots).forEach(dotId => {
          if (!newDots[dotId]) {
            this.dots[dotId].dot.destroy();
            delete this.dots[dotId];
          }
        });

        Object.keys(newBlocks).forEach(blockId => {
          const blockData = newBlocks[blockId];
          if (!this.blocks[blockId]) {
            const block = this.add.rectangle(
              blockData.x,
              blockData.y,
              blockData.size,
              blockData.size,
              0x8b4513
            );
            this.blocks[blockId] = { block };
          } else {
            this.blocks[blockId].block.x = blockData.x;
            this.blocks[blockId].block.y = blockData.y;
          }
        });

        Object.keys(this.blocks).forEach(blockId => {
          if (!newBlocks[blockId]) {
            this.blocks[blockId].block.destroy();
            delete this.blocks[blockId];
          }
        });
      }
    };

    this.input.on("pointerdown", pointer => {
      if (this.inBuildMode) {
        this.handleBuildModeClick(pointer);
      } else {
        this.handleNormalClick(pointer);
      }
    });

    this.cameras.main.setBounds(0, 0, 4320, 2880);
    this.bg = this.add.graphics();
    const chunk = 240;
    const colors = [0x000000, 0x333333, 0x666666, 0x999999, 0xcccccc, 0xffffff];
    for (let i = 0; i < 4320; i += chunk) {
      for (let j = 0; j < 2880; j += chunk) {
        const color = Phaser.Utils.Array.GetRandom(colors);
        this.bg.fillStyle(color, 1);
        this.bg.fillRect(i, j, chunk, chunk);
      }
    }

    this.border = this.add.graphics();
    this.border.lineStyle(4, 0xffffff);
    this.border.strokeRect(0, 0, 4320, 2880);

    this.miniCam = this.cameras.add(this.game.config.width - 250, 20, 200, 150);
    this.miniCam.setBounds(0, 0, 4320, 2880);
    this.miniCam.setBackgroundColor(0x000000);
    this.miniCam.setZoom(0.1);

    this.miniMapBorder = this.add.graphics().setScrollFactor(0);
    this.miniMapBorder.lineStyle(2, 0xffffff);
    this.miniMapBorder.strokeRect(this.game.config.width - 250, 20, 200, 150);
    this.miniMapBorder.setDepth(9999);

    this.hotbar = this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height - 30,
      300,
      40,
      0x444444
    );
    this.hotbar.setScrollFactor(0);
    this.hotbar.setOrigin(0.5);

    this.hotbarCell1 = this.add.rectangle(
      this.cameras.main.width / 2 - 100,
      this.cameras.main.height - 30,
      60,
      40,
      0x666666
    );
    this.hotbarCell1.setScrollFactor(0);
    this.hotbarCell1.setInteractive();
    this.hotbarCell1.setOrigin(0.5);

    this.hotbarCell2 = this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height - 30,
      60,
      40,
      0x888888
    );
    this.hotbarCell2.setScrollFactor(0);
    this.hotbarCell2.setInteractive();
    this.hotbarCell2.setOrigin(0.5);

    this.hotbarCell3 = this.add.rectangle(
      this.cameras.main.width / 2 + 100,
      this.cameras.main.height - 30,
      60,
      40,
      0xaaaaaa
    );
    this.hotbarCell3.setScrollFactor(0);
    this.hotbarCell3.setInteractive();
    this.hotbarCell3.setOrigin(0.5);

    this.hotbarText1 = this.add
      .text(this.hotbarCell1.x, this.hotbarCell1.y, "10x10\n(1)", {
        color: "#ffffff",
        fontSize: "14px",
      })
      .setScrollFactor(0)
      .setOrigin(0.5);

    this.hotbarText2 = this.add
      .text(this.hotbarCell2.x, this.hotbarCell2.y, "25x25\n(5)", {
        color: "#ffffff",
        fontSize: "14px",
      })
      .setScrollFactor(0)
      .setOrigin(0.5);

    this.hotbarText3 = this.add
      .text(this.hotbarCell3.x, this.hotbarCell3.y, "50x50\n(10)", {
        color: "#ffffff",
        fontSize: "14px",
      })
      .setScrollFactor(0)
      .setOrigin(0.5);

    this.hotbarCell1.on("pointerdown", () => {
      this.tryBuild(10, 1);
    });
    this.hotbarCell2.on("pointerdown", () => {
      this.tryBuild(25, 5);
    });
    this.hotbarCell3.on("pointerdown", () => {
      this.tryBuild(50, 10);
    });
  }

  tryBuild(size, cost) {
    const localPlayer = this.getLocalPlayerData();
    if (!localPlayer) {
      return;
    }
    if (localPlayer.score < cost) {
      return;
    }
    this.inBuildMode = true;
    this.selectedBlockSize = size;
    const px = localPlayer.box.x;
    const py = localPlayer.box.y;
    this.ghostBlock = this.add.rectangle(px, py, size, size, 0xffffff, 0.3);
  }

  handleBuildModeClick(pointer) {
    if (!this.ghostBlock) return;
    const x = pointer.worldX;
    const y = pointer.worldY;
    if (!this.lastClickPos) {
      this.ghostBlock.x = x;
      this.ghostBlock.y = y;
      this.lastClickPos = { x, y };
      return;
    }
    const dx = x - this.lastClickPos.x;
    const dy = y - this.lastClickPos.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    if (dist < this.cancelThreshold) {
      this.placeBlock(x, y);
    } else {
      this.ghostBlock.x = x;
      this.ghostBlock.y = y;
      this.lastClickPos = { x, y };
    }
  }

  placeBlock(x, y) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: "placeBlock",
          size: this.selectedBlockSize,
          x,
          y,
        })
      );
    }
    this.cancelBuildMode();
  }

  cancelBuildMode() {
    this.inBuildMode = false;
    this.selectedBlockSize = null;
    this.lastClickPos = null;
    if (this.ghostBlock) {
      this.ghostBlock.destroy();
      this.ghostBlock = null;
    }
  }

  handleNormalClick(pointer) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({ type: "move", x: pointer.worldX, y: pointer.worldY })
      );
    }
  }

  getLocalPlayerData() {
    if (!this.localPlayerId) return null;
    return this.players[this.localPlayerId] || null;
  }

  update() {
    if (this.localPlayerId && this.players[this.localPlayerId]) {
      const box = this.players[this.localPlayerId].box;
      this.cameras.main.startFollow(box, true, 0.05, 0.05);
      this.miniCam.startFollow(box, true, 0.05, 0.05);
    }
  }

  shutdown() {
    if (this.ws) {
      this.ws.close();
    }
  }

  destroy() {
    this.shutdown();
  }
}

export default NotForagerWorldScene;
