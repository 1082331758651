import React from "react";
import Swal from "sweetalert2";
import styles from "./DuelCards.module.scss";
import ICON_ALTHIAN from "./assets/ICON_ALTHIAN.svg";
import ICON_NATORAN from "./assets/ICON_NATORAN.svg";
import ICON_XENOZORIAN from "./assets/ICON_XENOZORIAN.svg";
import ICON_NECRONIAN from "./assets/ICON_NECRONIAN.svg";
import ICON_RANDOM from "./assets/ICON_RANDOM.svg";

const factionIcons = {
  althian: ICON_ALTHIAN,
  natoran: ICON_NATORAN,
  xenozorian: ICON_XENOZORIAN,
  necronian: ICON_NECRONIAN,
};

export default function CardShop({ ws, gold, returnToMenu }) {
  function buySingle() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_SINGLE",
      })
    );
  }

  function buyFive() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_FIVE",
      })
    );
  }

  function buySingleAlthian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_SINGLE_ALTHIAN",
      })
    );
  }

  function buyFiveAlthian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_FIVE_ALTHIAN",
      })
    );
  }

  function buySingleNatoran() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_SINGLE_NATORAN",
      })
    );
  }

  function buyFiveNatoran() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_FIVE_NATORAN",
      })
    );
  }

  function buySingleXenozorian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_SINGLE_XENOZORIAN",
      })
    );
  }

  function buyFiveXenozorian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_FIVE_XENOZORIAN",
      })
    );
  }

  function buySingleNecronian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_SINGLE_NECRONIAN",
      })
    );
  }

  function buyFiveNecronian() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "SHOP",
        action: "BUY_FIVE_NECRONIAN",
      })
    );
  }

  return (
    <div className={styles.shopContainer}>
      <h2>Card Shop</h2>
      <button onClick={returnToMenu}>Return to Menu</button>
      <div>Gold: {gold}</div>

      <div className={styles.shopRow}>
        <button
          onClick={buySingle}
          className={styles.iconButton}>
          <span>1x</span>
          <img
            src={ICON_RANDOM}
            alt="random"
          />
          <span>25G</span>
        </button>
        <button
          onClick={buyFive}
          className={styles.iconButton}>
          <span>5x</span>
          <img
            src={ICON_RANDOM}
            alt="random"
          />
          <span>100G</span>
        </button>
      </div>

      <div className={styles.shopRow}>
        <button
          onClick={buySingleAlthian}
          className={styles.iconButton}>
          <span>1x</span>
          <img
            src={factionIcons.althian}
            alt="althian"
          />
          <span>50G</span>
        </button>
        <button
          onClick={buyFiveAlthian}
          className={styles.iconButton}>
          <span>5x</span>
          <img
            src={factionIcons.althian}
            alt="althian"
          />
          <span>200G</span>
        </button>
      </div>

      <div className={styles.shopRow}>
        <button
          onClick={buySingleNatoran}
          className={styles.iconButton}>
          <span>1x</span>
          <img
            src={factionIcons.natoran}
            alt="natoran"
          />
          <span>50G</span>
        </button>
        <button
          onClick={buyFiveNatoran}
          className={styles.iconButton}>
          <span>5x</span>
          <img
            src={factionIcons.natoran}
            alt="natoran"
          />
          <span>200G</span>
        </button>
      </div>

      <div className={styles.shopRow}>
        <button
          onClick={buySingleXenozorian}
          className={styles.iconButton}>
          <span>1x</span>
          <img
            src={factionIcons.xenozorian}
            alt="xenozorian"
          />
          <span>50G</span>
        </button>
        <button
          onClick={buyFiveXenozorian}
          className={styles.iconButton}>
          <span>5x</span>
          <img
            src={factionIcons.xenozorian}
            alt="xenozorian"
          />
          <span>200G</span>
        </button>
      </div>

      <div className={styles.shopRow}>
        <button
          onClick={buySingleNecronian}
          className={styles.iconButton}>
          <span>1x</span>
          <img
            src={factionIcons.necronian}
            alt="necronian"
          />
          <span>50G</span>
        </button>
        <button
          onClick={buyFiveNecronian}
          className={styles.iconButton}>
          <span>5x</span>
          <img
            src={factionIcons.necronian}
            alt="necronian"
          />
          <span>200G</span>
        </button>
      </div>
    </div>
  );
}
