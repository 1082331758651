import React from "react";
import Swal from "sweetalert2";

export default function Matchmaking({
  wins,
  losses,
  ws,
  inGame,
  isQueueing,
  hasPreviousGame,
  setIsQueueing,
  returnToMenu,
}) {
  function doJoinQueue() {
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "QUEUE",
          action: "JOIN",
        })
      );
      setIsQueueing(true);
    }
  }

  function joinQueue() {
    if (hasPreviousGame) {
      Swal.fire({
        icon: "warning",
        title: "Forfeit Previous Game?",
        text: "If you queue for a new game, you will forfeit your previous game.",
        showCancelButton: true,
        confirmButtonText: "Yes, forfeit my old game",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          if (ws) {
            ws.send(
              JSON.stringify({
                type: "GAME",
                action: "FORFEIT",
              })
            );
          }
          doJoinQueue();
        }
      });
    } else {
      doJoinQueue();
    }
  }

  function cancelQueue() {
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "QUEUE",
          action: "CANCEL",
        })
      );
      setIsQueueing(false);
    }
  }

  function rejoinGame() {
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "GAME",
          action: "REJOIN",
        })
      );
    }
  }

  return (
    <div>
      <h2>Matchmaking</h2>
      <div>
        Wins: {wins}|Losses: {losses}
      </div>
      <button onClick={returnToMenu}>Return to Menu</button>
      <br />
      <br />
      {!inGame && !isQueueing && !hasPreviousGame && (
        <button onClick={joinQueue}>Queue for a Game</button>
      )}
      {!inGame && !isQueueing && hasPreviousGame && (
        <>
          <button onClick={rejoinGame}>Rejoin Previous Game</button>
          <button onClick={joinQueue}>Queue for a New Game</button>
        </>
      )}
      {!inGame && isQueueing && (
        <button onClick={cancelQueue}>Cancel Queue</button>
      )}
    </div>
  );
}
