import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import styles from "./MonsterIdle.module.scss";

const trainingMap = {
  Weightlifting: "strength",
  "Running Track": "dexterity",
  Library: "wisdom",
};

function MonsterIdle() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const [navItems, setNavItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [actionLabel, setActionLabel] = useState("");
  const [actionType, setActionType] = useState(null);
  const [actionTimer, setActionTimer] = useState(0);
  const [actionFraction, setActionFraction] = useState(0);
  const [actionTransition, setActionTransition] = useState("0s");
  const lastActionFractionRef = useRef(0);
  const lastActionIdRef = useRef(null);

  function getTrainingStat(monster) {
    if (!monster || !monster.trainingType) return null;
    const key = trainingMap[monster.trainingType];
    if (!key) return null;
    return monster[key];
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setIsNavExpanded(false);
    } else {
      setIsNavExpanded(true);
    }
  }, [isMobile]);

  useEffect(() => {
    let socket;
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    switch (environment) {
      case "localhost":
        socket = new WebSocket("ws://localhost:5000/monster-idle");
        break;
      case "localNetwork":
        socket = new WebSocket("ws://192.168.0.7:5000/monster-idle");
        break;
      case "production":
        socket = new WebSocket("wss://2nguyen.dev/monster-idle");
        break;
      default:
        return;
    }
    if (!socket) return;
    setWs(socket);

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );
    };

    socket.onclose = () => {
      setWs(null);
    };

    socket.onmessage = event => {
      const data = JSON.parse(event.data);

      if (data.type === "navigation" && data.action === "init") {
        setNavItems(data.navItems || []);
        if (data.navItems && data.navItems.length) {
          setSelectedItem(data.navItems[0]);
        }
      }

      if (data.type === "playerData" && data.action === "update") {
        setPlayerData(data.playerData);
        if (data.offlineProgress) {
          const { eggsHatched, monstersTrained } = data.offlineProgress;
          let summaryText = "";
          if (eggsHatched > 0) {
            summaryText += `Eggs Hatched: ${eggsHatched}<br/>`;
          }
          if (monstersTrained && monstersTrained.length > 0) {
            monstersTrained.forEach(mt => {
              summaryText += `Monster: ${mt.monsterType}, Trained: ${mt.stat}, XP Gained: ${mt.xpGained}, Levels Gained: ${mt.levelsGained}<br/>`;
            });
          }
          if (summaryText) {
            Swal.fire({
              title: "Offline Progress",
              html: summaryText,
              confirmButtonText: "OK",
            });
          }
        }
      }
    };

    return () => {
      if (socket) socket.close();
    };
  }, [user]);

  useEffect(() => {
    if (!playerData) {
      setActionLabel("");
      setActionType(null);
      setActionTimer(0);
      setActionFraction(0);
      setActionTransition("0s");
      lastActionFractionRef.current = 0;
      lastActionIdRef.current = null;
      return;
    }

    const activeEgg = playerData.eggs?.find(e => e.isHatching);
    const trainingMonster = playerData.monsters?.find(m => {
      if (!m.isTraining) return false;
      const s = getTrainingStat(m);
      if (!s) return false;
      return s.currentLevel < s.maxLevel;
    });
    const activePlot = playerData.farmland?.plots?.find(
      p => p.isGrowing || p.isHarvesting
    );

    if (!activeEgg && !trainingMonster && !activePlot) {
      setActionLabel("");
      setActionType(null);
      setActionTimer(0);
      setActionFraction(0);
      setActionTransition("0s");
      lastActionFractionRef.current = 0;
      lastActionIdRef.current = null;
      return;
    }

    if (activeEgg) {
      const total = activeEgg.requiredProgress;
      const current = activeEgg.currentProgress;
      const newFraction = total > 0 ? Math.min(current / total, 1) : 0;
      const timeLeft = (total - current) * (playerData.hatchingSpeed / 1000);
      setActionLabel(`Hatching ${activeEgg.type} Egg`);
      setActionType("egg");
      updateProgress(activeEgg.eggId, newFraction, timeLeft);
    } else if (trainingMonster) {
      const s = getTrainingStat(trainingMonster);
      if (!s) return;
      const neededXP = s.requiredXP;
      const currentXP = s.currentXP;
      const newFraction = neededXP > 0 ? Math.min(currentXP / neededXP, 1) : 0;
      const timeLeft =
        (neededXP - currentXP) * (playerData.trainingSpeed / 1000);
      setActionLabel(
        `Training ${trainingMonster.type} (${trainingMonster.trainingType} Lv.${s.currentLevel})`
      );
      setActionType("monster");
      updateProgress(trainingMonster.monsterId, newFraction, timeLeft);
    } else if (activePlot) {
      if (activePlot.isGrowing) {
        const total = activePlot.requiredProgress;
        const current = activePlot.currentProgress;
        const newFraction = total > 0 ? Math.min(current / total, 1) : 0;
        const timeLeft = (total - current) * (playerData.farmingSpeed / 1000);
        setActionLabel(
          `Growing ${activePlot.seedType} (Plot #${activePlot.plotNumber})`
        );
        setActionType("farm");
        updateProgress(activePlot.plotId, newFraction, timeLeft);
      } else if (activePlot.isHarvesting) {
        const total = activePlot.harvestRequired;
        const current = activePlot.harvestProgress;
        const newFraction = total > 0 ? Math.min(current / total, 1) : 0;
        const timeLeft = (total - current) * (playerData.farmingSpeed / 1000);
        setActionLabel(
          `Harvesting ${activePlot.seedType} (Plot #${activePlot.plotNumber})`
        );
        setActionType("farmHarvest");
        updateProgress(activePlot.plotId, newFraction, timeLeft);
      }
    }
  }, [playerData]);

  const updateProgress = (id, newFraction, timeLeft) => {
    setActionTimer(Math.max(0, Math.ceil(timeLeft)));
    if (
      id !== lastActionIdRef.current ||
      newFraction < lastActionFractionRef.current
    ) {
      lastActionIdRef.current = id;
      lastActionFractionRef.current = newFraction;
      setActionTransition("0s");
      setActionFraction(newFraction);
    } else {
      const diff = newFraction - lastActionFractionRef.current;
      // Use the actual timeLeft to animate properly
      // so shorter tasks end quickly and longer tasks animate longer
      const incDuration = timeLeft;
      setActionTransition(`${incDuration}s`);
      setActionFraction(newFraction);
      lastActionFractionRef.current = newFraction;
    }
  };

  const hasActiveAction = () => {
    if (!playerData) return false;
    if (playerData.eggs?.find(e => e.isHatching)) return true;
    if (playerData.monsters?.find(m => m.isTraining)) return true;
    if (playerData.farmland?.plots?.find(p => p.isGrowing || p.isHarvesting))
      return true;
    return false;
  };

  const cancelHatchingEgg = eggId => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "egg", action: "cancelHatching", eggId }));
  };

  const cancelMonsterTraining = monsterId => {
    if (!ws) return;
    ws.send(
      JSON.stringify({ type: "monster", action: "cancelTraining", monsterId })
    );
  };

  const cancelFarming = plotId => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "farming", action: "cancel", plotId }));
  };

  const cancelActiveAction = () => {
    if (!playerData) return;
    const activeEgg = playerData.eggs?.find(e => e.isHatching);
    const trainingMonster = playerData.monsters?.find(m => m.isTraining);
    const activePlot = playerData.farmland?.plots?.find(
      p => p.isGrowing || p.isHarvesting
    );
    if (activeEgg) {
      cancelHatchingEgg(activeEgg.eggId);
    } else if (trainingMonster) {
      cancelMonsterTraining(trainingMonster.monsterId);
    } else if (activePlot) {
      cancelFarming(activePlot.plotId);
    }
  };

  const promptCancelActiveAction = () => {
    return Swal.fire({
      title: "Cancel current action?",
      showCancelButton: true,
    }).then(res => {
      if (res.isConfirmed) {
        if (!playerData) return false;
        const activeEgg = playerData.eggs?.find(e => e.isHatching);
        const trainingMonster = playerData.monsters?.find(m => m.isTraining);
        const activePlot = playerData.farmland?.plots?.find(
          p => p.isGrowing || p.isHarvesting
        );
        if (activeEgg) {
          cancelHatchingEgg(activeEgg.eggId);
        } else if (trainingMonster) {
          cancelMonsterTraining(trainingMonster.monsterId);
        } else if (activePlot) {
          cancelFarming(activePlot.plotId);
        }
        return true;
      }
      return false;
    });
  };

  const chooseEgg = eggType => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "egg", action: "choose", eggType }));
  };

  const startHatchingEgg = eggId => {
    if (!ws || !playerData) return;
    const activeEgg = playerData.eggs?.find(e => e.isHatching);
    const trainingMonster = playerData.monsters?.find(m => m.isTraining);
    const activePlot = playerData.farmland?.plots?.find(
      p => p.isGrowing || p.isHarvesting
    );
    if (activeEgg || trainingMonster || activePlot) {
      Swal.fire({
        title: "Cancel current action and hatch this egg?",
        showCancelButton: true,
      }).then(res => {
        if (res.isConfirmed) {
          if (activeEgg) cancelHatchingEgg(activeEgg.eggId);
          if (trainingMonster) cancelMonsterTraining(trainingMonster.monsterId);
          if (activePlot) cancelFarming(activePlot.plotId);
          ws.send(
            JSON.stringify({ type: "egg", action: "startHatching", eggId })
          );
        }
      });
    } else {
      ws.send(JSON.stringify({ type: "egg", action: "startHatching", eggId }));
    }
  };

  const startTraining = (monsterId, trainingType) => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "monster",
        action: "startTraining",
        monsterId,
        trainingType,
      })
    );
  };

  const showMonsterDetails = monster => {
    if (selectedItem === "Training" && !monster.isTraining) {
      Swal.fire({
        title: `${monster.type}`,
        html: `<p><strong>Strength:</strong> LV.${monster.strength.currentLevel} (XP: ${monster.strength.currentXP}/${monster.strength.requiredXP}) Value: ${monster.strength.value} / Max:${monster.strength.maxLevel}</p>
          <p><strong>Dexterity:</strong> LV.${monster.dexterity.currentLevel} (XP: ${monster.dexterity.currentXP}/${monster.dexterity.requiredXP}) Value: ${monster.dexterity.value} / Max:${monster.dexterity.maxLevel}</p>
          <p><strong>Wisdom:</strong> LV.${monster.wisdom.currentLevel} (XP: ${monster.wisdom.currentXP}/${monster.wisdom.requiredXP}) Value: ${monster.wisdom.value} / Max:${monster.wisdom.maxLevel}</p>
          <p><strong>Luck:</strong> LV.${monster.luck.currentLevel} (XP: ${monster.luck.currentXP}/${monster.luck.requiredXP}) Value: ${monster.luck.value} / Max:${monster.luck.maxLevel}</p>
          <div style="display: flex; flex-direction: column; gap: 8px; margin-top: 12px;">
            <button id="weightliftBtn">Weightlifting</button>
            <button id="runningBtn">Running Track</button>
            <button id="libraryBtn">Library</button>
          </div>`,
        showConfirmButton: false,
        didOpen: () => {
          document
            .getElementById("weightliftBtn")
            .addEventListener("click", async () => {
              if (hasActiveAction()) {
                const cancelled = await promptCancelActiveAction();
                if (!cancelled) return;
              }
              startTraining(monster.monsterId, "Weightlifting");
              Swal.close();
            });
          document
            .getElementById("runningBtn")
            .addEventListener("click", async () => {
              if (hasActiveAction()) {
                const cancelled = await promptCancelActiveAction();
                if (!cancelled) return;
              }
              startTraining(monster.monsterId, "Running Track");
              Swal.close();
            });
          document
            .getElementById("libraryBtn")
            .addEventListener("click", async () => {
              if (hasActiveAction()) {
                const cancelled = await promptCancelActiveAction();
                if (!cancelled) return;
              }
              startTraining(monster.monsterId, "Library");
              Swal.close();
            });
        },
      });
    } else if (selectedItem === "Training" && monster.isTraining) {
      Swal.fire({
        title: `${monster.type}`,
        html: `<p><strong>Strength:</strong> LV.${monster.strength.currentLevel} (XP: ${monster.strength.currentXP}/${monster.strength.requiredXP}) Value: ${monster.strength.value}</p>
          <p><strong>Dexterity:</strong> LV.${monster.dexterity.currentLevel} (XP: ${monster.dexterity.currentXP}/${monster.dexterity.requiredXP}) Value: ${monster.dexterity.value}</p>
          <p><strong>Wisdom:</strong> LV.${monster.wisdom.currentLevel} (XP: ${monster.wisdom.currentXP}/${monster.wisdom.requiredXP}) Value: ${monster.wisdom.value}</p>
          <p><strong>Luck:</strong> LV.${monster.luck.currentLevel} (XP: ${monster.luck.currentXP}/${monster.luck.requiredXP}) Value: ${monster.luck.value}</p>
          <p>Currently training: ${monster.trainingType}</p>`,
        showConfirmButton: true,
      });
    } else {
      Swal.fire({
        title: `${monster.type}`,
        html: `<p><strong>Strength:</strong> LV.${monster.strength.currentLevel} (XP: ${monster.strength.currentXP}/${monster.strength.requiredXP}) Value: ${monster.strength.value}</p>
          <p><strong>Dexterity:</strong> LV.${monster.dexterity.currentLevel} (XP: ${monster.dexterity.currentXP}/${monster.dexterity.requiredXP}) Value: ${monster.dexterity.value}</p>
          <p><strong>Wisdom:</strong> LV.${monster.wisdom.currentLevel} (XP: ${monster.wisdom.currentXP}/${monster.wisdom.requiredXP}) Value: ${monster.wisdom.value}</p>
          <p><strong>Luck:</strong> LV.${monster.luck.currentLevel} (XP: ${monster.luck.currentXP}/${monster.luck.requiredXP}) Value: ${monster.luck.value}</p>`,
        showConfirmButton: true,
      });
    }
  };

  const renderHatchingContent = () => {
    if (!playerData) return null;
    if (!playerData.eggs || playerData.eggs.length === 0) {
      return <div>No eggs to hatch.</div>;
    }
    return (
      <div>
        {playerData.eggs.map(egg => {
          const totalSeconds =
            (egg.requiredProgress * playerData.hatchingSpeed) / 1000;
          if (egg.isHatching) {
            return (
              <div key={egg.eggId}>
                Hatching {egg.type} Egg ({totalSeconds}s total)
                <button onClick={() => cancelHatchingEgg(egg.eggId)}>
                  Cancel
                </button>
              </div>
            );
          }
          return (
            <div key={egg.eggId}>
              {egg.type} Egg ({totalSeconds}s)
              <button onClick={() => startHatchingEgg(egg.eggId)}>Hatch</button>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMonsters = () => {
    if (!playerData || !playerData.monsters) return null;
    if (playerData.monsters.length === 0) return <div>No monsters yet.</div>;
    return (
      <div>
        {playerData.monsters.map(mon => (
          <div
            key={mon.monsterId}
            style={{ marginBottom: "10px" }}>
            <button
              onClick={() => showMonsterDetails(mon)}
              title={`Str Lv.${mon.strength.currentLevel}, Dex Lv.${mon.dexterity.currentLevel}, Wis Lv.${mon.wisdom.currentLevel}, Luck Lv.${mon.luck.currentLevel}`}>
              {mon.type} Monster
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderTrainingFeedingContent = () => {
    return <div>{renderMonsters()}</div>;
  };

  const renderInventoryContent = () => {
    if (!playerData) return null;
    const seeds = playerData.seeds || [];
    return (
      <div>
        <div>Eggs</div>
        {playerData.eggs && playerData.eggs.length > 0 ? (
          <ul>
            {playerData.eggs.map(egg => {
              const totalSeconds =
                (egg.requiredProgress * playerData.hatchingSpeed) / 1000;
              return (
                <li key={egg.eggId}>
                  {egg.type} Egg ({totalSeconds}s)
                  {egg.isHatching ? " [Hatching]" : ""}
                </li>
              );
            })}
          </ul>
        ) : (
          <div>No eggs in inventory</div>
        )}
        <div style={{ marginTop: "10px" }}>Seeds</div>
        {seeds.length > 0 ? (
          <ul>
            {seeds.map(s => (
              <li key={s.type}>
                {s.type} x{s.quantity}
              </li>
            ))}
          </ul>
        ) : (
          <div>No seeds in inventory</div>
        )}
      </div>
    );
  };

  const renderManagementContent = () => {
    return <div>{renderMonsters()}</div>;
  };

  const plantSeeds = (plotId, seedType) => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "farming",
        action: "plant",
        plotId,
        seedType,
      })
    );
  };

  const startHarvesting = plotId => {
    if (!ws) return;
    ws.send(
      JSON.stringify({ type: "farming", action: "startHarvest", plotId })
    );
  };

  const removePlant = plotId => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "farming", action: "remove", plotId }));
  };

  const showPlotOptions = plot => {
    if (!playerData) return;
    if (plot.isGrowing || plot.isHarvesting) {
      Swal.fire({
        title: `Plot #${plot.plotNumber}`,
        text: "Currently busy.",
        showConfirmButton: true,
      });
      return;
    }
    if (!plot.seedType) {
      const userSeeds = playerData.seeds || [];
      if (userSeeds.length === 0) {
        Swal.fire({
          title: "No seeds available.",
          showConfirmButton: true,
        });
        return;
      }
      let html =
        "<div style='display: flex; flex-direction: column; gap: 8px;'>";
      userSeeds.forEach(s => {
        html += `<button class="seedBtn" data-seed="${s.type}">${s.type} (x${s.quantity})</button>`;
      });
      html += "</div>";
      Swal.fire({
        title: `Plot #${plot.plotNumber}`,
        html: html,
        showConfirmButton: false,
        didOpen: () => {
          const btns = document.querySelectorAll(".seedBtn");
          btns.forEach(btn => {
            btn.addEventListener("click", async () => {
              if (hasActiveAction()) {
                const cancelled = await promptCancelActiveAction();
                if (!cancelled) return;
              }
              const seedType = btn.getAttribute("data-seed");
              plantSeeds(plot.plotId, seedType);
              Swal.close();
            });
          });
        },
      });
    } else if (plot.isGrown) {
      Swal.fire({
        title: `Plot #${plot.plotNumber} - ${plot.seedType}`,
        html: `<div style="display: flex; flex-direction: column; gap: 8px;">
          <button id="harvestBtn">Harvest</button>
          <button id="removeBtn">Remove Plant</button>
        </div>`,
        showConfirmButton: false,
        didOpen: () => {
          document
            .getElementById("harvestBtn")
            .addEventListener("click", async () => {
              if (hasActiveAction()) {
                const cancelled = await promptCancelActiveAction();
                if (!cancelled) return;
              }
              startHarvesting(plot.plotId);
              Swal.close();
            });
          document.getElementById("removeBtn").addEventListener("click", () => {
            removePlant(plot.plotId);
            Swal.close();
          });
        },
      });
    } else {
      Swal.fire({
        title: `Plot #${plot.plotNumber} - ${plot.seedType}`,
        text: "Still growing or harvesting.",
        showConfirmButton: true,
      });
    }
  };

  const renderFarmingContent = () => {
    if (!playerData || !playerData.farmland) return null;
    return (
      <div>
        {playerData.farmland.plots.map(plot => (
          <div
            key={plot.plotId}
            style={{ marginBottom: "10px" }}>
            <button onClick={() => showPlotOptions(plot)}>
              Plot #{plot.plotNumber}
              {plot.seedType
                ? ` - ${plot.seedType} ${
                    plot.isGrowing
                      ? "(Growing)"
                      : plot.isHarvesting
                      ? "(Harvesting)"
                      : plot.isGrown
                      ? "(Ready)"
                      : ""
                  }`
                : " - Empty"}
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderContentPanel = () => {
    if (selectedItem === "Hatching") {
      return renderHatchingContent();
    }
    if (selectedItem === "Training") {
      return renderTrainingFeedingContent();
    }
    if (selectedItem === "Feeding") {
      return renderTrainingFeedingContent();
    }
    if (selectedItem === "Inventory") {
      return renderInventoryContent();
    }
    if (selectedItem === "Management") {
      return renderManagementContent();
    }
    if (selectedItem === "Farming") {
      return renderFarmingContent();
    }
    return (
      <div>
        Category: {selectedItem} <br />
        Name: {selectedItem}
      </div>
    );
  };

  useEffect(() => {
    if (
      playerData &&
      (!playerData.eggs || playerData.eggs.length === 0) &&
      (!playerData.monsters || playerData.monsters.length === 0)
    ) {
      Swal.fire({
        title: "Choose an Egg",
        html: `<div style="display: flex; flex-direction: column; gap: 10px;">
            <button id="fireEggBtn">Fire Egg</button>
            <button id="iceEggBtn">Ice Egg</button>
            <button id="natureEggBtn">Nature Egg</button>
          </div>`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          document
            .getElementById("fireEggBtn")
            .addEventListener("click", () => {
              chooseEgg("Fire");
              Swal.close();
            });
          document.getElementById("iceEggBtn").addEventListener("click", () => {
            chooseEgg("Ice");
            Swal.close();
          });
          document
            .getElementById("natureEggBtn")
            .addEventListener("click", () => {
              chooseEgg("Nature");
              Swal.close();
            });
        },
      });
    }
  }, [playerData]);

  return (
    <div className={styles.monsterIdleContainer}>
      <div className={styles.headerPanel}>
        {actionLabel && (
          <div style={{ marginRight: "20px" }}>
            {actionLabel}
            <button
              onClick={cancelActiveAction}
              style={{ marginLeft: "8px" }}>
              Cancel
            </button>
            <div
              style={{
                width: "200px",
                height: "20px",
                border: "1px solid #000",
                marginTop: "5px",
                position: "relative",
              }}>
              <div
                style={{
                  width: `${actionFraction * 100}%`,
                  height: "100%",
                  background:
                    actionType === "egg"
                      ? "green"
                      : actionType === "monster"
                      ? "blue"
                      : "orange",
                  transition: `width ${actionTransition} linear`,
                }}
              />
              {actionTimer > 0 && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: 0,
                    left: 0,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                  }}>
                  {actionTimer}s
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.gamePanel}>
        <div
          className={
            isNavExpanded ? styles.navPanelExpanded : styles.navPanelCollapsed
          }>
          {isMobile && (
            <div
              className={styles.toggleButton}
              onClick={() => setIsNavExpanded(!isNavExpanded)}>
              {isNavExpanded ? "<<" : ">>"}
            </div>
          )}
          {ws &&
            navItems.map(item => (
              <div
                key={item}
                className={
                  selectedItem === item
                    ? `${styles.navItem} ${styles.navItemSelected}`
                    : styles.navItem
                }
                onClick={() => setSelectedItem(item)}>
                {isNavExpanded ? item : item[0]}
              </div>
            ))}
        </div>
        <div className={styles.contentPanel}>{renderContentPanel()}</div>
        <div className={styles.playerInventory}>
          Player Inventory
          {playerData && playerData.eggs && playerData.eggs.length > 0 ? (
            <ul>
              {playerData.eggs.map(egg => {
                const totalSeconds =
                  (egg.requiredProgress * playerData.hatchingSpeed) / 1000;
                return (
                  <li key={egg.eggId}>
                    {egg.type} Egg ({totalSeconds}s)
                    {egg.isHatching ? " [Hatching]" : ""}
                  </li>
                );
              })}
            </ul>
          ) : (
            <div>No eggs in inventory</div>
          )}
          {playerData && playerData.seeds && playerData.seeds.length > 0 && (
            <>
              <div style={{ marginTop: "10px" }}>Seeds</div>
              <ul>
                {playerData.seeds.map(s => (
                  <li key={s.type}>
                    {s.type} x{s.quantity}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MonsterIdle;
