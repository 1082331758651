import React, { useEffect } from "react";
import ICON_STAGE from "./assets/ICON_STAGE.svg";
import ICON_RESOURCES from "./assets/ICON_RESOURCES.svg";

export default function Campaign({
  ws,
  returnToMenu,
  campaignStages,
  refreshCampaignStages,
  hasCampaignGameInProgress,
}) {
  useEffect(() => {
    refreshCampaignStages();
  }, [refreshCampaignStages]);

  function startStage(stageId) {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "CAMPAIGN",
          action: "START",
          stageId,
        })
      );
    }
  }

  function rejoinCampaignGame() {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "GAME", action: "REJOIN" }));
    }
  }

  const stageGroups = Object.entries(
    campaignStages.reduce((groups, stage) => {
      if (!groups[stage.category]) {
        groups[stage.category] = [];
      }
      groups[stage.category].push(stage);
      return groups;
    }, {})
  );

  return (
    <div>
      <h2>Campaign</h2>
      <button
        onClick={returnToMenu}
        style={{ marginTop: "16px" }}>
        Return to Main Menu
      </button>
      {hasCampaignGameInProgress && (
        <button onClick={rejoinCampaignGame}>
          Rejoin Current Campaign Game
        </button>
      )}
      {campaignStages.length === 0 && <div>Loading stages...</div>}
      {stageGroups.map(([category, stages]) => (
        <div
          key={category}
          style={{ marginBottom: "4px" }}>
          <h4>{category}</h4>
          <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
            {stages.map(stage => (
              <div
                key={stage.id}
                style={{
                  border: "4px solid #fff",
                  padding: "8px",
                  maxWidth: "300px",
                  flex: "0 0 auto",
                }}>
                <h5 style={{ display: "flex", alignItems: "center" }}>
                  {stage.name}
                </h5>
                <div>{stage.description}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={ICON_RESOURCES}
                    alt="Resources"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span style={{ marginLeft: "8px" }}>{stage.reward}</span>
                  {stage.completed && (
                    <span
                      style={{
                        color: "green",
                        marginLeft: "8px",
                        fontSize: "1.2em",
                      }}>
                      ✔
                    </span>
                  )}
                </div>
                <img
                  src={ICON_STAGE}
                  alt="Stage"
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                    marginTop: "8px",
                    ...(stage.locked
                      ? {
                          filter: "grayscale(100%)",
                          opacity: 0.3,
                        }
                      : {}),
                  }}
                  onClick={() => startStage(stage.id)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
