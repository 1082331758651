export default function getRoomDescription(roomName) {
  switch (roomName) {
    case "Inventory":
      return "All Your Stuff";
    case "Hangout":
      return "+HAPPINESS";
    case "Sleeping":
      return "+RECOVERY";
    case "Training":
      return "+RANDOM STAT";
    case "Quest":
      return "Do Things For Stuff";
    case "Weightlifting":
      return "+STR";
    case "Running Track":
      return "+DEX";
    case "Library":
      return "+WIS";
    case "Crusher":
      return "+ESSENCE";
    case "Breeding":
      return "Two Creatures Can Make an Egg";
    case "Incubator":
      return "Eggs Hatch Here";
    case "Arena":
      return "Fight to the Death, and for Glory";
    case "Market":
      return "Buy Stuff, Sell Stuff";
    case "Task Board":
      return "Various Tasks for Rewards";
    default:
      return "";
  }
}
