import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import Swal from "sweetalert2";
import style from "./parchmentGame.module.scss"; // TODO: Move stylings over to separate sheet

import { Filter } from "bad-words";
import { localProfaneWords } from "./profanityList";
import DeckBuilder from "./DeckBuilder";
import PatchNotes from "./PatchNotes";
import RulerGoldFont from "./assets/RulerGold.ttf";
import ParchmentLogo from "./assets/parchment_logo.png";

import ATTACK_SYMBOL from "./assets/attack_symbol.svg";
import HEALTH_SYMBOL from "./assets/health_symbol.svg";
import COOLDOWN_SYMBOL from "./assets/cooldown_symbol.svg";
import MechanicalFactionSymbol from "./assets/mechanical_faction_symbol.png";
import NatureFactionSymbol from "./assets/nature_faction_symbol.png";
import VanguardFactionSymbol from "./assets/vanguard_faction_symbol.png";
import { ReactComponent as DrawTwoSymbol } from "./assets/draw_two_symbol.svg";
import { ReactComponent as GainResourceSymbol } from "./assets/gain_resource_symbol.svg";
import { ReactComponent as EndTurnSymbol } from "./assets/end_turn_symbol.svg";
import { ReactComponent as DeckSymbol } from "./assets/deck_symbol.svg";
import { ReactComponent as ExamineSymbol } from "./assets/examine_symbol.svg";
import { ReactComponent as PlaySymbol } from "./assets/play_symbol.svg";
import { ReactComponent as ForfeitSymbol } from "./assets/forfeit_symbol.svg";
import { ReactComponent as ExamineDiscardSymbol } from "./assets/examine_discard_symbol.svg";
import { ReactComponent as ExamineLogSymbol } from "./assets/examine_log_symbol.svg";
import { ReactComponent as ConnectionStatusSymbol } from "./assets/connection_status_symbol.svg";

import CRYSTAL_01 from "./assets/crystal_01.png";
import CRYSTAL_02 from "./assets/crystal_02.png";
import CRYSTAL_03 from "./assets/crystal_03.png";
import CRYSTAL_04 from "./assets/crystal_04.png";
import CRYSTAL_05 from "./assets/crystal_05.png";

import PlainsArenaBackground from "./assets/plains_arena_background.png";
import VolcanicArenaBackground from "./assets/volcanic_arena_background.png";
import SnowFieldArenaBackground from "./assets/snow_field_arena_background.png";

import DamageAnimation from "./assets/damage_animation.png";

const FactionSymbol = ({ type }) => {
  const imageStyles = {
    width: "15px",
    height: "15px",
  };

  switch (type) {
    case "mechanical":
      return (
        <img
          src={MechanicalFactionSymbol}
          style={imageStyles}
          alt="Mechanical"
        />
      );
    case "nature":
      return (
        <img
          src={NatureFactionSymbol}
          style={imageStyles}
          alt="Nature"
        />
      );
    case "vanguard":
      return (
        <img
          src={VanguardFactionSymbol}
          style={imageStyles}
          alt="Vanguard"
        />
      );
    default:
      return null;
  }
};

const CRYSTAL_SIZE = 60;
const CRYSTAL_SPACING_Y = 5;
const OPPONENT_CRYSTAL_OFFSET_X = 65;

// const FRAME_SIZE = 32;
// const COLS = 4;
// const ROWS = 4;
// const FRAME_COUNT = COLS * ROWS;

// const generateKeyframes = () => {
//   return Array.from({ length: FRAME_COUNT }, (_, i) => {
//     const col = i % COLS;
//     const row = Math.floor(i / COLS);
//     const percentage = (i / FRAME_COUNT) * 100;
//     return `
//       ${percentage}% {
//         background-position: ${-col * FRAME_SIZE}px ${-row * FRAME_SIZE}px;
//       }
//     `;
//   }).join("");
// };

function ParchmentGame() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);

  const [patchNotesVersion, setPatchNotesVersion] = useState("Alpha 1.7.8");

  const [cardDatabase, setCardDatabase] = useState(null);

  const [players, setPlayers] = useState([]);
  const [inQueue, setInQueue] = useState(false);
  const [isInGame, setIsInGame] = useState(false);

  const [roomId, setRoomId] = useState(null);
  const [pendingRejoinRoomId, setPendingRejoinRoomId] = useState(null);
  const [opponent, setOpponent] = useState(null);
  const [isInRoom, setIsInRoom] = useState(false);
  const [opponentInRoom, setOpponentInRoom] = useState(false);

  const [myBoard, setMyBoard] = useState(null);
  const [opponentBoard, setOpponentBoard] = useState(null);

  const [myResources, setMyResources] = useState({
    nature: { current: 0, max: 0 },
    mechanical: { current: 0, max: 0 },
    vanguard: { current: 0, max: 0 },
  });

  const [opponentResources, setOpponentResources] = useState({
    nature: { current: 0, max: 0 },
    mechanical: { current: 0, max: 0 },
    vanguard: { current: 0, max: 0 },
  });

  const [mySacrificeUsed, setMySacrificeUsed] = useState(false);
  const [amPlayer1, setAmPlayer1] = useState(null);
  const [currentRound, setCurrentRound] = useState(1);
  const [currentTurnPlayer, setCurrentTurnPlayer] = useState(null);
  const [highlightedAttackers, setHighlightedAttackers] = useState([]);
  const [highlightedVictims, setHighlightedVictims] = useState([]);
  const [damageMap, setDamageMap] = useState(new Map());
  const [validMoves, setValidMoves] = useState([]);
  const [attackTelegraph, setAttackTelegraph] = useState([]);
  const [showDamage, setShowDamage] = useState(false);
  const [background, setBackground] = useState("");

  const [myHand, setMyHand] = useState([]);
  const [myDeckCount, setMyDeckCount] = useState(0);
  const [myDiscard, setMyDiscard] = useState([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [cardPlayingLog, setCardPlayingLog] = useState([]);
  const [showCardPlayingLogModal, setShowCardPlayingLogModal] = useState(false);
  const [showNetworkStatusModal, setShowNetworkStatusModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [isExamining, setIsExamining] = useState(false);
  const [examinedCard, setExaminedCard] = useState(null);
  const [isExaminingUnit, setIsExaminingUnit] = useState(false);
  const [examinedUnit, setExaminedUnit] = useState(null);

  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isFilterOn, setIsFilterOn] = useState(true);
  const [profanityFilter] = useState(() => {
    const filter = new Filter({
      emptyList: true,
      placeHolder: "*",
      regex: /[^a-zA-Z0-9_|$|@]|/gi,
      replaceRegex: /[A-Za-z0-9_]/g,
    });

    filter.addWords(...localProfaneWords);
    return filter;
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPatchNotes, setShowPatchNotes] = useState(false);
  const [bugDescription, setBugDescription] = useState("");
  const [beforeBug, setBeforeBug] = useState("");
  const [triggerBug, setTriggerBug] = useState("");

  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState(null);

  const [scale, setScale] = useState(1.75);

  useEffect(() => {
    const updateScale = () => {
      if (window.innerWidth < 768) {
        setScale(0.5);
      } else if (window.innerWidth < 1024) {
        setScale(0.6);
      } else if (window.innerWidth < 1280) {
        setScale(0.75);
      } else if (window.innerWidth < 1536) {
        setScale(1.1);
      } else if (window.innerWidth < 1920) {
        setScale(1.25);
      } else if (window.innerWidth < 2560) {
        setScale(1.5);
      } else {
        setScale(1.75);
      }
    };

    updateScale();
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  const fontInjection = (
    <>
      <style>
        {`
          @font-face {
            font-family: 'RulerGold-ParchmentGame';
            src: url(${RulerGoldFont}) format('truetype');
          }
          /* Force only the font-family with !important */
          .parchment-root, .parchment-root * {
            font-family: 'RulerGold-ParchmentGame', sans-serif !important;
          }
  
          /* Set a larger base font size here (no !important), so other rules can override it */
          .parchment-root {
            font-size: 20px;
          }
        `}
      </style>
    </>
  );

  useEffect(() => {
    let newWs;
    let environment;

    if (window.location.href.includes("localhost")) {
      environment = "localhost";
    } else if (window.location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (window.location.href.includes("2nguyen.dev")) {
      environment = "production";
    }

    switch (environment) {
      case "localhost":
        newWs = new WebSocket("ws://localhost:5000/parchment-game");
        break;
      case "localNetwork":
        newWs = new WebSocket("ws://192.168.0.7:5000/parchment-game");
        break;
      case "production":
        newWs = new WebSocket("wss://2nguyen.dev/parchment-game");
        break;
      default:
        console.log("Environment not recognized. WebSocket not established.");
        break;
    }

    if (!newWs) return;

    newWs.onopen = () => {
      newWs.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );

      newWs.send(
        JSON.stringify({
          type: "card",
          action: "getDatabase",
        })
      );

      newWs.send(
        JSON.stringify({
          type: "chat",
          action: "getHistory",
        })
      );

      setWs(newWs);
    };

    newWs.onclose = () => {
      setWs(null);
      setIsInGame(false);
      setRoomId(null);
      setOpponent(null);

      setMyBoard(null);
      setOpponentBoard(null);
      setMyResources(0);
      setOpponentResources(0);
      setCurrentTurnPlayer(null);

      window.location.reload();
    };

    newWs.onmessage = event => {
      const data = JSON.parse(event.data);

      switch (`${data.type}:${data.action}`) {
        case "account:playersList":
          setPlayers(data.players || []);
          break;

        case "account:boot":
          alert(data.message || "Logged out from this session");
          window.location.href = "/";
          break;

        case "card:database":
          setCardDatabase(data.cardDatabase);
          break;

        case "game:matchFound":
        case "game:soloMatchFound":
          setIsInGame(true);
          setRoomId(data.roomId);
          setOpponent(data.opponent);
          if (typeof data.opponentInRoom === "boolean") {
            setOpponentInRoom(data.opponentInRoom);
          } else {
            setOpponentInRoom(true);
          }

          setMyBoard(data.myBoard);
          setOpponentBoard(data.opponentBoard);
          setMyResources(data.myResources);
          setOpponentResources(data.opponentResources);
          setCurrentRound(data.round);
          setCurrentTurnPlayer(data.currentTurnPlayer);

          setAmPlayer1(data.amPlayer1);

          setMyHand(data.myHand || []);
          setMyDiscard(data.myDiscard || []);
          setMyDeckCount(data.myDeckCount || 0);
          setMySacrificeUsed(data.sacrificeUsedPlayer);

          setHighlightedAttackers([]);
          setHighlightedVictims([]);
          setDamageMap(new Map());
          setAttackTelegraph([]);
          break;

        case "game:updateState": {
          setMyBoard(data.myBoard);
          setOpponentBoard(data.opponentBoard);
          setMyResources(data.myResources);
          setOpponentResources(data.opponentResources);
          setCurrentRound(data.round);
          setCurrentTurnPlayer(data.currentTurnPlayer);

          if (data.recentAttacks && data.recentAttacks.length > 0) {
            const newAttackers = [];
            const newVictims = [];
            const newDamageMap = new Map(damageMap);
            setAttackTelegraph([]);

            data.recentAttacks.forEach(attack => {
              newAttackers.push(attack.attacker);
              newVictims.push(attack.victim);

              const victimKey = `${attack.victim.board}-${attack.victim.col}-${attack.victim.row}`;
              const existingDamage = newDamageMap.get(victimKey) || 0;
              newDamageMap.set(
                victimKey,
                existingDamage + (attack.damage || 0)
              );
            });

            setHighlightedAttackers(newAttackers);
            setHighlightedVictims(newVictims);
            setDamageMap(newDamageMap);
          }

          setMyHand(data.myHand || []);
          setMyDiscard(data.myDiscard || []);
          setMyDeckCount(data.myDeckCount || 0);
          setMySacrificeUsed(data.sacrificeUsedPlayer);

          break;
        }

        case "game:resetIndicators": {
          setHighlightedAttackers([]);
          setHighlightedVictims([]);
          setDamageMap(new Map());
          setAttackTelegraph([]);
          setValidMoves([]);
          setSelectedCard(null);
          setSelectedUnit(null);
          setSelectedTargets([]);

          break;
        }

        case "game:viewDiscardResponse":
          setMyDiscard(data.discard);
          setShowDiscardModal(true);
          break;

        case "game:viewCardPlayingLogResponse":
          setCardPlayingLog(data.cardPlayingLog);
          setShowCardPlayingLogModal(true);
          break;

        case "game:gameOver":
          setGameOver(true);
          setWinner(data.winner);
          setIsInRoom(false);
          setOpponentInRoom(false);
          break;

        case "game:opponentLeft":
          setGameOver(true);
          setWinner(user.username);

          Swal.fire({
            title: "Opponent Forfeited!",
            text: "Your opponent left the game. You win by default!",
            icon: "success",
            confirmButtonText: "Claim Victory",
            confirmButtonColor: "#3085d6",
            backdrop: `
                rgba(0, 0, 0, 0.7)
                left top
                no-repeat
              `,
          });
          break;

        case "game:leftGame":
          break;

        case "game:resumePossible":
          setPendingRejoinRoomId(data.roomId);
          break;

        case "game:roomExpired":
          setPendingRejoinRoomId(null);
          break;

        case "game:updatePlayerRoomStatus":
          setIsInRoom(data.inRoom);
          break;

        case "game:opponentRoomStatus":
          setOpponentInRoom(data.inRoom);
          break;

        case "game:opponentDisconnectedFromRoom":
          setOpponentInRoom(false);
          break;

        case "game:opponentRejoined":
          setOpponentInRoom(true);
          break;

        case "game:updateInRoomStatus":
          setIsInRoom(data.inRoom);

          break;

        case "game:updateOpponentInRoomStatus":
          setOpponentInRoom(data.inRoom);
          break;

        case "chat:history":
          setChatMessages(data.messages);
          break;

        case "chat:message":
          setChatMessages(prev => [...prev, data.message]);
          break;

        default:
          break;
      }
    };

    return () => {
      newWs.close();
    };
  }, [user.username]);

  useEffect(() => {
    const chatMessagesContainer = document.getElementById("chatMessages");
    if (chatMessagesContainer) {
      chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (highlightedVictims.length > 0) {
      setShowDamage(true);
      const duration = 12 * 60;
      const timer = setTimeout(() => {
        setShowDamage(false);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [highlightedVictims]);

  useEffect(() => {
    const backgrounds = [
      PlainsArenaBackground,
      VolcanicArenaBackground,
      SnowFieldArenaBackground,
    ];
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
    setBackground(backgrounds[randomIndex]);
  }, []);

  const getFontSize = name => {
    const length = name?.length || 0;
    if (length > 15 && name.includes(" ")) {
      if (length > 25) return "8px";
      return "10px";
    }
    if (length > 20) return "6px";
    if (length > 15) return "8px";
    return "10px";
  };

  const sendChatMessage = () => {
    if (messageInput.trim() && ws?.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "chat",
          action: "send",
          message: messageInput,
        })
      );

      setMessageInput("");
    }
  };

  function handleReconnect() {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "resume",
        roomId: pendingRejoinRoomId,
      })
    );
  }

  const handleStartSoloGame = () => {
    if (pendingRejoinRoomId) {
      Swal.fire({
        title: "Game in Progress",
        text: "You have a game in progress. Starting a new solo game will forfeit the current game. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, start new solo game",
        cancelButtonText: "No, keep current game",
      }).then(result => {
        if (!result.isConfirmed) {
          return;
        }

        ws.send(
          JSON.stringify({
            type: "game",
            action: "leave",
            roomId: pendingRejoinRoomId,
          })
        );
        setPendingRejoinRoomId(null);

        setGameOver(false);
        setWinner(null);

        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(
            JSON.stringify({
              type: "matchmaking",
              action: "solo",
            })
          );
        }
      });
    } else {
      setGameOver(false);
      setWinner(null);

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            type: "matchmaking",
            action: "solo",
          })
        );
      }
    }
  };

  const handleJoinQueue = () => {
    if (pendingRejoinRoomId) {
      Swal.fire({
        title: "Game in Progress",
        text: "You have a game in progress. Joining a new queue will forfeit the current game. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, join new queue",
        cancelButtonText: "No, keep current game",
      }).then(result => {
        if (!result.isConfirmed) {
          return;
        }

        ws.send(
          JSON.stringify({
            type: "game",
            action: "leave",
            roomId: pendingRejoinRoomId,
          })
        );
        setPendingRejoinRoomId(null);

        setGameOver(false);
        setWinner(null);

        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "matchmaking", action: "join" }));
          setInQueue(true);
        }
      });
    } else {
      setGameOver(false);
      setWinner(null);

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "matchmaking", action: "join" }));
        setInQueue(true);
      }
    }
  };

  const handleLeaveQueue = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "matchmaking", action: "leave" }));
      setInQueue(false);
    }
  };

  const resetGameState = () => {
    setIsInGame(false);
    setIsInRoom(false);
    setPendingRejoinRoomId(null);
    setPlayers([]);
    setOpponentInRoom(false);
    setRoomId(null);
    setOpponent(null);
    setMyBoard(null);
    setOpponentBoard(null);
    setMyResources(0);
    setOpponentResources(0);
    setCurrentTurnPlayer(null);
    setInQueue(false);
    setMyHand([]);
    setMyDiscard([]);
    setMyDeckCount(0);
    setSelectedCard(null);
    setShowDiscardModal(false);
    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setDamageMap(new Map());
    setAttackTelegraph([]);
    setGameOver(false);
    setWinner(null);
  };

  const handleLeaveGame = () => {
    if (roomId) {
      Swal.fire({
        title: "Forfeit Match?",
        text: "Are you sure you want to forfeit the match and leave the game?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave game",
        cancelButtonText: "No, stay in game",
      }).then(result => {
        if (!result.isConfirmed) {
          return;
        }

        ws.send(
          JSON.stringify({
            type: "game",
            action: "leave",
            roomId,
          })
        );

        resetGameState();
      });
    }
  };

  const handleReturnToLobby = () => {
    if (roomId) {
      ws.send(
        JSON.stringify({
          type: "game",
          action: "leave",
          roomId,
        })
      );
    }

    resetGameState();
  };

  const handleSelectCard = (cardType, index) => {
    if (selectedCard?.cardType === cardType && selectedCard?.index === index) {
      setSelectedCard(null);
      setValidMoves([]);
    } else {
      setSelectedCard({ cardType, index });
      const cardDef = cardDatabase[cardType];

      if (cardDef) {
        const canAfford =
          myResources[cardDef.resourceType].current >= cardDef.cost;

        if (
          canAfford &&
          (cardDef.card_type === "unit" || cardDef.card_type === "structure")
        ) {
          const validCells = getAllEmptyCells(myBoard);
          setValidMoves(validCells);
        } else {
          setValidMoves([]);
        }
      } else {
        setValidMoves([]);
      }
    }
  };

  const handleExamineCard = card => {
    setExaminedCard(card);
    setIsExamining(true);
  };

  const handleCloseExaminingModal = () => {
    setIsExamining(false);
    setExaminedCard(null);
  };

  const handleCloseUnitExaminingModal = () => {
    setIsExaminingUnit(false);
    setExaminedUnit(null);
  };

  function getAllEmptyCells(board) {
    const cells = [];
    for (let colIndex = 0; colIndex < board.length; colIndex++) {
      for (let rowIndex = 0; rowIndex < board[colIndex].length; rowIndex++) {
        if (board[colIndex][rowIndex] === null) {
          cells.push({ col: colIndex, row: rowIndex });
        }
      }
    }
    return cells;
  }

  const handlePlaceUnit = (columnIndex, cellIndex, isMyBoard) => {
    if (!selectedCard) return;
    if (!ws || !roomId) return;

    const { cardType } = selectedCard;

    let boardName = "";
    if (amPlayer1) {
      boardName = isMyBoard ? "player1" : "player2";
    } else {
      boardName = isMyBoard ? "player2" : "player1";
    }

    ws.send(
      JSON.stringify({
        type: "game",
        action: "placeUnit",
        roomId,
        pieceType: cardType,
        columnIndex,
        cellIndex,
        targetBoard: boardName,
      })
    );

    setValidMoves([]);
    setSelectedCard(null);
    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setDamageMap(new Map());
    setExaminedUnit(null);
  };

  function handleTargetUnit(columnIndex, rowIndex, cell, isMyBoard) {
    const cardDef = cardDatabase[selectedCard.cardType];
    const { targeting } = cardDef;

    if (targeting === "twoEntities") {
      if (!cell || cell.type === "crystal" || cell.type === "DESTROYED") {
        Swal.fire({
          title: "Invalid Target!",
          text: "This card requires targeting a valid entity (unit or structure).",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "Retry",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconColor: "#856404",
        });
        setSelectedTargets([]);
        return;
      }
      if (selectedTargets.length < 1) {
        setSelectedTargets([{ col: columnIndex, row: rowIndex, isMyBoard }]);
        Swal.fire({
          title: "First Target Set!",
          html: `<div style="font-size: 1.1rem">Now select the <strong>second target</strong></div>`,
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Proceed to Select Second Target",
          allowOutsideClick: false,
        });
        return;
      }
      if (selectedTargets.length === 1) {
        const first = selectedTargets[0];
        if (
          first.col === columnIndex &&
          first.row === rowIndex &&
          first.isMyBoard === isMyBoard
        ) {
          Swal.fire({
            title: "Duplicate Selection!",
            html: `<div style="color: #dc3545; font-weight: 500">You already selected this<br/>as your first target!</div>`,
            icon: "warning",
            confirmButtonText: "Reselect First Target",
            confirmButtonColor: "#dc3545",
            showConfirmButton: true,
          });
          setSelectedTargets([]);
          return;
        }
        const secondTarget = { col: columnIndex, row: rowIndex, isMyBoard };
        const allTargets = [first, secondTarget];
        handlePlaceUnitMultiTarget(allTargets);
        setSelectedTargets([]);
        return;
      }
    }

    if (targeting === "entity") {
      if (
        !cell ||
        (cell.card_type !== "unit" && cell.card_type !== "structure") ||
        cell.type === "crystal" ||
        cell.type === "DESTROYED"
      ) {
        Swal.fire({
          title: "Invalid Entity!",
          text: "This event requires targeting a valid entity (unit or structure).",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
          iconColor: "#856404",
        });
        setSelectedCard(null);
        setSelectedTargets([]);
        return;
      }
      handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      return;
    }

    if (targeting === "unit") {
      if (
        !cell ||
        cell.card_type !== "unit" ||
        cell.type === "crystal" ||
        cell.type === "DESTROYED"
      ) {
        Swal.fire({
          title: "Invalid Unit!",
          text: "This event requires targeting a valid unit.",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
          iconColor: "#856404",
        });
        setSelectedCard(null);
        setSelectedTargets([]);
        return;
      }
      handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      return;
    }

    if (targeting === "structure") {
      if (
        !cell ||
        cell.card_type !== "structure" ||
        cell.type === "crystal" ||
        cell.type === "DESTROYED"
      ) {
        Swal.fire({
          title: "Invalid Structure!",
          text: "This event requires targeting a valid structure.",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
          iconColor: "#856404",
        });
        setSelectedCard(null);
        setSelectedTargets([]);
        return;
      }
      handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      return;
    }

    if (targeting === "none") {
      handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      return;
    }

    Swal.fire({
      title: "Invalid Targeting Type!",
      text: "This card cannot be played here.",
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
      iconColor: "#856404",
    });
    setSelectedCard(null);
    setSelectedTargets([]);
  }

  const handleSpecialAction = (actionName, col, row, isMine) => {
    if (!isMine) {
      Swal.fire({
        icon: "warning",
        title: "Cannot Use Opponent's Card",
        text: "You cannot use your opponent's card!",
      });
      return;
    }

    const message = {
      type: "game",
      action: "performSpecialAction",
      roomId,
      col,
      row,
      actionName,
    };
    ws.send(JSON.stringify(message));
  };

  const handleEndTurn = () => {
    if (!ws || !roomId) return;

    ws.send(
      JSON.stringify({
        type: "game",
        action: "skipTurn",
        roomId,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setDamageMap(new Map());
    setValidMoves([]);
    setSelectedCard(null);
    setSelectedUnit(null);
    setSelectedTargets([]);
  };

  const isMyTurn = currentTurnPlayer === user.username;

  let selectedCardStats = null;
  if (selectedCard?.cardType) {
    selectedCardStats = cardDatabase[selectedCard.cardType] || null;
  }

  if (!isInGame) {
    return (
      <div className="parchment-root">
        {fontInjection}
        <div className={style.lobbyContainer}>
          {showEmailModal && (
            <div className={style.modalStyle}>
              <h3>Bug Report</h3>
              <div style={{ marginBottom: "1rem" }}>
                <div>Subject: BUG REPORT: Parchment Game</div>

                <label style={{ display: "block", margin: "10px 0" }}>
                  Description of the bug:
                  <textarea
                    value={bugDescription}
                    onChange={e => setBugDescription(e.target.value)}
                    style={{ width: "100%", marginTop: "5px" }}
                  />
                </label>

                <label style={{ display: "block", margin: "10px 0" }}>
                  What I was doing before:
                  <textarea
                    value={beforeBug}
                    onChange={e => setBeforeBug(e.target.value)}
                    style={{ width: "100%", marginTop: "5px" }}
                  />
                </label>

                <label style={{ display: "block", margin: "10px 0" }}>
                  How I triggered it:
                  <textarea
                    value={triggerBug}
                    onChange={e => setTriggerBug(e.target.value)}
                    style={{ width: "100%", marginTop: "5px" }}
                  />
                </label>
              </div>

              <button
                onClick={() => {
                  const email = "nguy1581@gmail.com";
                  const subject = encodeURIComponent(
                    "BUG REPORT: Parchment Game"
                  );
                  const body = encodeURIComponent(
                    `Hello,\n\nHere is a description of the bug:\n- ${bugDescription}\n\n` +
                      `What I was doing before the bug happened:\n- ${beforeBug}\n\n` +
                      `What I did to trigger the bug (if possible):\n- ${triggerBug}\n\nThank you!`
                  );
                  window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
                  setShowEmailModal(false);
                  setBugDescription("");
                  setBeforeBug("");
                  setTriggerBug("");
                }}>
                Send Email
              </button>
              <button
                onClick={() => {
                  setShowEmailModal(false);
                  setBugDescription("");
                  setBeforeBug("");
                  setTriggerBug("");
                }}
                style={{ marginLeft: "10px" }}>
                Cancel
              </button>
            </div>
          )}
          {showPatchNotes && (
            <div className={style.modalStyle}>
              <PatchNotes />
              <button
                onClick={() => setShowPatchNotes(false)}
                style={{ marginTop: "1rem" }}>
                Close
              </button>
            </div>
          )}
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              zIndex: 1,
            }}>
            <button
              onClick={() => setShowEmailModal(true)}
              style={{
                padding: "5px 10px",
                fontSize: "0.8rem",
                background: "#444",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}>
              Send a Bug Report
            </button>
            <button
              onClick={() => setShowPatchNotes(true)}
              style={{
                padding: "5px 10px",
                fontSize: "0.8rem",
                background: "#444",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}>
              Show Patch Notes ({patchNotesVersion})
            </button>
          </div>
          <h1>
            <img
              src={ParchmentLogo}
              alt="Parchment, the Game"
              className={style.logoImage}
            />
          </h1>

          {gameOver && (
            <div>
              <h2>Your opponent forfeited the previous game.</h2>
            </div>
          )}

          <div className={style.lobbyContent}>
            <div className={style.chatContainer}>
              <div
                className={style.chatMessages}
                id="chatMessages">
                {chatMessages.map((msg, idx) => (
                  <div
                    key={idx}
                    className={style.chatMessage}>
                    <span className={style.chatMeta}>
                      [<span className={style.chatTime}>{msg.time}</span>]{" "}
                      <span className={style.chatUser}>{msg.user}</span>:{" "}
                    </span>
                    {isFilterOn ? profanityFilter.clean(msg.text) : msg.text}
                  </div>
                ))}
              </div>
              <div className={style.chatInputContainer}>
                <input
                  type="text"
                  value={messageInput}
                  onChange={e => setMessageInput(e.target.value)}
                  onKeyPress={e => e.key === "Enter" && sendChatMessage()}
                  className={style.chatInput}
                  placeholder="Type a message..."
                />
                <button
                  onClick={sendChatMessage}
                  className={style.sendButton}>
                  Send
                </button>
                <button
                  style={{
                    backgroundColor: isFilterOn ? "#EFDECD" : "orange",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsFilterOn(!isFilterOn)}>
                  {isFilterOn ? "Disable" : "Enable"} Profanity Filter
                </button>
              </div>
            </div>

            <div className={style.playersContainer}>
              <span
                style={{
                  fontSize: "1.5rem",
                  color: "orange",
                }}>
                {" "}
                Online Players{" "}
              </span>{" "}
              ({players.length})
              <div className={style.playerList}>
                {players.map((player, idx) => (
                  <div
                    key={idx}
                    className={style.playerItem}>
                    {player.username}
                    {player.inGame && (
                      <span style={{ color: "lime", marginLeft: "8px" }}>
                        (In Game)
                      </span>
                    )}
                    {player.inQueue && (
                      <span style={{ color: "gold", marginLeft: "8px" }}>
                        (In Queue)
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
          {pendingRejoinRoomId && (
            <button
              style={{
                display: "block",
                margin: "0 auto",
                fontSize: "2rem",
                backgroundColor: "yellow",
              }}
              onClick={handleReconnect}>
              Reconnect To Last Game
            </button>
          )}

          <button
            style={{ display: "block", margin: "0 auto", fontSize: "2rem" }}
            onClick={handleStartSoloGame}>
            Solitaire Game
          </button>

          {!inQueue ? (
            <button
              style={{ display: "block", margin: "0 auto", fontSize: "2rem" }}
              onClick={handleJoinQueue}>
              Queue for a Game Against Someone in Lobby
            </button>
          ) : (
            <button
              style={{
                display: "block",
                margin: "0 auto",
                fontSize: "2rem",
                backgroundColor: "orange",
              }}
              onClick={handleLeaveQueue}>
              Queued for a Game... Click Again To Stop Queueing
            </button>
          )}

          <DeckBuilder
            ws={ws}
            cardDatabase={cardDatabase}
          />

          <h5>
            <a
              href="https://linktr.ee/diahborne"
              target="_blank"
              rel="noopener noreferrer">
              Some Art Assets Provided by Diahborne, click here for his
              LinkTree.
            </a>
          </h5>
        </div>
      </div>
    );
  }

  const handleCardAction = action => {
    if (!selectedCard || !ws || !roomId) return;

    switch (action) {
      case "play":
        break;

      case "sacrificeForResources":
        ws.send(
          JSON.stringify({
            type: "game",
            action: "sacrificeCardForResources",
            roomId,
            cardType: selectedCard.cardType,
            index: selectedCard.index,
          })
        );
        setSelectedCard(null);
        setValidMoves([]);
        break;

      case "sacrificeForCards":
        ws.send(
          JSON.stringify({
            type: "game",
            action: "sacrificeCardForCards",
            roomId,
            cardType: selectedCard.cardType,
            index: selectedCard.index,
          })
        );
        setSelectedCard(null);
        setValidMoves([]);
        break;

      default:
        break;
    }
  };

  const rootContainerStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%) scale(${scale})`,
    transformOrigin: "center center",
    width: "100%",
  };

  return (
    <>
      <div style={rootContainerStyle}>
        <div className="parchment-root">
          {/* <style>{`
      @keyframes damage-grid-animation {
        ${generateKeyframes()}
        100% { opacity: 0; }
      }
    `}</style> */}
          <br />
          <br />

          {fontInjection}
          <div className={style.container}>
            {showDiscardModal && (
              <div className={style.modalWrapper}>
                <div className={style.modalContentStyle}>
                  <h3>Discard Pile</h3>
                  {myDiscard && myDiscard.length === 0 ? (
                    <div>No discarded cards yet.</div>
                  ) : (
                    myDiscard &&
                    myDiscard.map((cardType, idx) => (
                      <div key={idx}>{cardType}</div>
                    ))
                  )}
                </div>
                <button
                  className={style.closeButton}
                  onClick={() => setShowDiscardModal(false)}
                  aria-label="Close">
                  ×
                </button>
              </div>
            )}

            {showCardPlayingLogModal && (
              <div className={style.modalWrapper}>
                <div className={style.modalContentStyle}>
                  <h3>Game Log</h3>
                  {cardPlayingLog && cardPlayingLog.length === 0 ? (
                    <div>No actions taken yet.</div>
                  ) : (
                    cardPlayingLog.map((entry, idx) => (
                      <div key={idx}>
                        <strong>{entry.actionType}</strong> - {entry.message}{" "}
                        <br />
                      </div>
                    ))
                  )}
                </div>
                <button
                  className={style.closeButton}
                  onClick={() => setShowCardPlayingLogModal(false)}
                  aria-label="Close">
                  ×
                </button>
              </div>
            )}

            {showNetworkStatusModal && (
              <div className={style.modalWrapper}>
                <div className={style.modalContentStyle}>
                  <h3>Network Status</h3>
                  {!isInRoom && (
                    <div style={{ color: "gray" }}>
                      You are currently not in a room.
                    </div>
                  )}
                  {isInRoom && !opponentInRoom && (
                    <div style={{ color: "red" }}>
                      Your opponent is disconnected from the room.
                    </div>
                  )}
                  {isInRoom && opponentInRoom && (
                    <div style={{ color: "green" }}>
                      Both you and your opponent are in the room!
                    </div>
                  )}
                </div>
                <button
                  className={style.closeButton}
                  onClick={() => setShowNetworkStatusModal(false)}
                  aria-label="Close">
                  ×
                </button>
              </div>
            )}

            {isExamining &&
              examinedCard &&
              (() => {
                const cardDef = cardDatabase[examinedCard.cardType];
                return (
                  <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                      <img
                        src={cardDef.image}
                        alt={cardDef.cardType}
                        style={styles.cardImage}
                      />
                      <h4 style={styles.cardTitle}>{cardDef.name}</h4>
                      <span style={styles.topLeftInfo}>
                        <span style={styles.resourceType}>
                          <FactionSymbol
                            size={30}
                            type={cardDef.resourceType}
                          />{" "}
                          <span style={styles.costCardLabel}>
                            {cardDef.cost}
                          </span>
                        </span>
                      </span>
                      <div style={styles.middleText}>
                        {cardDef.effectDescription}
                        {cardDef.effectDescription && (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                        <i>{cardDef.flavorText}</i>
                        {cardDef.specialActions &&
                          cardDef.specialActions.length > 0 && (
                            <div>
                              {cardDef.specialActions.map((actionObj, idx) => (
                                <button
                                  key={idx}
                                  disabled={true}
                                  style={{
                                    opacity: 0.6,
                                    cursor: "not-allowed",
                                    fontSize: "0.75rem",
                                  }}>
                                  {actionObj.name || "Special Action"}
                                </button>
                              ))}
                            </div>
                          )}
                      </div>

                      <div style={styles.bottomStats}>
                        <div style={styles.attackStat}>
                          <img
                            src={ATTACK_SYMBOL}
                            alt="Attack"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "4px",
                            }}
                          />
                          {cardDef.maximumAttack}
                        </div>
                        <div style={styles.cooldownStat}>
                          <img
                            src={COOLDOWN_SYMBOL}
                            alt="Cooldown"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "4px",
                            }}
                          />
                          {cardDef.maximumCooldown}
                        </div>
                        <div style={styles.healthStat}>
                          <img
                            src={HEALTH_SYMBOL}
                            alt="Health"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "4px",
                            }}
                          />
                          {cardDef.maximumHealth}
                        </div>
                      </div>
                    </div>
                    <div style={styles.modalButtons}>
                      <button
                        onClick={handleCloseExaminingModal}
                        style={styles.closeModalButton}
                        aria-label="Close card details">
                        Close
                      </button>
                    </div>
                  </div>
                );
              })()}

            <div className={style.topMenuButtons}>
              <div style={styles.forfeitButton}>
                <ActionButton
                  onClick={handleLeaveGame}
                  ariaLabel="Forfeit Game and Return to Lobby"
                  IconComponent={ForfeitSymbol}
                />
              </div>
              <div style={styles.discardPileButton}>
                <ActionButton
                  onClick={handleViewDiscardPile}
                  ariaLabel="View Discard Pile"
                  IconComponent={ExamineDiscardSymbol}
                />
              </div>
              <div style={styles.cardPlayingLogButton}>
                <ActionButton
                  onClick={handleViewCardPlayingLog}
                  ariaLabel="View Card Playing Log"
                  IconComponent={ExamineLogSymbol}
                />
              </div>
              <div style={styles.connectionStatusButton}>
                <ActionButton
                  onClick={() => setShowNetworkStatusModal(true)}
                  ariaLabel="View Network Status"
                  IconComponent={ConnectionStatusSymbol}
                />
              </div>
            </div>

            <div className={style.container}>
              <div className={style.gameHeader}>
                <div className={style.deckInfoContainer}>
                  <DeckSymbol style={styles.deckIcon} />
                  <span>{myDeckCount}</span>
                </div>
                <div className={style.nameContainer}>
                  <div
                    style={{
                      ...styles.playerNameBox,
                      backgroundColor: isMyTurn
                        ? "rgba(255, 166, 0, 0.9)"
                        : styles.playerNameBox.backgroundColor,
                    }}>
                    <h3 className={style.playerName}>{user.username}</h3>
                  </div>
                  <div className={style.versusContainer}>
                    <span className={style.versus}>VS</span>
                  </div>
                  <div style={styles.opponentNameBox}>
                    <h3 className={style.opponentName}>{opponent}</h3>
                  </div>
                </div>
                <div className={style.roundInfoContainer}>
                  <div className={style.roundText}>Round: {currentRound}</div>
                </div>
              </div>

              <div className={style.gameContainer}>
                <img
                  src={background}
                  alt="Arena Background"
                  style={{
                    position: "absolute",
                    top: "-70%",
                    left: "-65%",
                    width: "250%",
                    height: "250%",
                    zIndex: -1,
                  }}
                />
                <div style={styles.boardWrapper}>
                  {myBoard && renderBoard(myBoard, true)}
                </div>
                <div style={styles.boardWrapper}>
                  {opponentBoard && renderBoard(opponentBoard, false)}
                </div>
              </div>
            </div>

            <div className={style.endTurnContainer}>
              <div className={style.resourcesRow}>
                <div className={style.leftGroup}>
                  <div className={style.endTurnButton}>
                    <ActionButton
                      onClick={handleEndTurn}
                      disabled={!isMyTurn}
                      ariaLabel="End Turn"
                      IconComponent={EndTurnSymbol}
                    />
                  </div>

                  <div className={style.playerResources}>
                    {(myResources.nature.max > 0 ||
                      myResources.nature.current > 0) && (
                      <div className={style.resourceIcon}>
                        <FactionSymbol type="nature" />
                        <span>
                          {myResources.nature.current}/{myResources.nature.max}
                        </span>
                      </div>
                    )}
                    {(myResources.mechanical.max > 0 ||
                      myResources.mechanical.current > 0) && (
                      <div className={style.resourceIcon}>
                        <FactionSymbol type="mechanical" />
                        <span>
                          {myResources.mechanical.current}/
                          {myResources.mechanical.max}
                        </span>
                      </div>
                    )}
                    {(myResources.vanguard.max > 0 ||
                      myResources.vanguard.current > 0) && (
                      <div className={style.resourceIcon}>
                        <FactionSymbol type="vanguard" />
                        <span>
                          {myResources.vanguard.current}/
                          {myResources.vanguard.max}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.opponentResources}>
                  {(opponentResources.nature.max > 0 ||
                    opponentResources.nature.current > 0) && (
                    <div className={style.resourceIcon}>
                      <FactionSymbol type="nature" />
                      <span>
                        {opponentResources.nature.current}/
                        {opponentResources.nature.max}
                      </span>
                    </div>
                  )}
                  {(opponentResources.mechanical.max > 0 ||
                    opponentResources.mechanical.current > 0) && (
                    <div className={style.resourceIcon}>
                      <FactionSymbol type="mechanical" />
                      <span>
                        {opponentResources.mechanical.current}/
                        {opponentResources.mechanical.max}
                      </span>
                    </div>
                  )}
                  {(opponentResources.vanguard.max > 0 ||
                    opponentResources.vanguard.current > 0) && (
                    <div className={style.resourceIcon}>
                      <FactionSymbol type="vanguard" />
                      <span>
                        {opponentResources.vanguard.current}/
                        {opponentResources.vanguard.max}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {!gameOver &&
                selectedCard &&
                (() => {
                  const fullCard = cardDatabase[selectedCard.cardType];

                  const hasEnoughResources =
                    fullCard &&
                    fullCard.resourceType &&
                    myResources[fullCard.resourceType].current >= fullCard.cost;
                  return (
                    <div className={style.cardActionOverlay}>
                      <div className="cardActionOptions">
                        <ActionButton
                          onClick={() => handleExamineCard(selectedCard)}
                          ariaLabel="Examine Card"
                          IconComponent={ExamineSymbol}
                        />
                        <ActionButton
                          onClick={() =>
                            handleCardAction("sacrificeForResources")
                          }
                          disabled={!isMyTurn || mySacrificeUsed}
                          ariaLabel="Discard for Resource"
                          IconComponent={GainResourceSymbol}
                        />
                        <ActionButton
                          onClick={() => handleCardAction("sacrificeForCards")}
                          disabled={!isMyTurn || mySacrificeUsed}
                          ariaLabel="Discard to Draw 2 Cards"
                          IconComponent={DrawTwoSymbol}
                        />

                        {["self", "opponent"].includes(fullCard.targeting) && (
                          <ActionButton
                            onClick={() => handleTargetPlayerEventCardAction()}
                            disabled={!isMyTurn || !hasEnoughResources}
                            ariaLabel="Play Card"
                            IconComponent={PlaySymbol}
                          />
                        )}
                      </div>
                    </div>
                  );
                })()}
            </div>
            <div className={style.handContainer}>
              {myHand && myHand.length > 0 ? (
                myHand.map((cardType, index) => {
                  const isSelected =
                    selectedCard?.cardType === cardType &&
                    selectedCard?.index === index;

                  const cardData = cardDatabase ? cardDatabase[cardType] : null;

                  const hasEnoughResources =
                    myResources[cardData.resourceType].current >= cardData.cost;

                  return (
                    <div
                      key={index}
                      style={{
                        ...styles.card,
                        outline: isSelected
                          ? "4px solid limegreen"
                          : "4px solid black",
                        zIndex: isSelected ? 999 : 1,
                        transform: isSelected
                          ? "translateY(-20px)"
                          : "translateY(0)",
                        position: "relative",
                      }}
                      onClick={() => handleSelectCard(cardType, index)}>
                      {cardData && (
                        <>
                          {cardData.image ? (
                            <img
                              src={cardData.image}
                              alt={cardType}
                              style={styles.cardImage}
                            />
                          ) : (
                            <div
                              style={{
                                ...styles.cardImage,
                                backgroundColor: "grey",
                                border: "2px solid rgba(0, 0, 0, 0.3)",
                              }}
                            />
                          )}

                          <div
                            style={{
                              position: "absolute",
                              top: "2px",
                              left: "15%",
                              transform: "translateX(-50%)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0px 6px",
                              backgroundColor: "rgba(0, 0, 0, 0.85)",
                              borderRadius: "8px",
                            }}>
                            <FactionSymbol type={cardData.resourceType} />
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: hasEnoughResources ? "white" : "brown",
                                transition: "opacity 0.2s ease",
                              }}>
                              {cardData.cost}
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "2px",
                              display: "flex",
                              padding: "2px 4px",
                              backgroundColor: "rgba(0, 0, 0, 0.85)",
                              borderRadius: "8px",
                              maxWidth: "60%",
                              zIndex: 1,
                              ...(cardData.name.length > 15 &&
                                cardData.name.includes(" ") && {
                                  maxWidth: "55%",
                                  textAlign: "right",
                                  lineHeight: "1.2",
                                  minHeight: "32px",
                                }),
                            }}>
                            <div
                              style={{
                                fontSize: getFontSize(cardData.name),
                                fontWeight: "bold",
                                color: "white",
                                textAlign: "right",
                                ...(cardData.name.length > 15 &&
                                cardData.name.includes(" ")
                                  ? {
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }
                                  : {
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }),
                              }}>
                              {cardData.name}
                            </div>
                          </div>

                          {(cardData.effectDescription ||
                            cardData.flavorText) && (
                            <div className={style.cardDescription}>
                              {cardData.effectDescription ||
                                cardData.flavorText}
                            </div>
                          )}

                          <div
                            style={{
                              position: "absolute",
                              bottom: "2px",
                              left: "2px",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.85)",
                              borderRadius: "4px",
                              padding: "2px",
                            }}>
                            <img
                              src={ATTACK_SYMBOL}
                              alt="Attack"
                              style={{ width: "12px", height: "12px" }}
                            />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "white",
                                marginLeft: "2px",
                              }}>
                              {cardData.maximumAttack}
                            </span>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              bottom: "2px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.85)",
                              borderRadius: "4px",
                              padding: "2px",
                            }}>
                            <img
                              src={COOLDOWN_SYMBOL}
                              alt="Cooldown"
                              style={{ width: "12px", height: "12px" }}
                            />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "white",
                                marginLeft: "2px",
                              }}>
                              {cardData.maximumCooldown}
                            </span>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "2px",
                              right: "2px",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.85)",
                              borderRadius: "4px",
                              padding: "2px",
                            }}>
                            <img
                              src={HEALTH_SYMBOL}
                              alt="Health"
                              style={{ width: "12px", height: "12px" }}
                            />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "white",
                                marginLeft: "2px",
                              }}>
                              {cardData.maximumHealth}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <div>No cards in hand.</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* TODO: Need to fix the position of the examined unit's card over the opponent's board or to the right of it, and adjust its size based on the screen resolution */}
      {isExaminingUnit &&
        examinedUnit &&
        (() => {
          const cardDef = cardDatabase[examinedUnit.type];
          const { col, row, isMine } = examinedUnit; // Extract our stored data

          console.log("examinedUnit", examinedUnit);

          return (
            <div style={styles.examinedUnitModalOverlay}>
              <div style={styles.examinedUnitModalContent}>
                <img
                  src={cardDef.image}
                  alt={cardDef.cardType}
                  style={styles.cardImage}
                />
                <h4 style={styles.cardTitle}>{cardDef.name}</h4>
                <span style={styles.topLeftInfo}>
                  <span style={styles.resourceType}>
                    <FactionSymbol
                      size={30}
                      type={cardDef.resourceType}
                    />{" "}
                    <span style={styles.costCardLabel}>{cardDef.cost}</span>
                  </span>
                </span>
                <div style={styles.middleText}>
                  {examinedUnit.currentArmor > 0 && (
                    <>
                      Current Armor: {examinedUnit.currentArmor}
                      <br />
                    </>
                  )}
                  {examinedUnit.currentEnergyShield > 0 && (
                    <>
                      Current Energy Shield: {examinedUnit.currentEnergyShield}
                      <br />
                    </>
                  )}
                  {cardDef.effectDescription}
                  {cardDef.effectDescription && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  <i>{cardDef.flavorText}</i>
                  {cardDef.specialActions &&
                    cardDef.specialActions.length > 0 && (
                      <div>
                        {cardDef.specialActions.map((actionObj, idx) => (
                          <button
                            style={{ fontSize: "0.75rem" }}
                            key={idx}
                            disabled={!isMyTurn || !isMine}
                            onClick={() =>
                              handleSpecialAction(
                                actionObj.name,
                                col,
                                row,
                                isMine
                              )
                            }>
                            {actionObj.name || "Special Action"}
                          </button>
                        ))}
                      </div>
                    )}
                </div>

                <div style={styles.bottomStats}>
                  <div style={styles.attackStat}>
                    <img
                      src={ATTACK_SYMBOL}
                      alt="Attack"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "4px",
                      }}
                    />
                    {cardDef.maximumAttack}
                  </div>
                  <div style={styles.cooldownStat}>
                    <img
                      src={COOLDOWN_SYMBOL}
                      alt="Cooldown"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "4px",
                      }}
                    />
                    {cardDef.maximumCooldown}
                  </div>
                  <div style={styles.healthStat}>
                    <img
                      src={HEALTH_SYMBOL}
                      alt="Health"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "4px",
                      }}
                    />
                    {cardDef.maximumHealth}
                  </div>
                </div>
              </div>
              <div style={styles.modalButtons}>
                <button
                  onClick={handleCloseUnitExaminingModal}
                  style={styles.closeModalButton}
                  aria-label="Close card details">
                  Close
                </button>
              </div>
            </div>
          );
        })()}

      {gameOver && isInGame && (
        <div className={style.gameOverModal}>
          <h1>Game Over</h1>
          <h2>Winner: {winner}</h2>
          <button onClick={handleReturnToLobby}>Return to Lobby</button>
        </div>
      )}
    </>
  );

  function HexCell({ color, children }) {
    const points = "40,0 74.64,20 74.64,60 40,80 5.36,60 5.36,20";

    return (
      <div style={{ position: "relative", width: 80, height: 80 }}>
        <svg
          width="80"
          height="80">
          <polygon
            points={points}
            fill={color}
            fillOpacity="0.70"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {children}
        </div>
      </div>
    );
  }

  function SquareCell({ color, children }) {
    return (
      <div
        style={{
          position: "relative",
          width: CRYSTAL_SIZE,
          height: CRYSTAL_SIZE,
          opacity: 0.9,
          backgroundColor: color,
          border: "2px solid black",
          boxSizing: "border-box",
          zIndex: 10,
        }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {children}
        </div>
      </div>
    );
  }

  function renderBoard(board, isMyBoard) {
    const displayedBoard = isMyBoard ? board : [...board].reverse();
    const originalBoardLength = board.length;
    const isOpponentBoardLocal = !isMyBoard;

    const HEX_SIDE = 40;
    const HEX_WIDTH = Math.sqrt(3) * HEX_SIDE;
    const HEX_HEIGHT = 2 * HEX_SIDE;

    function getHexPosition(row, col, isCrystal, isMyBoard) {
      if (isCrystal) {
        let x = col * (CRYSTAL_SIZE + 5);
        const y = row * (CRYSTAL_SIZE + CRYSTAL_SPACING_Y);
        if (!isMyBoard) {
          x += OPPONENT_CRYSTAL_OFFSET_X;
        }
        return { x, y };
      } else {
        let x = col * HEX_WIDTH;
        let y = row * (HEX_HEIGHT * 0.75);
        const shouldOffset = isMyBoard ? row % 2 === 0 : row % 2 === 1;
        if (shouldOffset) {
          x += HEX_WIDTH / 2;
        }
        return { x, y };
      }
    }

    const totalCols = displayedBoard.length;
    const maxRows = Math.max(...displayedBoard.map(col => col.length));
    const containerWidth = (totalCols - 1) * HEX_WIDTH + HEX_WIDTH;
    const containerHeight = (maxRows - 1) * (HEX_HEIGHT * 0.75) + HEX_HEIGHT;

    return (
      <div
        style={{
          position: "relative",
          margin: "1rem auto",
          width: containerWidth,
          height: containerHeight,
        }}>
        {displayedBoard.map((column, cIdx) =>
          column.map((cell, rIdx) => {
            const trueColIndex = isMyBoard
              ? cIdx
              : originalBoardLength - 1 - cIdx;

            let boardName = "";
            if (amPlayer1) {
              boardName = isMyBoard ? "player1" : "player2";
            } else {
              boardName = isMyBoard ? "player2" : "player1";
            }

            const isAttacker = highlightedAttackers.some(
              c =>
                c.board === boardName &&
                c.col === trueColIndex &&
                c.row === rIdx
            );
            const isVictim = highlightedVictims.some(
              c =>
                c.board === boardName &&
                c.col === trueColIndex &&
                c.row === rIdx
            );
            const isValidMoveCell =
              isMyBoard &&
              validMoves.some(m => m.col === trueColIndex && m.row === rIdx);
            const isSelectedTarget = selectedTargets.some(
              target =>
                target.col === trueColIndex &&
                target.row === rIdx &&
                target.isMyBoard === isMyBoard
            );

            const isAttackTelegraphCell = attackTelegraph.some(
              t =>
                t.isMyBoard === isMyBoard &&
                t.col === trueColIndex &&
                t.row === rIdx
            );
            const baseColor =
              cell?.type === "crystal" || cell?.type === "DESTROYED"
                ? "#9ef"
                : "#ccc";

            let cellBackground = baseColor;

            switch (true) {
              case isSelectedTarget:
                cellBackground = "darkgreen";
                break;
              case isAttacker:
                cellBackground = "red";
                break;
              case isVictim:
                cellBackground = "purple";
                break;
              case isValidMoveCell:
                cellBackground = "tan";
                break;
              case isAttackTelegraphCell:
                cellBackground = "orange";
                break;
              default:
                cellBackground = baseColor;
                break;
            }

            const isCrystal =
              cell?.type === "crystal" || cell?.type === "DESTROYED";
            const { x, y } = getHexPosition(rIdx, cIdx, isCrystal, isMyBoard);

            const cellContainerStyle = {
              position: "absolute",
              transform: `translate(${x}px, ${y}px)`,
              cursor: canClickCell(isMyBoard) ? "pointer" : "default",
              filter: isMyBoard && !isMyTurn ? "brightness(0.3)" : "none",
              zIndex: isCrystal ? 10 : 5,
            };

            const victimEntry = highlightedVictims.find(
              c =>
                c.board === boardName &&
                c.col === trueColIndex &&
                c.row === rIdx
            );
            const victimKey = `${boardName}-${trueColIndex}-${rIdx}`;
            const damage = damageMap.get(victimKey);

            return (
              <div
                key={`${cIdx}-${rIdx}`}
                style={cellContainerStyle}
                onClick={() => {
                  handleExamineCell(trueColIndex, rIdx, cell, isMyBoard);
                  if (canClickCell(isMyBoard)) {
                    handleCellClick(trueColIndex, rIdx, cell, isMyBoard);
                  }
                }}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}>
                  {isCrystal ? (
                    <SquareCell color={cellBackground}>
                      {renderCellContent(cell, rIdx, isOpponentBoardLocal)}
                    </SquareCell>
                  ) : (
                    <HexCell color={cellBackground}>
                      {renderCellContent(cell, rIdx, isOpponentBoardLocal)}
                    </HexCell>
                  )}
                  {victimEntry && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "20px",
                        textShadow: "1px 1px 2px black",
                        pointerEvents: "none",
                        zIndex: 20,
                      }}>
                      {damage}
                    </div>
                  )}
                  {showDamage && isVictim && (
                    <PhaserOverlay
                      parentId={`phaser-damage-${boardName}-${trueColIndex}-${rIdx}`}
                    />
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }

  function handleExamineCell(colIndex, rowIndex, cell, isMyBoard) {
    if (!cell || cell.type === "crystal" || cell.type === "DESTROYED") {
      setExaminedUnit(null);
      setSelectedUnit(null);
      setValidMoves([]);
      setAttackTelegraph([]);
      return;
    }

    if (
      examinedUnit &&
      examinedUnit.col === colIndex &&
      examinedUnit.row === rowIndex &&
      examinedUnit.isMine === isMyBoard
    ) {
      setExaminedUnit(null);
      setSelectedUnit(null);
      setValidMoves([]);
      setAttackTelegraph([]);
      return;
    }

    const examinedData = {
      ...cell,
      col: colIndex,
      row: rowIndex,
      isMine: isMyBoard,
    };
    setExaminedUnit(examinedData);
    setIsExaminingUnit(true);

    if (isMyBoard) {
      if (cell.currentMovement && cell.currentMovement > 0) {
        setSelectedUnit({ col: colIndex, row: rowIndex });
        const moves = getValidAdjacentMoves(
          myBoard,
          colIndex,
          rowIndex,
          cell.currentMovement
        );
        setValidMoves(moves);
      } else {
        setSelectedUnit(null);
        setValidMoves([]);
      }

      if (cell.canAttack) {
        const rawTelegraphCells = getAttackCellsOnOpponentBoard(
          cell,
          colIndex,
          rowIndex,
          opponentBoard
        );
        const finalTelegraph = rawTelegraphCells.map(tc => ({
          ...tc,
          isMyBoard: false,
        }));
        setAttackTelegraph(finalTelegraph);
      } else {
        setAttackTelegraph([]);
      }
    } else {
      setSelectedUnit(null);
      setValidMoves([]);

      if (cell.canAttack) {
        const rawTelegraphCells = getAttackCellsOnOpponentBoard(
          cell,
          colIndex,
          rowIndex,
          myBoard
        );
        const finalTelegraph = rawTelegraphCells.map(tc => ({
          ...tc,
          isMyBoard: true,
        }));
        setAttackTelegraph(finalTelegraph);
      } else {
        setAttackTelegraph([]);
      }
    }
  }

  function handleCellClick(columnIndex, rowIndex, cell, isMyBoard) {
    if (selectedCard) {
      setValidMoves([]);
      const cardDef = cardDatabase[selectedCard.cardType];
      if (!cardDef) {
        return;
      }
      if (
        (cardDef.card_type === "unit" || cardDef.card_type === "structure") &&
        !isMyBoard
      ) {
        Swal.fire({
          title: "Invalid Target",
          text: "Units and structures can only be placed on your own board!",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#d33",
          backdrop: "rgba(255, 0, 0, 0.1)",
        });
        setSelectedCard(null);
        return;
      }
      if (cardDef.targeting === "self") {
        return;
      }
      if (cardDef.targeting === "opponent") {
        return;
      }
      if (cardDef.targeting === "self" && !isMyBoard) {
        return;
      }
      if (cardDef.targeting === "opponent" && isMyBoard) {
        return;
      }
      const requiredResource = cardDef.resourceType;
      const requiredCost = cardDef.cost;
      if (myResources[requiredResource].current < requiredCost) {
        Swal.fire({
          title: "Insufficient Resources",
          html: `You need <b>${requiredCost} ${requiredResource} resource</b> to play this card!`,
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#d33",
          backdrop: "rgba(255, 215, 0, 0.1)",
        });
        setSelectedCard(null);
        return;
      }
      if (cardDef.card_type === "event") {
        handleTargetUnit(columnIndex, rowIndex, cell, isMyBoard);
        return;
      }
      if (!cell) {
        handlePlaceUnit(columnIndex, rowIndex, isMyBoard);
      } else {
        Swal.fire({
          title: "Occupied Space!",
          text: "Cannot place a unit on an occupied cell!",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#d33",
          backdrop: "rgba(255, 0, 0, 0.1)",
        });
        setSelectedCard(null);
      }
      return;
    }

    if (selectedUnit) {
      if (selectedUnit.col === columnIndex && selectedUnit.row === rowIndex) {
        setSelectedUnit(null);
        setValidMoves([]);
        setAttackTelegraph([]);
        return;
      }
      if (!cell) {
        ws.send(
          JSON.stringify({
            type: "game",
            action: "moveUnit",
            roomId,
            startCol: selectedUnit.col,
            startRow: selectedUnit.row,
            endCol: columnIndex,
            endRow: rowIndex,
          })
        );
        setHighlightedAttackers([]);
        setHighlightedVictims([]);
        setDamageMap(new Map());
        setSelectedUnit(null);
        setValidMoves([]);
        setAttackTelegraph([]);
      } else {
        Swal.fire({
          title: "Move Blocked!",
          html: `
            <div style="position: relative">
              <div style="font-size: 0.9rem; color: #666">
                You cannot move to a cell occupied by another unit
              </div>
            </div>
          `,
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#d33",
          backdrop: "rgba(100, 0, 0, 0.3)",
        });
      }
      setSelectedUnit(null);
      setValidMoves([]);
      return;
    }
  }

  function handleTargetPlayerEventCardAction() {
    if (!selectedCard) return;

    const cardDef = cardDatabase[selectedCard.cardType];
    if (!cardDef) {
      console.error("Card definition not found");
      setSelectedCard(null);
      return;
    }

    if (cardDef.targeting !== "self" && cardDef.targeting !== "opponent") {
      console.error("This card does not target a player directly.");
      setSelectedCard(null);
      return;
    }

    const requiredResource = cardDef.resourceType;
    const requiredCost = cardDef.cost;
    if (myResources[requiredResource].current < requiredCost) {
      Swal.fire({
        title: "Insufficient Resources",
        html: `You need <b>${requiredCost} ${requiredResource} resource</b> to play this card!`,
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#d33",
        backdrop: "rgba(255, 215, 0, 0.1)",
      });
      setSelectedCard(null);
      return;
    }

    const targetBoard =
      cardDef.targeting === "self"
        ? amPlayer1
          ? "player1"
          : "player2"
        : amPlayer1
        ? "player2"
        : "player1";

    ws.send(
      JSON.stringify({
        type: "game",
        action: "targetPlayerCard",
        roomId,
        pieceType: selectedCard.cardType,
        targetBoard,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setDamageMap(new Map());
    setValidMoves([]);
    setSelectedCard(null);
    setSelectedTargets([]);
  }

  function handlePlaceUnitMultiTarget(targets) {
    if (!selectedCard) return;
    if (!ws || !roomId) return;

    const { cardType } = selectedCard;

    const finalTargets = targets.map(t => {
      let boardName = "";
      if (amPlayer1) {
        boardName = t.isMyBoard ? "player1" : "player2";
      } else {
        boardName = t.isMyBoard ? "player2" : "player1";
      }
      return {
        columnIndex: t.col,
        cellIndex: t.row,
        targetBoard: boardName,
      };
    });

    ws.send(
      JSON.stringify({
        type: "game",
        action: "placeMultiTargetEvent",
        roomId,
        pieceType: cardType,
        targets: finalTargets,
      })
    );

    setHighlightedAttackers([]);
    setHighlightedVictims([]);
    setDamageMap(new Map());
    setSelectedCard(null);
  }

  function renderCellContent(cell, rowIndex, isOpponentBoard) {
    if (!cell) return null;

    if (cell.type === "crystal") {
      const crystalImages = [
        CRYSTAL_01,
        CRYSTAL_02,
        CRYSTAL_03,
        CRYSTAL_04,
        CRYSTAL_05,
      ];

      const safeIndex = Math.max(
        0,
        Math.min(rowIndex, crystalImages.length - 1)
      );

      const crystalImage = crystalImages[safeIndex];

      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            textAlign: "center",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            <img
              src={crystalImage}
              alt={`Crystal for row ${rowIndex + 1}`}
              style={{
                maxWidth: "80%",
                maxHeight: "80%",
              }}
            />
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              zIndex: 9999,
              padding: "0px",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={HEALTH_SYMBOL}
                  alt="Health"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentHealth}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (cell.type === "DESTROYED") {
      return (
        <div style={{ textAlign: "center" }}>
          <div style={{ color: "red", fontWeight: "bold" }}>DESTROYED</div>
          <div
            style={{ fontSize: "0.5rem", lineHeight: "1.2", color: "black" }}>
            X
          </div>
        </div>
      );
    }

    if (cardDatabase && cardDatabase[cell.type]) {
      const cardData = cardDatabase[cell.type];

      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 0,
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            {cardData.unit_image ? (
              <img
                src={cardData.unit_image}
                alt={cell.type}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transform: isOpponentBoard ? "scaleX(-1)" : "none",
                  position: "relative",
                  zIndex: 0,
                }}
              />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "0.6rem",
                  color: "black",
                }}>
                {cardData.displayName || capitalizeFirstLetter(cell.type)}
              </div>
            )}
          </div>

          <div
            style={{
              transform: "translateX(-50%)",
              position: "absolute",
              bottom: "5px",
              left: "50%",
              width: "80%",
              zIndex: 9999,
              padding: "0px",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={HEALTH_SYMBOL}
                  alt="Health"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentHealth}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={ATTACK_SYMBOL}
                  alt="Attack"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentAttack}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-1px",
                gap: "2px",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.85)",
                  borderRadius: "4px",
                  padding: "1px 2px",
                  zIndex: 9999,
                }}>
                <img
                  src={COOLDOWN_SYMBOL}
                  alt="Cooldown"
                  style={{
                    width: "10px",
                    height: "10px",
                    opacity: 0.85,
                  }}
                />
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "rgba(255, 255, 255, 0.85)",
                    marginLeft: "2px",
                    lineHeight: "1",
                  }}>
                  {cell.currentCooldown}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div>{cell.type}</div>;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleViewDiscardPile() {
    if (!ws || !roomId) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "viewDiscard",
        roomId,
      })
    );
  }

  function handleViewCardPlayingLog() {
    if (!ws || !roomId) return;
    ws.send(
      JSON.stringify({
        type: "game",
        action: "viewCardPlayingLog",
        roomId,
      })
    );
  }

  function getValidAdjacentMoves(board, startCol, startRow, currentMovement) {
    if (!currentMovement || currentMovement < 1) return [];

    const evenOffsets = [
      [0, -1],
      [+1, -1],
      [-1, 0],
      [+1, 0],
      [0, +1],
      [+1, +1],
    ];

    const oddOffsets = [
      [-1, -1],
      [0, -1],
      [-1, 0],
      [+1, 0],
      [-1, +1],
      [0, +1],
    ];

    const rowIsEven = startRow % 2 === 0;
    const offsets = rowIsEven ? evenOffsets : oddOffsets;

    const validMoves = [];
    for (const [dc, dr] of offsets) {
      const newCol = startCol + dc;
      const newRow = startRow + dr;

      if (newCol < 0 || newCol >= board.length) continue;
      if (newRow < 0 || newRow >= board[newCol].length) continue;

      if (board[newCol][newRow] === null) {
        validMoves.push({ col: newCol, row: newRow });
      }
    }

    return validMoves;
  }

  function canClickCell(isMyBoard) {
    if (!isMyTurn) return false;

    if (selectedCard) {
      return true;
    }

    return isMyBoard;
  }

  function getAttackCellsOnOpponentBoard(unit, col, row, boardOfOpponent) {
    if (!boardOfOpponent || !boardOfOpponent.length) return [];
    if (!unit.canAttack) return [];

    switch (unit.attackStyle) {
      case "backline":
        return findBacklineTarget(boardOfOpponent, row);
      case "rangedSplash":
        return findRangedSplashTarget(col, row, boardOfOpponent);
      default:
        return findFrontTargetOrCrystal(boardOfOpponent, row);
    }
  }

  function findRangedSplashTarget(col, rowIndex, boardOfOpponent) {
    const cellsToHighlight = [];
    const numCols = boardOfOpponent.length;
    const numRows = boardOfOpponent[0] ? boardOfOpponent[0].length : 0;

    if (!numCols || rowIndex < 0 || rowIndex >= numRows) {
      return cellsToHighlight;
    }

    const targetCol = numCols - col;

    if (targetCol >= 0 && targetCol < numCols) {
      cellsToHighlight.push({ col: targetCol, row: rowIndex });

      const splashCol = rowIndex % 2 === 0 ? targetCol - 1 : targetCol + 1;

      if (splashCol >= 0 && splashCol < numCols) {
        cellsToHighlight.push({ col: splashCol, row: rowIndex });
      }

      const aboveRow = rowIndex - 1;
      if (aboveRow >= 0) {
        cellsToHighlight.push({ col: targetCol, row: aboveRow });
      }

      const belowRow = rowIndex + 1;
      if (belowRow < numRows) {
        cellsToHighlight.push({ col: targetCol, row: belowRow });
      }
    }

    return cellsToHighlight;
  }

  function findFrontTargetOrCrystal(boardOfOpponent, rowIndex) {
    const cellsToHighlight = [];

    const numCols = boardOfOpponent.length;
    if (!numCols || rowIndex < 0 || rowIndex >= boardOfOpponent[0].length) {
      return cellsToHighlight;
    }

    for (let c = numCols - 1; c >= 0; c--) {
      const cell = boardOfOpponent[c][rowIndex];
      if (cell && cell.type !== "crystal" && cell.type !== "DESTROYED") {
        cellsToHighlight.push({ col: c, row: rowIndex });
        return cellsToHighlight;
      }
    }

    for (let c = numCols - 1; c >= 0; c--) {
      const cell = boardOfOpponent[c][rowIndex];
      if (cell && cell.type === "crystal") {
        cellsToHighlight.push({ col: c, row: rowIndex });
        return cellsToHighlight;
      }
    }

    return cellsToHighlight;
  }

  function findBacklineTarget(boardOfOpponent, rowIndex) {
    const cellsToHighlight = [];
    let foundUnit = false;

    if (
      rowIndex < 0 ||
      rowIndex >= (boardOfOpponent[0] ? boardOfOpponent[0].length : 0)
    ) {
      return cellsToHighlight;
    }

    for (let col = 0; col < boardOfOpponent.length; col++) {
      const cell = boardOfOpponent[col][rowIndex];
      if (cell && cell.type !== "crystal" && cell.type !== "DESTROYED") {
        cellsToHighlight.push({ col, row: rowIndex });
        foundUnit = true;
        break;
      }
    }
    if (!foundUnit) {
      for (let col = 0; col < boardOfOpponent.length; col++) {
        const cell = boardOfOpponent[col][rowIndex];
        if (cell && cell.type === "crystal") {
          cellsToHighlight.push({ col, row: rowIndex });
          break;
        }
      }
    }

    return cellsToHighlight;
  }
}

const ActionButton = ({ onClick, disabled, ariaLabel, IconComponent }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    ...styles.actionButton,
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
    color: disabled ? "#999" : "#000",
    transform: isHovered && !disabled ? "scale(1.5)" : "scale(1)",
  };

  return (
    <div
      style={{
        display: "inline-block",
        textAlign: "center",
        position: "relative",
      }}>
      <button
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        aria-label={ariaLabel}
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <IconComponent style={styles.svgIcon} />
      </button>
      {isHovered && !disabled && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "100%",
            marginTop: -5,
            fontSize: "0.8em",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            borderRadius: "4px",
            zIndex: 999,
            transform: "translateX(-50%)",
            whiteSpace: "nowrap",
            padding: "2px 4px",
          }}>
          {ariaLabel}
        </div>
      )}
    </div>
  );
};

function PhaserOverlay({ parentId }) {
  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 32,
      height: 32,
      parent: parentId,
      transparent: true,
      scene: {
        preload: function () {
          this.load.spritesheet("damage", DamageAnimation, {
            frameWidth: 32,
            frameHeight: 32,
          });
        },
        create: function () {
          this.anims.create({
            key: "damageAnim",
            frames: this.anims.generateFrameNumbers("damage", {
              start: 0,
              end: 15,
            }),
            frameRate: 30,
          });
          this.add.sprite(16, 16, "damage").play("damageAnim");
        },
      },
    };
    const game = new Phaser.Game(config);
    return () => {
      game.destroy(true);
    };
  }, [parentId]);

  return (
    <div
      id={parentId}
      style={{
        position: "absolute",
        width: 32,
        height: 32,
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        zIndex: 9999,
      }}
    />
  );
}

const styles = {
  //TODO: These will take more time to transfer over to the module.scss
  damageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "red",
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "1px 1px 2px black",
    pointerEvents: "none",
    zIndex: 20,
  },
  // damageAnimation: {
  //   position: "absolute",
  //   left: "50%",
  //   top: "50%",
  //   width: `${FRAME_SIZE}px`,
  //   height: `${FRAME_SIZE}px`,
  //   backgroundImage: `url(${DamageAnimation})`,
  //   backgroundSize: `${COLS * FRAME_SIZE}px ${ROWS * FRAME_SIZE}px`,
  //   imageRendering: "pixelated",
  //   pointerEvents: "none",
  //   transform: "translate(-50%, -50%)",
  //   animation: "damage-grid-animation 0.5s steps(1) forwards",
  //   zIndex: 9999,
  // },

  actionButton: {
    background: "transparent",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    marginTop: "-200px",
    transition: "transform 0.2s",
  },

  svgIcon: {
    width: "50px",
    height: "50px",
    fill: "currentColor",
  },

  deckIcon: {
    width: "24px",
    height: "24px",
  },

  playerNameBox: {
    backgroundColor: "rgba(40, 40, 40, 0.9)",
    padding: "4px 20px",
    borderRadius: "15px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    width: "200px",
    minWidth: "200px",
    textAlign: "center",
    transform: "translateX(20px)",
    marginRight: "10px",
  },
  opponentNameBox: {
    backgroundColor: "rgba(40, 40, 40, 0.9)",
    padding: "4px 20px",
    borderRadius: "15px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    width: "200px",
    minWidth: "200px",
    textAlign: "center",
    transform: "translateX(-20px)",
    marginLeft: "10px",
  },
  boardWrapper: {
    flex: 1,
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 1.5rem",
    "& h3": {
      margin: "0.2rem 0",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "0 5px",
  },

  //TODO: These ones have not been tried and moved over yet

  card: {
    position: "relative",
    margin: "0 4px",
    transition: "transform 0.2s ease",
    boxSizing: "border-box",
    width: 125,
    height: 175,
    marginLeft: -40,
    border: "2px solid black",
    borderRadius: 4,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  examinedUnitModalOverlay: {
    position: "fixed",
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 9000,
  },

  examinedUnitModalContent: {
    position: "relative",
    width: "320px",
    height: "450px",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
  },

  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9000,
  },
  modalContent: {
    position: "relative",
    width: "320px",
    height: "450px",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
  },

  cardTitle: {
    position: "absolute",
    top: "5px",
    left: "98%",
    width: "60%",
    transform: "translateX(-100%)",
    color: "white",
    fontSize: "0.75em",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    margin: 0,
    textAlign: "center",
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "4px 12px",
    borderRadius: "8px",
  },
  topLeftInfo: {
    position: "absolute",
    top: "5px",
    left: "5px",
    color: "white",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
  },
  resourceType: {
    fontSize: "1.0em",
    fontWeight: "bold",
    display: "inline-flex",
    alignItems: "center",
    gap: "2px",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "2px 4px",
    borderRadius: "6px",
  },

  costCardLabel: {
    fontSize: "1.0em",
  },
  middleText: {
    position: "absolute",
    bottom: "15%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "80%",
    color: "white",
    textAlign: "center",
    fontSize: "0.6em",
    lineHeight: "1.2",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    textShadow: "1px 1px 3px rgba(0, 0, 0, 0.8)",
    padding: "2px 8px",
    borderRadius: "6px",
  },
  bottomStats: {
    position: "absolute",
    bottom: "5px",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 25px",
    color: "white",
    fontSize: "1.0em",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
  },

  attackStat: {
    fontSize: "1.5em",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "2px 8px",
    borderRadius: "6px",
  },

  cooldownStat: {
    fontSize: "1.5em",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "2px 8px",
    borderRadius: "6px",
  },

  healthStat: {
    fontSize: "1.5em",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "2px 8px",
    borderRadius: "6px",
  },

  modalButtons: {
    marginTop: "25px",
    display: "flex",
    gap: "15px",
  },
  closeModalButton: {
    padding: "10px 20px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ParchmentGame;
