export const defaultRooms = [
  { name: "Inventory", backgroundColor: "darkgray" },
  { name: "Hangout", backgroundColor: "darkgoldenrod" },
  { name: "Sleeping", backgroundColor: "darkblue" },
  { name: "Training", backgroundColor: "gray" },
  { name: "Quest", backgroundColor: "tan" },
  { name: "Mines", backgroundColor: "dimgray" },
  { name: "Weightlifting", backgroundColor: "firebrick" },
  { name: "Running Track", backgroundColor: "darkgreen" },
  { name: "Library", backgroundColor: "mediumpurple" },
  { name: "Crusher", backgroundColor: "#450707" },
  { name: "Breeding", backgroundColor: "purple" },
  { name: "Incubator", backgroundColor: "olive" },
  { name: "Arena", backgroundColor: "darkorange" },
  { name: "Market", backgroundColor: "black" },
  { name: "Task Board", backgroundColor: "brown" },
  { name: "Research Lab", backgroundColor: "darkslategray" },
  { name: "Exploration", backgroundColor: "tan" },
];

export const defaultUnlockedRooms = [
  "Inventory",
  "Hangout",
  "Sleeping",
  "Training",
  "Quest",
  "Mines",
  "Research Lab",
  "Exploration",
];
