import Phaser from "phaser";

class NotForagerMainMenuScene extends Phaser.Scene {
  constructor(user) {
    super({ key: "MainMenuScene" });
    this.user = user;
  }

  create() {
    this.add
      .text(200, 200, "Enter Game", { fontSize: "32px", fill: "#fff" })
      .setInteractive()
      .on("pointerdown", () => {
        this.scene.start("NotForagerWorldScene", {
          username: this.user?.username || "Guest",
        });
      });
  }
}

export default NotForagerMainMenuScene;
