import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import styles from "./DuelCards.module.scss";
import DeckBuilder from "./DeckBuilder";
import Matchmaking from "./Matchmaking";
import CardShop from "./CardShop";
import Campaign from "./Campaign";
import ICON_ALTHIAN from "./assets/ICON_ALTHIAN.svg";
import ICON_NATORAN from "./assets/ICON_NATORAN.svg";
import ICON_XENOZORIAN from "./assets/ICON_XENOZORIAN.svg";
import ICON_NECRONIAN from "./assets/ICON_NECRONIAN.svg";
import ICON_ACTION from "./assets/ICON_ACTION.svg";
import ICON_LIFE from "./assets/ICON_LIFE.svg";
import ICON_ATTACK from "./assets/ICON_ATTACK.svg";
import ICON_DEFENSE from "./assets/ICON_DEFENSE.svg";
import ICON_RANDOM from "./assets/ICON_RANDOM.svg";
import ICON_DISCARD_PILE from "./assets/ICON_DISCARD_PILE.svg";
import ICON_EXAMINE from "./assets/ICON_EXAMINE.svg";
import ICON_LEVEL_UP from "./assets/ICON_LEVEL_UP.svg";
import ICON_RECYCLE from "./assets/ICON_RECYCLE.svg";
import ICON_END_TURN from "./assets/ICON_END_TURN.svg";
import ICON_DISCARD_FOR_RESOURCE from "./assets/ICON_DISCARD_FOR_RESOURCE.svg";
import ICON_RESOURCES from "./assets/ICON_RESOURCES.svg";
import ICON_LEVEL_ONE from "./assets/ICON_LEVEL_ONE.svg";
import ICON_LEVEL_TWO from "./assets/ICON_LEVEL_TWO.svg";
import ICON_LEVEL_THREE from "./assets/ICON_LEVEL_THREE.svg";
import ICON_STUN from "./assets/ICON_STUN.svg";
import ICON_SUMMONING_SICKNESS from "./assets/ICON_SUMMONING_SICKNESS.svg";
import ExamineCard from "./ExamineCard";
import LastPlayedCardQueue from "./LastPlayedCardQueue";

export default function DuelCards() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [cards, setCards] = useState([]);
  const [decks, setDecks] = useState([]);
  const [collection, setCollection] = useState({});
  const [gold, setGold] = useState(0);
  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0);
  const [inGame, setInGame] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const [opponentName, setOpponentName] = useState("");
  const [playerDeck, setPlayerDeck] = useState({});
  const [opponentDeck, setOpponentDeck] = useState({});
  const [isQueueing, setIsQueueing] = useState(false);
  const [hasPreviousGame, setHasPreviousGame] = useState(false);
  const [hasCampaignGameInProgress, setHasCampaignGameInProgress] =
    useState(false);
  const [playerBoard, setPlayerBoard] = useState([]);
  const [opponentBoard, setOpponentBoard] = useState([]);
  const [playerHand, setPlayerHand] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardLocation, setSelectedCardLocation] = useState(null);
  const [examinedCard, setExaminedCard] = useState(null);
  const [playerLife, setPlayerLife] = useState(0);
  const [opponentLife, setOpponentLife] = useState(0);
  const [playerLevel, setPlayerLevel] = useState(1);
  const [opponentLevel, setOpponentLevel] = useState(1);
  const [playerXP, setPlayerXP] = useState(0);
  const [opponentXP, setOpponentXP] = useState(0);
  const [playerActionPoints, setPlayerActionPoints] = useState(1);
  const [opponentActionPoints, setOpponentActionPoints] = useState(1);
  const [playerResources, setPlayerResources] = useState({});
  const [opponentResources, setOpponentResources] = useState({});
  const [playerFactions, setPlayerFactions] = useState([]);
  const [opponentFactions, setOpponentFactions] = useState([]);
  const [currentTurn, setCurrentTurn] = useState(1);
  const [isPlayerTurn, setIsPlayerTurn] = useState(false);
  const [playerDiscard, setPlayerDiscard] = useState([]);
  const [opponentDiscard, setOpponentDiscard] = useState([]);
  const [damageHealIndicators, setDamageHealIndicators] = useState({});
  const [destroyedIndicators, setDestroyedIndicators] = useState({});
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [tooltip, setTooltip] = useState({
    text: "",
    x: 0,
    y: 0,
    visible: false,
  });
  const xpCaps = { 1: 6, 2: 14, 3: 22 };
  const actionButtonsRef = useRef(null);
  const wasPlayerTurnRef = useRef(false);

  const factionIcons = {
    althian: ICON_ALTHIAN,
    natoran: ICON_NATORAN,
    xenozorian: ICON_XENOZORIAN,
    necronian: ICON_NECRONIAN,
  };
  const [menuOption, setMenuOption] = useState("MENU");
  const [lastPlayedCards, setLastPlayedCards] = useState([]);
  const [campaignStages, setCampaignStages] = useState([]);

  function refreshCampaignStages() {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "CAMPAIGN", action: "LIST" }));
    }
  }

  function getSpellDamageValues(cardData) {
    if (!cardData.triggers) return null;
    const damageEffects = cardData.triggers
      .flatMap(t => t.effect)
      .filter(e => e.type === "damage");
    if (!damageEffects.length) return null;
    const damageValues = [];
    for (let level = 1; level <= 3; level++) {
      const found = damageEffects.find(e => e.level === level);
      damageValues.push(found ? found.value : 0);
    }
    return damageValues;
  }

  const getImageUrlByCardKey = cardKey => `/assets/${cardKey}.png`;

  function showDamageOrHeal(cardId, amount) {
    setDamageHealIndicators(prev => ({ ...prev, [cardId]: amount }));
    setTimeout(() => {
      setDamageHealIndicators(prev => {
        const { [cardId]: omit, ...rest } = prev;
        return rest;
      });
      setDestroyedIndicators(prev => {
        const newObj = { ...prev };
        Object.keys(newObj).forEach(key => {
          if (newObj[key] === cardId) {
            delete newObj[key];
          }
        });
        return newObj;
      });
    }, 3000);
  }

  function applyGameStateData(data) {
    setInGame(true);
    setIsQueueing(false);
    setHasPreviousGame(false);
    setRoomId(data.roomId);
    setOpponentName(data.opponentName);
    setPlayerDeck(data.playerDeck);
    setOpponentDeck(data.opponentDeck);
    setPlayerBoard(oldBoard => {
      const updatedBoard = data.playerBoard || [];
      const newCardIds = updatedBoard.map(c => c?.id).filter(Boolean);
      oldBoard.forEach((oldCard, i) => {
        if (oldCard) {
          const oldEffective = oldCard.health + (oldCard.defense || 0);
          if (!newCardIds.includes(oldCard.id)) {
            showDamageOrHeal(oldCard.id, 0 - oldEffective);
            setDestroyedIndicators(prev => ({ ...prev, [i]: oldCard.id }));
          } else {
            const newIndex = updatedBoard.findIndex(
              c => c && c.id === oldCard.id
            );
            if (newIndex !== -1) {
              const newCard = updatedBoard[newIndex];
              const newEffective = newCard.health + (newCard.defense || 0);
              if (newEffective < oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              } else if (newEffective > oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              }
            }
          }
        }
      });
      return updatedBoard;
    });
    setOpponentBoard(oldBoard => {
      const updatedBoard = data.opponentBoard || [];
      const newCardIds = updatedBoard.map(c => c?.id).filter(Boolean);
      oldBoard.forEach((oldCard, i) => {
        if (oldCard) {
          const oldEffective = oldCard.health + (oldCard.defense || 0);
          if (!newCardIds.includes(oldCard.id)) {
            showDamageOrHeal(oldCard.id, 0 - oldEffective);
            setDestroyedIndicators(prev => ({
              ...prev,
              [i + "_op"]: oldCard.id,
            }));
          } else {
            const newIndex = updatedBoard.findIndex(
              c => c && c.id === oldCard.id
            );
            if (newIndex !== -1) {
              const newCard = updatedBoard[newIndex];
              const newEffective = newCard.health + (newCard.defense || 0);
              if (newEffective < oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              } else if (newEffective > oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              }
            }
          }
        }
      });
      return updatedBoard;
    });
    setPlayerHand(data.playerHand || []);
    setPlayerLife(oldLife => {
      let newLife = data.playerLife ?? 25;
      if (newLife !== oldLife) {
        showDamageOrHeal("playerLife", newLife - oldLife);
      }
      return newLife;
    });
    setOpponentLife(oldLife => {
      let newLife = data.opponentLife ?? 25;
      if (newLife !== oldLife) {
        showDamageOrHeal("opponentLife", newLife - oldLife);
      }
      return newLife;
    });
    setPlayerLevel(data.playerLevel ?? 1);
    setOpponentLevel(data.opponentLevel ?? 1);
    setPlayerXP(data.playerXP ?? 0);
    setOpponentXP(data.opponentXP ?? 0);
    setPlayerActionPoints(data.playerActionPoints ?? 1);
    setOpponentActionPoints(data.opponentActionPoints ?? 1);
    setPlayerResources(data.playerResources || {});
    setOpponentResources(data.opponentResources || {});
    setPlayerFactions(data.playerFactions || []);
    setOpponentFactions(data.opponentFactions || []);
    setCurrentTurn(data.currentTurn ?? 1);
    setIsPlayerTurn(data.isPlayerTurn ?? false);
    setPlayerDiscard(data.playerDiscard || []);
    setOpponentDiscard(data.opponentDiscard || []);
    setDamageHealIndicators({});
    setDestroyedIndicators({});
  }

  function updateGameStateData(data) {
    setPlayerBoard(oldBoard => {
      const updatedBoard = data.playerBoard || [];
      const newCardIds = updatedBoard.map(c => c?.id).filter(Boolean);
      oldBoard.forEach((oldCard, i) => {
        if (oldCard) {
          const oldEffective = oldCard.health + (oldCard.defense || 0);
          if (!newCardIds.includes(oldCard.id)) {
            showDamageOrHeal(oldCard.id, 0 - oldEffective);
            setDestroyedIndicators(prev => ({ ...prev, [i]: oldCard.id }));
          } else {
            const newIndex = updatedBoard.findIndex(
              c => c && c.id === oldCard.id
            );
            if (newIndex !== -1) {
              const newCard = updatedBoard[newIndex];
              const newEffective = newCard.health + (newCard.defense || 0);
              if (newEffective < oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              } else if (newEffective > oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              }
            }
          }
        }
      });
      return updatedBoard;
    });
    setOpponentBoard(oldBoard => {
      const updatedBoard = data.opponentBoard || [];
      const newCardIds = updatedBoard.map(c => c?.id).filter(Boolean);
      oldBoard.forEach((oldCard, i) => {
        if (oldCard) {
          const oldEffective = oldCard.health + (oldCard.defense || 0);
          if (!newCardIds.includes(oldCard.id)) {
            showDamageOrHeal(oldCard.id, 0 - oldEffective);
            setDestroyedIndicators(prev => ({
              ...prev,
              [i + "_op"]: oldCard.id,
            }));
          } else {
            const newIndex = updatedBoard.findIndex(
              c => c && c.id === oldCard.id
            );
            if (newIndex !== -1) {
              const newCard = updatedBoard[newIndex];
              const newEffective = newCard.health + (newCard.defense || 0);
              if (newEffective < oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              } else if (newEffective > oldEffective) {
                showDamageOrHeal(newCard.id, newEffective - oldEffective);
              }
            }
          }
        }
      });
      return updatedBoard;
    });
    setPlayerHand(data.playerHand || []);
    setPlayerLife(oldLife => {
      let newLife = data.playerLife ?? 0;
      if (newLife !== oldLife) {
        showDamageOrHeal("playerLife", newLife - oldLife);
      }
      return newLife;
    });
    setOpponentLife(oldLife => {
      let newLife = data.opponentLife ?? 0;
      if (newLife !== oldLife) {
        showDamageOrHeal("opponentLife", newLife - oldLife);
      }
      return newLife;
    });
    setPlayerLevel(data.playerLevel || 1);
    setOpponentLevel(data.opponentLevel || 1);
    setPlayerXP(data.playerXP || 0);
    setOpponentXP(data.opponentXP || 0);
    setPlayerActionPoints(data.playerActionPoints || 0);
    setPlayerResources(data.playerResources || {});
    setOpponentResources(data.opponentResources || {});
    setCurrentTurn(data.currentTurn || 1);
    setIsPlayerTurn(data.isPlayerTurn || false);
    setPlayerDiscard(data.playerDiscard || []);
    setOpponentDiscard(data.opponentDiscard || []);
  }

  function resetGameState() {
    setInGame(false);
    setIsQueueing(false);
    setRoomId(null);
    setOpponentName("");
    setPlayerDeck({});
    setOpponentDeck({});
    setHasPreviousGame(false);
    setPlayerBoard([]);
    setOpponentBoard([]);
    setPlayerHand([]);
    setPlayerLife(25);
    setOpponentLife(25);
    setPlayerLevel(1);
    setOpponentLevel(1);
    setPlayerXP(0);
    setOpponentXP(0);
    setPlayerActionPoints(1);
    setOpponentActionPoints(1);
    setPlayerResources({});
    setOpponentResources({});
    setPlayerFactions([]);
    setOpponentFactions([]);
    setCurrentTurn(1);
    setIsPlayerTurn(false);
    setPlayerDiscard([]);
    setOpponentDiscard([]);
    setSelectedCardId(null);
    setSelectedCardLocation(null);
    setExaminedCard(null);
    setMenuOption("MENU");
    setDamageHealIndicators({});
    setDestroyedIndicators({});
  }

  useEffect(() => {
    let socket;
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    switch (environment) {
      case "localhost":
        socket = new WebSocket("ws://localhost:5000/duel-cards");
        break;
      case "localNetwork":
        socket = new WebSocket("ws://192.168.0.7:5000/duel-cards");
        break;
      case "production":
        socket = new WebSocket("wss://2nguyen.dev/duel-cards");
        break;
      default:
        return;
    }
    setWs(socket);
    socket.onopen = () => {
      socket.send(
        JSON.stringify({ type: "CONNECT", username: user?.username || "Guest" })
      );
      socket.send(JSON.stringify({ type: "DECK", action: "GET" }));
    };
    socket.onmessage = event => {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "WELCOME":
          Swal.fire({
            icon: "success",
            title: "Welcome!",
            text: `Hello ${data.username}`,
          });
          break;
        case "CARDS":
          setCards(data.cards);
          break;
        case "DECKS":
          setDecks(data.decks || []);
          if (data.collection) setCollection(data.collection);
          if (typeof data.gold === "number") setGold(data.gold);
          if (typeof data.wins === "number") setWins(data.wins);
          if (typeof data.losses === "number") setLosses(data.losses);
          break;
        case "DECK_SAVED":
          setDecks(data.decks || []);
          if (data.collection) setCollection(data.collection);
          if (typeof data.gold === "number") setGold(data.gold);
          Swal.fire({
            icon: "success",
            title: "Deck saved successfully!",
            text: "Your changes have been saved.",
          });
          break;
        case "DECK_ACTIVE_SET":
          setDecks(data.decks || []);
          if (data.collection) setCollection(data.collection);
          if (typeof data.gold === "number") setGold(data.gold);
          Swal.fire({
            icon: "success",
            title: "Deck set as active!",
            text: "Your active deck has changed.",
          });
          break;
        case "DECK_DELETED":
          setDecks(data.decks || []);
          if (data.collection) setCollection(data.collection);
          if (typeof data.gold === "number") setGold(data.gold);
          Swal.fire({
            icon: "success",
            title: "Deck deleted!",
            text: "Your deck has been removed.",
          });
          break;
        case "HAS_PREVIOUS_GAME":
          setHasPreviousGame(true);
          break;
        case "GAME_STATE":
          applyGameStateData(data);
          break;
        case "GAME_UPDATE":
          updateGameStateData(data);
          if (data.isPlayerTurn && !wasPlayerTurnRef.current) {
            setLastPlayedCards(prev => [...prev, { type: "TURN_INDICATOR" }]);
          }
          wasPlayerTurnRef.current = data.isPlayerTurn;
          break;
        case "GAME_ENDED":
          if (typeof data.gold === "number") setGold(data.gold);
          if (typeof data.wins === "number") setWins(data.wins);
          if (typeof data.losses === "number") setLosses(data.losses);
          resetGameState();
          if (data.winner) {
            Swal.fire({
              icon: "info",
              title: "Game Over",
              text: data.winner + " is the winner!",
            });
          } else {
            Swal.fire({
              icon: "info",
              title: "Game Over",
              text: "The game has ended or was forfeited.",
            });
          }
          break;
        case "GAME_ERROR":
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message,
          });
          break;
        case "SHOP_UPDATE":
          if (data.collection) setCollection(data.collection);
          if (typeof data.gold === "number") setGold(data.gold);
          if (data.purchased && data.purchased.length) {
            data.purchased.reduce((chain, cardKey) => {
              return chain.then(() => {
                return Swal.fire({
                  icon: "success",
                  title: `Purchased: ${cardKey}`,
                  text: "Your collection and gold have been updated.",
                  imageUrl: getImageUrlByCardKey(cardKey),
                  imageHeight: 250,
                  imageAlt: cardKey,
                });
              });
            }, Promise.resolve());
          } else {
            Swal.fire({
              icon: "success",
              title: "Cards Purchased or Updated",
              text: "Your collection and gold have been updated.",
            });
          }
          break;
        case "CARD_PLAYED":
          setLastPlayedCards(prev => [
            ...prev,
            {
              cardName: data.cardName,
              level: data.level,
              playedBy: data.playedBy,
            },
          ]);
          break;
        case "CAMPAIGN_LIST":
          if (data.stages) {
            setCampaignStages(data.stages);
          }
          break;
        case "HAS_PREVIOUS_CAMPAIGN_GAME":
          setHasCampaignGameInProgress(true);
          break;
        default:
          break;
      }
    };
    socket.onclose = () => {
      setWs(null);
    };
    return () => {
      if (socket) socket.close();
    };
  }, [user?.username, location.href]);

  useEffect(() => {
    if (isPlayerTurn && !wasPlayerTurnRef.current) {
      setLastPlayedCards(prev => [...prev, { type: "TURN_INDICATOR" }]);
    }
    wasPlayerTurnRef.current = isPlayerTurn;
  }, [isPlayerTurn]);

  function getStatsForHandCard(cardName) {
    const fullCardData = cards.find(c => c.key === cardName);
    if (!fullCardData) return { attack: 0, defense: 0, health: 0 };
    if (fullCardData.cardType === "spell") {
      const damageVals = getSpellDamageValues(fullCardData);
      if (damageVals) {
        return {
          attack: damageVals[playerLevel - 1] || 0,
          defense: 0,
          health: 0,
        };
      }
      return { attack: 0, defense: 0, health: 0 };
    }
    const attackObj = fullCardData.attack?.find(a => a.level === playerLevel);
    const defenseObj = fullCardData.defense?.find(d => d.level === playerLevel);
    const healthObj = fullCardData.health?.find(h => h.level === playerLevel);
    return {
      attack: attackObj ? attackObj.value : 0,
      defense: defenseObj ? defenseObj.value : 0,
      health: healthObj ? healthObj.value : 0,
    };
  }

  function getStatsForBoardCard(slot) {
    if (!slot) return { attack: 0, defense: 0, health: 0 };
    const fullCardData = cards.find(c => c.key === slot.cardName);
    if (!fullCardData) return { attack: 0, defense: 0, health: 0 };
    if (fullCardData.cardType === "spell") {
      const damageVals = getSpellDamageValues(fullCardData);
      if (damageVals) {
        return {
          attack: damageVals[slot.level - 1] || 0,
          defense: 0,
          health: 0,
        };
      }
      return { attack: 0, defense: 0, health: 0 };
    }
    if (typeof slot.attack === "number" && typeof slot.health === "number") {
      return {
        attack: slot.attack,
        defense: slot.defense || 0,
        health: slot.health,
      };
    }
    return { attack: 0, defense: 0, health: 0 };
  }

  function getCostForDiscardCard(card) {
    const fullCardData = cards.find(c => c.key === card.cardName);
    if (!fullCardData) return 0;
    let baseCost = 0;
    const costObj = Array.isArray(fullCardData.cost)
      ? fullCardData.cost.find(c => c.level === playerLevel)
      : null;
    if (costObj) baseCost = costObj.value;
    else if (!Array.isArray(fullCardData.cost))
      baseCost = fullCardData.cost || 0;
    return baseCost + (card.costIncrease || 0);
  }

  function getStatsForDiscardCard(card) {
    const fullCardData = cards.find(c => c.key === card.cardName);
    if (!fullCardData) return { attack: 0, defense: 0, health: 0 };
    if (fullCardData.cardType === "spell") {
      const damageVals = getSpellDamageValues(fullCardData);
      if (damageVals) {
        return {
          attack: damageVals[playerLevel - 1] || 0,
          defense: 0,
          health: 0,
        };
      }
      return { attack: 0, defense: 0, health: 0 };
    }
    const attackObj = fullCardData.attack?.find(a => a.level === playerLevel);
    const defenseObj = fullCardData.defense?.find(d => d.level === playerLevel);
    const healthObj = fullCardData.health?.find(h => h.level === playerLevel);
    return {
      attack: attackObj ? attackObj.value : 0,
      defense: defenseObj ? defenseObj.value : 0,
      health: healthObj ? healthObj.value : 0,
    };
  }

  function showTooltip(e, text) {
    e.preventDefault();
    if (!actionButtonsRef.current) return;
    const rect = actionButtonsRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    setTooltip({ text, x: centerX, y: centerY - 30, visible: true });
  }

  function hideTooltip() {
    setTooltip({ ...tooltip, visible: false });
  }

  function forfeitGame() {
    if (ws) {
      ws.send(JSON.stringify({ type: "GAME", action: "FORFEIT", roomId }));
    }
  }

  function handleHandCardClick(cardId) {
    if (selectedCardLocation === "hand" && selectedCardId === cardId) {
      setSelectedCardId(null);
      setSelectedCardLocation(null);
      return;
    }
    setSelectedCardId(cardId);
    setSelectedCardLocation("hand");
  }

  function handleBoardSlotClick(slotIndex, location) {
    if (!inGame || !ws) return;
    if (selectedCardLocation === "hand" && selectedCardId !== null) {
      if (!isPlayerTurn) return;
      const cardInHand = playerHand.find(c => c.id === selectedCardId);
      if (!cardInHand) return;
      const foundDB = cards.find(c => c.key === cardInHand.cardName);
      if (foundDB && foundDB.cardType === "spell") {
        const effectsThisLevel = foundDB.effect
          ? foundDB.effect.filter(e => e.level === playerLevel)
          : [];
        const mustHaveTarget = effectsThisLevel.some(
          eff =>
            eff.target === "selected" || eff.target === "target_and_adjacent"
        );
        const canTargetEmpty = effectsThisLevel.some(
          eff => eff.type === "summon" && eff.target === "selected"
        );
        const boardSlot =
          location === "boardPlayer"
            ? playerBoard[slotIndex]
            : opponentBoard[slotIndex];
        if (mustHaveTarget && !canTargetEmpty && !boardSlot) {
          Swal.fire({
            icon: "error",
            title: "Invalid Target",
            text: "You cannot cast this spell on an empty slot.",
          });
          return;
        }
        ws.send(
          JSON.stringify({
            type: "GAME",
            action: "PLAY_SPELL",
            roomId,
            cardId: selectedCardId,
            targetSlotIndex: slotIndex,
            targetBoardLocation: location,
          })
        );
        setSelectedCardId(null);
        setSelectedCardLocation(null);
      } else {
        ws.send(
          JSON.stringify({
            type: "GAME",
            action: "PLAY_CARD",
            roomId,
            cardId: selectedCardId,
            slotIndex,
          })
        );
        setSelectedCardId(null);
        setSelectedCardLocation(null);
      }
    } else {
      let boardCard = null;
      if (location === "boardPlayer") {
        boardCard = playerBoard[slotIndex];
      } else {
        boardCard = opponentBoard[slotIndex];
      }
      if (boardCard) {
        if (
          selectedCardId === boardCard.id &&
          selectedCardLocation === location
        ) {
          setSelectedCardId(null);
          setSelectedCardLocation(null);
        } else {
          setSelectedCardId(boardCard.id);
          setSelectedCardLocation(location);
        }
      }
    }
  }

  function handleChooseResource() {
    if (!ws || !isPlayerTurn || playerActionPoints <= 0) return;
    ws.send(
      JSON.stringify({
        type: "GAME",
        action: "CHOOSE_RESOURCE",
        roomId,
        faction: "random",
      })
    );
  }

  function handleDiscardForResource() {
    if (!ws || !isPlayerTurn || playerActionPoints <= 0 || !selectedCardId)
      return;
    if (selectedCardLocation !== "hand") return;
    ws.send(
      JSON.stringify({
        type: "GAME",
        action: "DISCARD_FOR_RESOURCE",
        roomId,
        cardId: selectedCardId,
      })
    );
    setSelectedCardId(null);
    setSelectedCardLocation(null);
  }

  function handleEndTurn() {
    if (!ws || !isPlayerTurn) return;
    ws.send(JSON.stringify({ type: "GAME", action: "END_TURN", roomId }));
  }

  function handleRecycle() {
    if (!ws || !isPlayerTurn) return;
    ws.send(JSON.stringify({ type: "GAME", action: "RECYCLE", roomId }));
  }

  function openDiscardModal() {
    setIsDiscardModalOpen(true);
  }

  function closeDiscardModal() {
    setIsDiscardModalOpen(false);
  }

  function handleLevelUp() {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "GAME", action: "LEVEL_UP", roomId }));
  }

  function handleExamineCard() {
    if (!selectedCardId) return;
    if (selectedCardLocation === "hand") {
      const found = playerHand.find(c => c.id === selectedCardId);
      if (!found) return;
      const cardData = cards.find(d => d.key === found.cardName);
      setExaminedCard({ ...found, cardData });
    } else if (selectedCardLocation === "boardPlayer") {
      const found = playerBoard.find(c => c && c.id === selectedCardId);
      if (!found) return;
      const cardData = cards.find(d => d.key === found.cardName);
      setExaminedCard({ ...found, cardData });
    } else if (selectedCardLocation === "boardOpponent") {
      const found = opponentBoard.find(c => c && c.id === selectedCardId);
      if (!found) return;
      const cardData = cards.find(d => d.key === found.cardName);
      setExaminedCard({ ...found, cardData });
    }
  }

  if (inGame) {
    const canLevelUp =
      playerLevel < 3 &&
      playerXP >= xpCaps[playerLevel] &&
      playerActionPoints > 0;

    return (
      <div className={styles.duelCards}>
        <div className={styles.gameArea}>
          <div className={styles.opponentInfo}>
            <div className={styles.statsRow}>
              <div className={styles.statItem}>
                <img
                  src={ICON_LIFE}
                  alt="life"
                  className={styles.factionIcon}
                />
                {opponentLife}
                {damageHealIndicators["opponentLife"] !== undefined && (
                  <div
                    className={`${styles.damageIndicator} ${
                      styles.opponentLifeIndicator
                    } ${
                      damageHealIndicators["opponentLife"] > 0
                        ? styles.healText
                        : styles.damageText
                    }`}>
                    {damageHealIndicators["opponentLife"] > 0 ? "+" : ""}
                    {damageHealIndicators["opponentLife"]}
                  </div>
                )}
              </div>
              <div className={styles.statItem}>
                {opponentLevel === 1 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_ONE}
                    alt="Level 1"
                  />
                )}
                {opponentLevel === 2 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_TWO}
                    alt="Level 2"
                  />
                )}
                {opponentLevel === 3 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_THREE}
                    alt="Level 3"
                  />
                )}
              </div>
              <div className={styles.statItem}>
                XP: {opponentXP}/{xpCaps[opponentLevel]}
              </div>
              {Object.entries(opponentResources).map(([faction, val]) => (
                <div
                  key={faction}
                  className={styles.resourceItem}>
                  <img
                    className={styles.factionIcon}
                    src={factionIcons[faction]}
                    alt={faction}
                  />
                  {val.current}/{val.max}
                </div>
              ))}
            </div>
            <span>
              <button onClick={forfeitGame}>Forfeit Game</button> VS:{" "}
              {opponentName}
            </span>
          </div>
          <div className={styles.boards}>
            <div className={styles.opponentBoard}>
              {[...opponentBoard].reverse().map((slot, idx) => {
                const originalIndex = opponentBoard.length - 1 - idx;
                const { attack, defense, health } = getStatsForBoardCard(slot);
                return (
                  <div
                    key={originalIndex}
                    className={styles.boardSlot}
                    onClick={() =>
                      handleBoardSlotClick(originalIndex, "boardOpponent")
                    }>
                    {slot && (
                      <div
                        className={
                          selectedCardLocation === "boardOpponent" &&
                          selectedCardId === slot.id
                            ? styles.boardCardSelected
                            : styles.boardCard
                        }>
                        <div className={styles.cardTitle}>
                          {cards.find(c => c.key === slot.cardName)?.name}
                        </div>
                        <img
                          src={cards.find(c => c.key === slot.cardName)?.image}
                          alt={slot.cardName}
                          className={styles.cardImage}
                        />
                        <div className={styles.cardStats}>
                          {attack !== 0 && (
                            <div className={styles.attackStat}>
                              <img
                                src={ICON_ATTACK}
                                alt="Attack"
                              />
                              <span>{attack}</span>
                            </div>
                          )}
                          {defense !== 0 && (
                            <div className={styles.defenseStat}>
                              <img
                                src={ICON_DEFENSE}
                                alt="Defense"
                              />
                              <span>{defense}</span>
                            </div>
                          )}
                          {health !== 0 && (
                            <div className={styles.lifeStat}>
                              <img
                                src={ICON_LIFE}
                                alt="Life"
                              />
                              <span>{health}</span>
                            </div>
                          )}
                          {slot.stunRemaining > 0 && (
                            <div className={styles.stunStat}>
                              <img
                                src={ICON_STUN}
                                alt="Stun"
                              />
                              <span>{slot.stunRemaining}</span>
                            </div>
                          )}
                          {slot.summoningSickness && (
                            <div className={styles.summonSickStat}>
                              <img
                                src={ICON_SUMMONING_SICKNESS}
                                alt="Summoning Sickness"
                              />
                            </div>
                          )}
                        </div>
                        {damageHealIndicators[slot.id] !== undefined && (
                          <div
                            className={`${styles.damageIndicator} ${
                              damageHealIndicators[slot.id] > 0
                                ? styles.healText
                                : styles.damageText
                            }`}>
                            {damageHealIndicators[slot.id] > 0 ? "+" : ""}
                            {damageHealIndicators[slot.id]}
                          </div>
                        )}
                      </div>
                    )}
                    {!slot &&
                      destroyedIndicators[originalIndex + "_op"] &&
                      damageHealIndicators[
                        destroyedIndicators[originalIndex + "_op"]
                      ] !== undefined && (
                        <div
                          className={`${styles.damageIndicator} ${
                            damageHealIndicators[
                              destroyedIndicators[originalIndex + "_op"]
                            ] > 0
                              ? styles.healText
                              : styles.damageText
                          }`}>
                          {damageHealIndicators[
                            destroyedIndicators[originalIndex + "_op"]
                          ] > 0
                            ? "+"
                            : ""}
                          {
                            damageHealIndicators[
                              destroyedIndicators[originalIndex + "_op"]
                            ]
                          }
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
            <div className={styles.playerBoard}>
              {playerBoard.map((slot, idx) => {
                const { attack, defense, health } = getStatsForBoardCard(slot);
                return (
                  <div
                    key={idx}
                    className={styles.boardSlot}
                    onClick={() => handleBoardSlotClick(idx, "boardPlayer")}>
                    {slot && (
                      <div
                        className={
                          selectedCardLocation === "boardPlayer" &&
                          selectedCardId === slot.id
                            ? styles.boardCardSelected
                            : styles.boardCard
                        }>
                        <div className={styles.cardTitle}>
                          {cards.find(c => c.key === slot.cardName)?.name}
                        </div>
                        <img
                          src={cards.find(c => c.key === slot.cardName)?.image}
                          alt={slot.cardName}
                          className={styles.cardImage}
                        />
                        <div className={styles.cardStats}>
                          {attack !== 0 && (
                            <div className={styles.attackStat}>
                              <img
                                src={ICON_ATTACK}
                                alt="Attack"
                              />
                              <span>{attack}</span>
                            </div>
                          )}
                          {defense !== 0 && (
                            <div className={styles.defenseStat}>
                              <img
                                src={ICON_DEFENSE}
                                alt="Defense"
                              />
                              <span>{defense}</span>
                            </div>
                          )}
                          {health !== 0 && (
                            <div className={styles.lifeStat}>
                              <img
                                src={ICON_LIFE}
                                alt="Life"
                              />
                              <span>{health}</span>
                            </div>
                          )}
                          {slot.stunRemaining > 0 && (
                            <div className={styles.stunStat}>
                              <img
                                src={ICON_STUN}
                                alt="Stun"
                              />
                              <span>{slot.stunRemaining}</span>
                            </div>
                          )}
                          {slot.summoningSickness && (
                            <div className={styles.summonSickStat}>
                              <img
                                src={ICON_SUMMONING_SICKNESS}
                                alt="Summoning Sickness"
                              />
                            </div>
                          )}
                        </div>
                        {damageHealIndicators[slot.id] !== undefined && (
                          <div
                            className={`${styles.damageIndicator} ${
                              damageHealIndicators[slot.id] > 0
                                ? styles.healText
                                : styles.damageText
                            }`}>
                            {damageHealIndicators[slot.id] > 0 ? "+" : ""}
                            {damageHealIndicators[slot.id]}
                          </div>
                        )}
                      </div>
                    )}
                    {!slot &&
                      destroyedIndicators[idx] &&
                      damageHealIndicators[destroyedIndicators[idx]] !==
                        undefined && (
                        <div
                          className={`${styles.damageIndicator} ${
                            damageHealIndicators[destroyedIndicators[idx]] > 0
                              ? styles.healText
                              : styles.damageText
                          }`}>
                          {damageHealIndicators[destroyedIndicators[idx]] > 0
                            ? "+"
                            : ""}
                          {damageHealIndicators[destroyedIndicators[idx]]}
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.playerResourcesRow}>
            {Object.entries(playerResources).map(([faction, val]) => (
              <div
                key={faction}
                className={styles.resourceItem}>
                <img
                  className={styles.factionIcon}
                  src={factionIcons[faction]}
                  alt={faction}
                />
                {val.current}/{val.max}
              </div>
            ))}
          </div>
          <div className={styles.playerHand}>
            <div className={styles.handScroll}>
              {playerHand.map(card => {
                const fullCardData = cards.find(c => c.key === card.cardName);
                let baseCost = 0;
                if (Array.isArray(fullCardData?.cost)) {
                  const costObj = fullCardData.cost.find(
                    c => c.level === playerLevel
                  );
                  baseCost = costObj ? costObj.value : 0;
                } else {
                  baseCost = fullCardData?.cost || 0;
                }
                const totalCost = baseCost + (card.costIncrease || 0);
                const { attack, defense, health } = getStatsForHandCard(
                  card.cardName
                );
                return (
                  <div
                    key={card.id}
                    className={
                      selectedCardLocation === "hand" &&
                      selectedCardId === card.id
                        ? styles.handCardSelected
                        : styles.handCard
                    }
                    onClick={() => handleHandCardClick(card.id)}>
                    <img
                      src={fullCardData?.image}
                      alt={card.cardName}
                      className={styles.cardImage}
                    />
                    <div className={styles.handCardOverlay}>
                      <div className={styles.handCardTitle}>
                        {fullCardData?.name}
                      </div>
                      <div className={styles.handCardCost}>
                        <span>{totalCost}</span>
                        <img
                          src={factionIcons[fullCardData?.faction]}
                          alt={fullCardData?.faction}
                          className={styles.cardFactionIcon}
                        />
                      </div>
                    </div>
                    <div className={styles.cardStats}>
                      {attack !== 0 && (
                        <div className={styles.attackStat}>
                          <img
                            src={ICON_ATTACK}
                            alt="Attack"
                          />
                          <span>{attack}</span>
                        </div>
                      )}
                      {defense !== 0 && (
                        <div className={styles.defenseStat}>
                          <img
                            src={ICON_DEFENSE}
                            alt="Defense"
                          />
                          <span>{defense}</span>
                        </div>
                      )}
                      {health !== 0 && (
                        <div className={styles.lifeStat}>
                          <img
                            src={ICON_LIFE}
                            alt="Life"
                          />
                          <span>{health}</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.playerInfo}>
            <div className={styles.statsRow}>
              <div className={styles.statItem}>
                <img
                  src={ICON_LIFE}
                  alt="life"
                  className={styles.factionIcon}
                />
                {playerLife}
                {damageHealIndicators["playerLife"] !== undefined && (
                  <div
                    className={`${styles.damageIndicator} ${
                      styles.playerLifeIndicator
                    } ${
                      damageHealIndicators["playerLife"] > 0
                        ? styles.healText
                        : styles.damageText
                    }`}>
                    {damageHealIndicators["playerLife"] > 0 ? "+" : ""}
                    {damageHealIndicators["playerLife"]}
                  </div>
                )}
              </div>
              <div className={styles.statItem}>
                {playerLevel === 1 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_ONE}
                    alt="Level 1"
                  />
                )}
                {playerLevel === 2 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_TWO}
                    alt="Level 2"
                  />
                )}
                {playerLevel === 3 && (
                  <img
                    className={styles.factionIcon}
                    src={ICON_LEVEL_THREE}
                    alt="Level 3"
                  />
                )}
              </div>
              <div className={styles.statItem}>
                XP: {playerXP}/{xpCaps[playerLevel]}
              </div>
              <div className={styles.statItem}>
                <img
                  src={ICON_ACTION}
                  alt="action"
                  className={styles.factionIcon}
                />
                {playerActionPoints}
              </div>
            </div>
            <div
              className={styles.actionButtons}
              ref={actionButtonsRef}>
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_END_TURN})` }}
                onMouseEnter={e => showTooltip(e, "End your turn")}
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleEndTurn}
                disabled={!isPlayerTurn}
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_DISCARD_PILE})` }}
                onMouseEnter={e => showTooltip(e, "Examine your discard pile")}
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={openDiscardModal}
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_RESOURCES})` }}
                onMouseEnter={e => showTooltip(e, "Gain a random resource")}
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleChooseResource}
                disabled={!isPlayerTurn || playerActionPoints < 1}
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_DISCARD_FOR_RESOURCE})` }}
                onMouseEnter={e =>
                  showTooltip(e, "Discard card for 1 resource from its faction")
                }
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleDiscardForResource}
                disabled={
                  !isPlayerTurn ||
                  !selectedCardId ||
                  selectedCardLocation !== "hand" ||
                  playerActionPoints <= 0
                }
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_EXAMINE})` }}
                onMouseEnter={e => showTooltip(e, "Examine selected card")}
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleExamineCard}
                disabled={!selectedCardId}
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_RECYCLE})` }}
                onMouseEnter={e =>
                  showTooltip(e, "Return all cards from your discard pile")
                }
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleRecycle}
                disabled={
                  !isPlayerTurn ||
                  playerActionPoints < 1 ||
                  playerDiscard.length === 0
                }
              />
              <button
                className={styles.resourceButton}
                style={{ backgroundImage: `url(${ICON_LEVEL_UP})` }}
                onMouseEnter={e => showTooltip(e, "Level up")}
                onMouseLeave={hideTooltip}
                onTouchEnd={hideTooltip}
                onClick={handleLevelUp}
                disabled={!isPlayerTurn || !canLevelUp}
              />
            </div>
          </div>
        </div>
        {isDiscardModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <button
                className={styles.closeButton}
                onClick={closeDiscardModal}>
                Close
              </button>
              <div className={styles.playerHand}>
                <div className={styles.handScroll}>
                  {playerDiscard.map(card => {
                    const fullCardData = cards.find(
                      c => c.key === card.cardName
                    );
                    const totalCost = getCostForDiscardCard(card);
                    const { attack, defense, health } =
                      getStatsForDiscardCard(card);
                    return (
                      <div
                        key={card.id}
                        className={styles.handCard}>
                        <img
                          src={fullCardData?.image}
                          alt={card.cardName}
                          className={styles.cardImage}
                        />
                        <div className={styles.handCardOverlay}>
                          <div className={styles.handCardTitle}>
                            {fullCardData?.name}
                          </div>
                          <div className={styles.handCardCost}>
                            <span>{totalCost}</span>
                            <img
                              src={factionIcons[fullCardData?.faction]}
                              alt={fullCardData?.faction}
                              className={styles.cardFactionIcon}
                            />
                          </div>
                        </div>
                        <div className={styles.cardStats}>
                          {attack !== 0 && (
                            <div className={styles.attackStat}>
                              <img
                                src={ICON_ATTACK}
                                alt="Attack"
                              />
                              <span>{attack}</span>
                            </div>
                          )}
                          {defense !== 0 && (
                            <div className={styles.defenseStat}>
                              <img
                                src={ICON_DEFENSE}
                                alt="Defense"
                              />
                              <span>{defense}</span>
                            </div>
                          )}
                          {health !== 0 && (
                            <div className={styles.lifeStat}>
                              <img
                                src={ICON_LIFE}
                                alt="Life"
                              />
                              <span>{health}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {examinedCard && (
          <ExamineCard
            cardData={examinedCard.cardData}
            onClose={() => setExaminedCard(null)}
          />
        )}
        {tooltip.visible && (
          <div
            className={styles.tooltip}
            style={{ left: tooltip.x, top: tooltip.y }}>
            {tooltip.text}
          </div>
        )}
        <LastPlayedCardQueue
          lastPlayedCards={lastPlayedCards}
          setLastPlayedCards={setLastPlayedCards}
          cards={cards}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.duelCards}>
        {menuOption === "MENU" && (
          <div>
            <h2>Main Menu</h2>

            <button onClick={() => setMenuOption("MATCHMAKING")}>
              Matchmaking
            </button>
            <br />
            <br />
            <button onClick={() => setMenuOption("CAMPAIGN")}>Campaign</button>
            <br />
            <br />
            <button onClick={() => setMenuOption("DECKBUILDER")}>
              Deck Builder
            </button>
            <br />
            <br />
            <button onClick={() => setMenuOption("SHOP")}>Card Shop</button>
          </div>
        )}
        {menuOption === "MATCHMAKING" && (
          <Matchmaking
            wins={wins}
            losses={losses}
            ws={ws}
            inGame={inGame}
            isQueueing={isQueueing}
            hasPreviousGame={hasPreviousGame}
            setIsQueueing={setIsQueueing}
            returnToMenu={() => setMenuOption("MENU")}
          />
        )}
        {menuOption === "DECKBUILDER" && (
          <DeckBuilder
            ws={ws}
            cards={cards}
            decks={decks}
            setDecks={setDecks}
            collection={collection}
            gold={gold}
            returnToMenu={() => setMenuOption("MENU")}
          />
        )}
        {menuOption === "SHOP" && (
          <CardShop
            ws={ws}
            gold={gold}
            returnToMenu={() => setMenuOption("MENU")}
          />
        )}
        {menuOption === "CAMPAIGN" && (
          <Campaign
            ws={ws}
            returnToMenu={() => setMenuOption("MENU")}
            campaignStages={campaignStages}
            refreshCampaignStages={refreshCampaignStages}
            hasCampaignGameInProgress={hasCampaignGameInProgress}
          />
        )}
      </div>
    );
  }
}
