import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import styles from "./Castles.module.scss";

export default function Castles() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [assignment, setAssignment] = useState("");
  const [campfire, setCampfire] = useState(0);
  const [campfireHealth, setCampfireHealth] = useState(0);
  const [currentHealth, setCurrentHealth] = useState(100);
  const [maxHealth, setMaxHealth] = useState(100);
  const [inventory, setInventory] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [woodcutting, setWoodcutting] = useState({
    currentProgress: 0,
    requiredProgress: 10,
    actionSpeed: 1000,
  });
  const [mining, setMining] = useState({
    currentProgress: 0,
    requiredProgress: 10,
    actionSpeed: 1000,
  });
  const [gathering, setGathering] = useState({
    currentProgress: 0,
    requiredProgress: 10,
    actionSpeed: 1000,
  });
  const [crafting, setCrafting] = useState({
    currentProgress: 0,
    requiredProgress: 0,
    actionSpeed: 1000,
  });
  const [gold, setGold] = useState(0);
  const [viewTab, setViewTab] = useState(null);
  const [huntData, setHuntData] = useState(null);

  useEffect(() => {
    let ws;
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    switch (environment) {
      case "localhost":
        ws = new WebSocket("ws://localhost:5000/castles");
        break;
      case "localNetwork":
        ws = new WebSocket("ws://192.168.0.7:5000/castles");
        break;
      case "production":
        ws = new WebSocket("wss://2nguyen.dev/castles");
        break;
      default:
        return;
    }
    if (!ws) return;
    setWs(ws);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: "account",
          action: "user",
          user: user.username,
        })
      );
    };

    ws.onclose = () => {
      setWs(null);
    };

    ws.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "update") {
        setAssignment(data.assignment);
        setCampfire(data.campfire || 0);
        setCampfireHealth(data.campfire_health || 0);
        if (data.current_health !== undefined)
          setCurrentHealth(data.current_health);
        if (data.max_health !== undefined) setMaxHealth(data.max_health);
        if (data.inventory) setInventory(data.inventory);
        if (data.woodcutting) setWoodcutting(data.woodcutting);
        if (data.mining) setMining(data.mining);
        if (data.gathering) setGathering(data.gathering);
        if (data.crafting) setCrafting(data.crafting);
        if (data.gold !== undefined) setGold(data.gold);
        if (data.hunt && data.hunt.enemy) {
          setHuntData(data.hunt);
        } else {
          setHuntData(null);
        }
      } else if (data.type === "hunt_result") {
        Swal.fire({
          title: data.title,
          text: data.text,
          icon: data.icon,
          confirmButtonText: "OK",
        });
      }
    };

    return () => {
      if (ws) ws.close();
    };
  }, [user]);

  const assignSkill = resource => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "CASTLES",
        action: "ASSIGN_WORKER",
        resource,
      })
    );
  };

  const buildCampfire = () => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "CASTLES",
        action: "BUILD_CAMPFIRE",
      })
    );
  };

  const getStickCount = () => {
    return inventory.find(i => i.id === "stick")?.quantity || 0;
  };

  const getItemCount = itemId => {
    return inventory.find(i => i.id === itemId)?.quantity || 0;
  };

  const examineItem = item => {
    setSelectedItem(item);
  };

  const handleEquipItem = itemId => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "CASTLES",
        action: "EQUIP_ITEM",
        itemId,
      })
    );
  };

  const handleUnequipItem = itemId => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "CASTLES",
        action: "UNEQUIP_ITEM",
        itemId,
      })
    );
  };

  const craftItem = itemId => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "CASTLES",
        action: "CRAFT_ITEM",
        itemId,
      })
    );
  };

  const handleDropItem = item => {
    if (!ws) return;
    if (item.equippable) {
      Swal.fire({
        title: "Drop Item?",
        text: `Are you sure you want to drop one ${item.name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, drop it",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          ws.send(
            JSON.stringify({
              type: "CASTLES",
              action: "DROP_ITEM",
              itemId: item.id,
            })
          );
        }
      });
    } else {
      ws.send(
        JSON.stringify({
          type: "CASTLES",
          action: "DROP_ITEM",
          itemId: item.id,
        })
      );
    }
  };

  const equipmentItems = inventory.filter(item => item.equippable);
  const inventoryItems = inventory.filter(
    item => !item.equippable && item.quantity > 0
  );

  const getSkillButtonStyle = skill => {
    return assignment === skill ? { backgroundColor: "lightgreen" } : {};
  };

  const getCraftButtonStyle = itemId => {
    return assignment === "crafting" && crafting.inProgressItem === itemId
      ? { backgroundColor: "lightgreen" }
      : {};
  };

  return (
    <div className={styles.container}>
      <div className={styles.skillsSidebar}>
        <div>
          Health: {currentHealth}/{maxHealth}
        </div>
        <div>Gold: {gold}</div>
        <h2>Skills</h2>
        <button
          style={getSkillButtonStyle("woodcutting")}
          onClick={() => assignSkill("woodcutting")}
          className={styles.skillButton}>
          Chop Wood
        </button>
        <button
          style={getSkillButtonStyle("mining")}
          onClick={() => assignSkill("mining")}
          className={styles.skillButton}>
          Mine Stone
        </button>
        <button
          style={getSkillButtonStyle("gathering")}
          onClick={() => assignSkill("gathering")}
          className={styles.skillButton}>
          Gather
        </button>
        <button
          style={getSkillButtonStyle("crafting")}
          onClick={() => assignSkill("crafting")}
          className={styles.skillButton}>
          Crafting
        </button>
        <button
          style={getSkillButtonStyle("hunt")}
          onClick={() => assignSkill("hunt")}
          className={styles.skillButton}>
          Hunt
        </button>
        <button
          onClick={() => setViewTab("inventory")}
          className={styles.skillButton}>
          View Inventory
        </button>
        <button
          onClick={() => setViewTab("equipment")}
          className={styles.skillButton}>
          View Equipment
        </button>
        <button
          onClick={() => setViewTab("structures")}
          className={styles.skillButton}>
          View Structures
        </button>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.main}>
          {assignment === "woodcutting" && (
            <div>
              <h2>Woodcutting</h2>
              <div>
                Progress: {woodcutting.currentProgress}/
                {woodcutting.requiredProgress}
              </div>
            </div>
          )}
          {assignment === "mining" && (
            <div>
              <h2>Mining</h2>
              <div>
                Progress: {mining.currentProgress}/{mining.requiredProgress}
              </div>
            </div>
          )}
          {assignment === "gathering" && (
            <div>
              <h2>Gathering</h2>
              <div>
                Progress: {gathering.currentProgress}/
                {gathering.requiredProgress}
              </div>
            </div>
          )}
          {assignment === "crafting" && (
            <div>
              <h2>Crafting</h2>
              <div>
                Progress: {crafting.currentProgress}/{crafting.requiredProgress}
              </div>
              <div>
                <button
                  style={getCraftButtonStyle("simple_logging_axe")}
                  onClick={() => craftItem("simple_logging_axe")}
                  disabled={
                    getItemCount("stick") < 1 ||
                    getItemCount("stone") < 1 ||
                    getItemCount("fiber") < 1
                  }>
                  Craft Simple Logging Axe (1 Stick, 1 Stone, 1 Fiber)
                </button>
              </div>
              <div>
                <button
                  style={getCraftButtonStyle("simple_pickaxe")}
                  onClick={() => craftItem("simple_pickaxe")}
                  disabled={
                    getItemCount("stick") < 1 ||
                    getItemCount("stone") < 1 ||
                    getItemCount("fiber") < 1
                  }>
                  Craft Simple Pickaxe (1 Stick, 1 Stone, 1 Fiber)
                </button>
              </div>
              <div>
                <button
                  style={getCraftButtonStyle("cloth")}
                  onClick={() => craftItem("cloth")}
                  disabled={getItemCount("fiber") < 5}>
                  Craft Cloth (5 Fiber)
                </button>
              </div>
              <div>
                <button
                  style={getCraftButtonStyle("simple_pouch")}
                  onClick={() => craftItem("simple_pouch")}
                  disabled={getItemCount("cloth") < 5}>
                  Craft Simple Pouch (5 Cloth)
                </button>
              </div>
            </div>
          )}
          {assignment === "rest" && (
            <div>
              <h2>Resting</h2>
              <div>Recovering health at the campfire...</div>
            </div>
          )}
          {assignment === "hunt" && huntData && huntData.enemy && (
            <div>
              <h2>Hunting</h2>
              <div>Fighting a {huntData.enemy.type}...</div>
              <div>
                Enemy Health: {huntData.enemy.health}/{huntData.enemy.maxHealth}
              </div>
            </div>
          )}
        </div>

        {viewTab === "inventory" && (
          <div>
            <h2>
              Inventory (
              {inventory.reduce((sum, item) => sum + (item.quantity || 0), 0)}
              /250)
            </h2>
            <div>
              {inventoryItems.map(item => (
                <button
                  key={item.id}
                  onClick={() => examineItem(item)}>
                  {item.name} x{item.quantity}
                </button>
              ))}
            </div>
          </div>
        )}

        {viewTab === "equipment" && (
          <div>
            <h2>Equipment</h2>
            <div>
              {equipmentItems.map(item => (
                <button
                  key={item.id}
                  onClick={() => examineItem(item)}>
                  {item.name}
                  {item.equipped ? " (equipped)" : ""}
                </button>
              ))}
            </div>
          </div>
        )}

        {viewTab === "structures" && (
          <div>
            <h2>Structures</h2>
            <button
              onClick={buildCampfire}
              disabled={getStickCount() < 10 || campfire >= 1}>
              Build Campfire (10 Sticks)
            </button>
            {campfire >= 1 && (
              <div>
                <div>Campfire (Health: {campfireHealth})</div>
                <button
                  style={getSkillButtonStyle("rest")}
                  onClick={() => assignSkill("rest")}>
                  Rest by Campfire (Recover Health)
                </button>
              </div>
            )}
          </div>
        )}

        {selectedItem && (
          <div>
            <div>{selectedItem.name}</div>
            <div>Item Type: {selectedItem.item_type}</div>
            {selectedItem.action_type && (
              <div>Action Type: {selectedItem.action_type}</div>
            )}
            {selectedItem.power !== undefined && (
              <div>Power: {selectedItem.power}</div>
            )}
            {selectedItem.quantity !== undefined && (
              <div>Quantity: {selectedItem.quantity}</div>
            )}
            {selectedItem.equippable && !selectedItem.equipped && (
              <button onClick={() => handleEquipItem(selectedItem.id)}>
                Equip
              </button>
            )}
            {selectedItem.equippable && selectedItem.equipped && (
              <button onClick={() => handleUnequipItem(selectedItem.id)}>
                Unequip
              </button>
            )}
            <button onClick={() => handleDropItem(selectedItem)}>Drop 1</button>
          </div>
        )}
      </div>
    </div>
  );
}
