import React, { useEffect, useState } from "react";

const PatchNotes = () => {
  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        padding: "0 1rem",
        lineHeight: "1.5",
      }}>
      <h1
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          padding: "1rem 0",
        }}>
        Patch Notes
      </h1>

      <div style={{ paddingBottom: "1rem" }}>
        <p>
          <b> Alpha 1.7.8</b> <br /> - Added a new card for Mechanical, Conduit
          Cannon, a unit whose attack is based on its current energy shield.
        </p>
        <p>
          <b>Alpha 1.7.7</b> <br /> - Added more cards and new effects
        </p>
        <p>
          <b>Alpha 1.7.4</b> <br /> - Added 3 new cards for Nature faction: Mama
          Slime, Blooming Glade, and Return to Earth.
        </p>
        <p>
          <b>Alpha 1.7.3</b> <br /> - Added starter decks for Vanguard and
          Mechanical.
        </p>
        <p>
          <b>Alpha 1.7.2</b> <br /> - Attack telegraphing works much better.
        </p>
        <p>
          <b>Alpha 1.7.0</b> <br /> - More notes to be added later.
        </p>
        <p>
          <b>Alpha 1.6.18</b> <br /> - Added Trumpeter, a Vanguard unit that
          decreases the cooldown of adjacent Vanguard units by 1 until end of
          turn. <br /> - Wiped the DB and added a new starter deck.
        </p>
        <p>
          <b>Alpha 1.6.15</b> <br /> - Added Royal Banner, a Vanguard structure,
          that while on the board, provides a stacking +1 Attack to all allied
          Vanguard units <br /> - Made the discard, card log, and network status
          modals consistent and added a floating button to close them
        </p>
        <p>
          <b>Alpha 1.6.12</b> <br /> - Fixed a bug where the player's status
          message was inaccurately showing them as still in-game <br /> - Began
          working on some architecture to implement an in-game currency
        </p>
        <p>
          <b>Alpha 1.6.11</b> <br /> - Show text on hover for non-text buttons{" "}
          <br /> - Show players queued for a game <br /> - Queueing for a game
          now takes a few seconds to allow multiple people to join and match up
        </p>
        <p>
          <b>Alpha 1.6.9</b> <br /> - Added a couple cards to test effects{" "}
          <br /> - Moved the "connection" messages into their own pop-up modal{" "}
          <br /> - Added buttons for forfeiting the game, examining the discard
          pile, examining the card playing log, and checking the connection
          status of your opponent
        </p>
        <p>
          <b>Alpha 1.6.8</b> <br /> - Implemented Forest's Blessing which heals
          all of the player's Nature units by 1
        </p>
        <p>
          <b>Alpha 1.6.7</b> <br /> - Added a button to play a card that targets
          an entire player's board or opponent's board
        </p>
        <p>
          <b>Alpha 1.6.6</b> <br /> - Created a larger version of the cards for
          the Deck Builder and for the player to examine in game. <br /> Added
          sweetalert2 for better looking alerts.
        </p>
        <p>
          <b>Alpha 1.6.3</b> <br /> - Added an explosion animation to attacked
          units/crystals <br /> - Added damage numbers to attacked units
        </p>
        <p>
          <b>Alpha 1.6.2</b> <br /> - Added profanity filter to the lobby chat
        </p>
        <p>
          <b>Alpha 1.6.1</b> <br /> - Added a "in game" status indicator next to
          player's names in the lobby <br /> - Added a new card: "Entangling
          Vines" which prevents a unit from moving for 3 rounds <br /> Add unit
          image for "Hammerguard"
        </p>
        <p>
          Alpha 1.6.0 <br /> - Added a new card: "Call of the Wild" which
          searches and draws a random Nature Unit card from the player's deck.{" "}
          <br /> Fixed a server crashing bug with deck saving.
        </p>
        <p>
          <b>Alpha 1.5.21</b> <br /> - Added Arbalest Ranger unit and card
          images
        </p>
        <p>
          <b>Alpha 1.5.20</b> <br /> - Added Brawncoli unit image and card image{" "}
          <br /> - Changed the color of the cards in the Library to match their
          resource type
        </p>
        <p>
          <b>Alpha 1.5.19</b> <br /> - Added Brawncoli, a Nature unit that
          targets the backline first
        </p>
        <p>
          <b>Alpha 1.5.18</b> <br /> - Fixed a bug where a reconnecting player
          did not have an accurate status of their turn or if they had used
          their sacrifice <br /> - Added a unit sprite for mushroom spearmen
        </p>
        <p>
          <b>Alpha 1.5.17</b> <br /> - Add an alert when player tries to play a
          card they can't afford <br /> - Clear invalid selected cells when
          using multi-target event cards <br /> - Added a color indicator for
          selected multi-target cells
        </p>
        <p>
          <b>Alpha 1.5.16</b> <br /> - Added unit images for Hammerguard, Steel
          Neil, and Warframe <br /> - Added a Plains background to the arena
        </p>
        <p>
          <b>Alpha 1.5.15</b> <br /> - Added chat to the lobby. <br /> - Added a
          unit image for Warhound. <br /> - Reworked the Deck Builder UI
        </p>
        <p>
          <b>Alpha 1.5.11</b> <br /> - Added ability to delete decks (cannot
          delete active decks or your last deck).
        </p>
        <p>
          <b>Alpha 1.5.10</b> <br /> - Added a unit image for Axe Man.
        </p>
        <p>
          <b>Alpha 1.5.9</b> <br /> - Added a unit image for Wisp Beast <br />-
          Added the name of the card onto the card
        </p>
        <p>
          <b>Alpha 1.5.8</b> <br /> - Fixed players not having an accurate list
          of online players
        </p>
        <p>
          <b>Alpha 1.5.7</b> <br />- Fixed players being timed out after about
          30 seconds and not being able to perform any online actions (saving
          deck, starting a game, etc.)
        </p>
        <p>
          <b>Alpha 1.5.6</b> <br /> - Added a Parchment logo for the game
        </p>
        <p>
          <b>Alpha 1.5.5</b> <br /> - Add pop up notifications for the Deck
          Building actions.
        </p>
        <p>
          <b>Alpha 1.5.4</b> <br /> - Highlight valid hexes where
          units/structures can be placed.
        </p>
        <p>
          <b>Alpha 1.5.3</b> <br /> - Added the card's effect description (if it
          has one), otherwise, the card's flavor text.
        </p>
        <p>
          <b>Alpha 1.5.2</b> <br /> - Added a custom font (RulerGold).
        </p>
        <p>
          <b>Alpha 1.5.1</b> <br /> - Added some faction icons from Diahborne.
        </p>
        <p>
          <b>Alpha 1.5.0</b>
          <br /> - Styling updates, such as symbols/icons and text in-game.
        </p>
        <p>
          <b>Alpha 1.4.0</b> <br /> - Fixed a bug with some Event cards
          destroying a card no matter what the damage amount.
        </p>

        <p>
          <b>Alpha 1.3.0</b> <br /> - Added unit image for Rocky.
        </p>
        <p>
          <b>Alpha 1.2.0</b> <br /> - Added filtering to the Deck Builder.
        </p>
        <p>
          <b>Alpha 1.1.0</b>
          <br /> - Implemented effects from cards that have effects.
        </p>
        <p>
          <b>Alpha 1.0.0</b> <br />- Basic implementation of the game.
        </p>
      </div>
    </div>
  );
};

export default PatchNotes;
