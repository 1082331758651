import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import PlantExchangeMarket from "./PlantExchangeMarket";

function PlantExchange() {
  const user = useSelector(store => store.user);
  const [globalScore, setGlobalScore] = useState(0);
  const [spaces, setSpaces] = useState(
    Array.from({ length: 1 }, () => ({
      started: false,
      seed: null,
      growTime: 0,
      effect: null,
    }))
  );
  const [inventory, setInventory] = useState([]);
  const [selectedSeed, setSelectedSeed] = useState(null);
  const [description, setDescription] = useState("");
  const [ws, setWs] = useState(null);
  const [removeMode, setRemoveMode] = useState(false);
  const [plusQualityGrowTimeMode, setPlusQualityGrowTimeMode] = useState(false);
  const [showMarketModal, setShowMarketModal] = useState(false);
  const [marketPrice, setMarketPrice] = useState("");
  const [seedToMarket, setSeedToMarket] = useState(null);
  const [market, setMarket] = useState([]);
  const [maxInventory, setMaxInventory] = useState(10);

  useEffect(() => {
    let socket;
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    switch (environment) {
      case "localhost":
        socket = new WebSocket("ws://localhost:5000/plant-exchange");
        break;
      case "localNetwork":
        socket = new WebSocket("ws://192.168.0.7:5000/plant-exchange");
        break;
      case "production":
        socket = new WebSocket("wss://2nguyen.dev/plant-exchange");
        break;
      default:
        return;
    }
    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          type: "initialize",
          userId: user?.id,
          username: user?.username,
        })
      );
      setWs(socket);
    };
    socket.onclose = () => {
      setWs(null);
    };
    socket.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "state") {
        setGlobalScore(data.globalScore);
        setSpaces(data.spaces);
        setInventory(data.inventory || []);
        if (data.market) {
          setMarket(data.market);
        }
        if (data.maxInventory) {
          setMaxInventory(data.maxInventory);
        }
      }
      if (data.type === "inventoryFull") {
        Swal.fire("Your inventory is full. You cannot harvest right now.");
      }
    };
    return () => {
      socket && socket.close();
    };
  }, [user]);

  const handleSpaceClick = (space, index) => {
    if (!ws) return;
    if (removeMode && space.started) {
      if (space.seed && space.growTime < space.seed.requiredGrowTime) {
        Swal.fire({
          title: "Plant not fully grown",
          text: "Removing this plant now will not harvest any seeds. Continue?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            ws.send(JSON.stringify({ type: "removePlant", index }));
            setDescription("");
          }
        });
      } else {
        ws.send(JSON.stringify({ type: "removePlant", index }));
        setDescription("");
      }
      return;
    }
    if (!space.started) {
      setDescription("");
      if (plusQualityGrowTimeMode) {
        ws.send(JSON.stringify({ type: "applySpaceEffect", index }));
        setPlusQualityGrowTimeMode(false);
      } else if (selectedSeed) {
        ws.send(JSON.stringify({ type: "toggleSpace", index, selectedSeed }));
        setSelectedSeed(null);
      }
    } else {
      if (space.seed && space.growTime >= space.seed.requiredGrowTime) {
        if (inventory.length >= maxInventory) {
          Swal.fire("Your inventory is full. You cannot harvest right now.");
          return;
        }
        ws.send(JSON.stringify({ type: "toggleSpace", index }));
        setDescription("");
      } else {
        if (space.seed) {
          setDescription(
            `Seed: ${space.seed.name} | Quality: ${space.seed.quality} | Grow Time: ${space.growTime}/${space.seed.requiredGrowTime} | Harvest: ${space.seed.seedHarvestAmount}`
          );
        }
      }
    }
  };

  const handleToggleSpace = index => {
    const space = spaces[index];
    handleSpaceClick(space, index);
  };

  const handleBuySeed = () => {
    if (!ws) return;
    if (inventory.length >= maxInventory) {
      Swal.fire("Your inventory is full. You cannot buy a new seed right now.");
      return;
    }
    ws.send(JSON.stringify({ type: "buySeed" }));
  };

  const handleSelectSeed = seed => {
    if (selectedSeed && selectedSeed.id === seed.id) {
      setSelectedSeed(null);
      setDescription("");
    } else {
      setSelectedSeed(seed);
      setDescription(
        `Name: ${seed.name} | Common Name: ${seed.commonName} | Quality: ${seed.quality} | Grow Time: ${seed.requiredGrowTime} | Harvest: ${seed.seedHarvestAmount}`
      );
      if (removeMode) {
        setRemoveMode(false);
      }
    }
  };

  const handleRemoveMode = () => {
    setRemoveMode(!removeMode);
    setSelectedSeed(null);
    setDescription("");
    setPlusQualityGrowTimeMode(false);
  };

  const handlePlusQualityGrowTimeMode = () => {
    setPlusQualityGrowTimeMode(!plusQualityGrowTimeMode);
    setRemoveMode(false);
    setSelectedSeed(null);
    setDescription("");
  };

  const handleSellInstantly = seed => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "sellSeedInstantly", seedId: seed.id }));
    setDescription("");
  };

  const handleAddToMarket = seed => {
    setSeedToMarket(seed);
    setMarketPrice("");
    setShowMarketModal(true);
  };

  const confirmAddToMarket = () => {
    if (!ws || !seedToMarket || !marketPrice) return;
    ws.send(
      JSON.stringify({
        type: "addSeedToMarket",
        seedId: seedToMarket.id,
        price: parseInt(marketPrice, 10),
      })
    );
    setShowMarketModal(false);
    setMarketPrice("");
    setSeedToMarket(null);
  };

  const closeMarketModal = () => {
    setShowMarketModal(false);
    setMarketPrice("");
    setSeedToMarket(null);
  };

  const handleBuyMarketSeed = listingId => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "buySeedFromMarket", listingId }));
  };

  const handleBuySpace = () => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "buySpace" }));
  };

  const getInventorySlotCost = () => {
    if (maxInventory < 20) {
      return maxInventory * 10;
    } else if (maxInventory < 50) {
      return maxInventory * 25;
    } else {
      return maxInventory * 50;
    }
  };

  const handleBuyInventorySlot = () => {
    if (!ws) return;
    ws.send(JSON.stringify({ type: "buyInventorySlot" }));
  };

  return (
    <div>
      <h1>$$$:{globalScore}</h1>
      <h2>
        Inventory: {inventory.length}/{maxInventory}
      </h2>
      <button
        style={{ backgroundColor: "gold" }}
        onClick={handleBuySeed}>
        Buy Random Seed (100 pts)
      </button>
      <button
        onClick={handleRemoveMode}
        style={{
          border: removeMode ? "" : "2px solid red",
          backgroundColor: removeMode ? "red" : "",
          marginLeft: "10px",
        }}>
        {removeMode ? "Cancel Remove" : "Harvest and Remove Plant"}
      </button>
      <button
        onClick={handleBuySpace}
        style={{ backgroundColor: "lightgreen", marginLeft: "10px" }}>
        Buy Additional Space ({50 * spaces.length} pts)
      </button>
      <button
        onClick={handlePlusQualityGrowTimeMode}
        style={{
          backgroundColor: plusQualityGrowTimeMode ? "#ffa500" : "",
          marginLeft: "10px",
        }}>
        +/-Quality +GrowTime (50 pts)
      </button>
      <button
        onClick={handleBuyInventorySlot}
        style={{ backgroundColor: "#add8e6", marginLeft: "10px" }}>
        Buy +1 Inventory Slot ({getInventorySlotCost()} pts)
      </button>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "10px",
          marginTop: "20px",
        }}>
        {spaces.map((space, index) => {
          let progress = 0;
          if (space.seed) {
            progress = Math.floor(
              (space.growTime / space.seed.requiredGrowTime) * 100
            );
            if (progress > 100) {
              progress = 100;
            }
          }
          return (
            <button
              key={index}
              onClick={() => handleToggleSpace(index)}
              style={{
                position: "relative",
                width: "100%",
                height: "80px",
                overflow: "hidden",
                backgroundColor: "gray",
                color: "black",
                border:
                  space.started && space.seed && progress === 100
                    ? "4px solid white"
                    : "none",
              }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: space.started && space.seed ? `${progress}%` : "0%",
                  backgroundColor:
                    space.started && space.seed
                      ? space.seed.hexColor
                      : "transparent",
                  transition: "height 0.3s ease",
                }}
              />
              <span
                style={{
                  position: "relative",
                  zIndex: 1,
                  textAlign: "center",
                  display: "block",
                  width: "100%",
                }}>
                {space.started
                  ? `${space.seed.name} (${space.seed.quality})`
                  : space.effect === "plusQualityGrowTime"
                  ? "+Quality +Growtime"
                  : "Empty"}
              </span>
            </button>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          height: "200px",
          overflowY: "scroll",
          border: "1px solid black",
          padding: "10px",
          marginTop: "20px",
        }}>
        {inventory.map(seed => (
          <div
            key={seed.id}
            style={{
              border:
                selectedSeed && selectedSeed.id === seed.id
                  ? "4px solid white"
                  : "1px solid gray",
              padding: "10px",
              margin: "5px 0",
              backgroundColor: seed.hexColor,
              color: "black",
            }}>
            <div
              style={{ cursor: "pointer", marginBottom: "5px" }}
              onClick={() => handleSelectSeed(seed)}>
              {`${seed.name} | Quality: ${seed.quality} | Grow Time: ${seed.requiredGrowTime} | Harvest: ${seed.seedHarvestAmount}`}
              {" | "}
              <button onClick={() => handleSellInstantly(seed)}>
                Sell Instantly (${seed.quality > 0 ? seed.quality : 1})
              </button>
              <button onClick={() => handleAddToMarket(seed)}>
                Add to Market
              </button>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid black",
          padding: "10px",
          minHeight: "50px",
        }}>
        {description}
      </div>
      {showMarketModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 999,
            color: "black",
          }}>
          <h3>Set Price</h3>
          <input
            type="number"
            value={marketPrice}
            onChange={e => setMarketPrice(e.target.value)}
          />
          <div style={{ marginTop: "10px" }}>
            <button onClick={confirmAddToMarket}>Confirm</button>
            <button onClick={closeMarketModal}>Cancel</button>
          </div>
        </div>
      )}
      <PlantExchangeMarket
        market={market}
        globalScore={globalScore}
        onBuyMarketSeed={handleBuyMarketSeed}
      />
    </div>
  );
}

export default PlantExchange;
