import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CREATURE_RANDOM from "./assets/CREATURE_RANDOM.svg";
import CREATURE_UNKNOWN from "./assets/CREATURE_UNKNOWN.svg";
import UNHATCHED_EGG from "./assets/UNHATCHED_EGG.svg";
import ICON_GOLD from "./assets/ICON_GOLD.svg";
import ICON_LEVEL from "./assets/ICON_LEVEL.svg";
import ICON_SACK from "./assets/ICON_SACK.svg";
import ICON_ESSENCE from "./assets/ICON_ESSENCE.svg";
import ICON_LIMIT_DUST from "./assets/ICON_LIMIT_DUST.svg";
import ICON_FLAME from "./assets/ICON_FLAME.svg";
import ICON_FRUIT from "./assets/ICON_FRUIT.svg";
import ICON_ICE from "./assets/ICON_ICE.svg";
import ICON_MUSHROOM from "./assets/ICON_MUSHROOM.svg";
import ICON_SAND from "./assets/ICON_SAND.svg";
import styles from "./DungenEnjoyers.module.scss";
import getRoomDescription from "./getRoomDescription";
import CreatureStats from "./CreatureStats";
import { creatureImages } from "./creatureImages";
import { defaultRooms, defaultUnlockedRooms } from "./rooms";

function CreatureBox({
  creature,
  isSelected,
  onSelect,
  small,
  statsMinimized,
  setStatsMinimized,
  attackFlash,
}) {
  const user = useSelector(store => store.user);
  if (!creature) return null;

  const src = creature.isEgg
    ? UNHATCHED_EGG
    : creatureImages[creature.type] || CREATURE_UNKNOWN;

  const boxClass = small
    ? `${styles.creatureBox} ${styles.smallBox}`
    : styles.creatureBox;

  function handleBuy(e) {
    e.stopPropagation();
    if (!window.dungeonEnjoyersSocket) return;
    if (creature.ownerId === user.id) return;
    if (creature.room !== "Market") return;
    if (creature.marketPrice <= 0) return;
    window.dungeonEnjoyersSocket.send(
      JSON.stringify({
        type: "BUY_CREATURE_FROM_MARKET",
        buyerId: user.id,
        creatureUuid: creature.uuid,
      })
    );
  }

  let displayStyle = {
    backgroundColor: creature.color || "#ffffff",
    WebkitMaskImage: `url(${src})`,
    WebkitMaskSize: "contain",
    WebkitMaskRepeat: "no-repeat",
    WebkitMaskPosition: "center",
    maskImage: `url(${src})`,
    maskSize: "contain",
    maskRepeat: "no-repeat",
    maskPosition: "center",
  };

  const healthPercent =
    creature.maximumHealth > 0
      ? Math.max(
          0,
          Math.min(100, (creature.currentHealth / creature.maximumHealth) * 100)
        )
      : 0;

  const manaPercent =
    creature.maximumMana > 0
      ? Math.max(
          0,
          Math.min(100, (creature.currentMana / creature.maximumMana) * 100)
        )
      : 0;

  return (
    <div
      className={`${boxClass} ${isSelected ? styles.selected : ""} ${
        attackFlash ? styles.attackFlash : ""
      }`}
      onClick={e => {
        e.stopPropagation();
        onSelect(creature.uuid);
      }}
      style={{ position: "relative" }}>
      <div className={styles.healthBar}>
        <div
          className={styles.healthBarFill}
          style={{ height: `${healthPercent}%` }}
        />
      </div>
      <div className={styles.manaBar}>
        <div
          className={styles.manaBarFill}
          style={{ height: `${manaPercent}%` }}
        />
      </div>
      <div
        className={styles.creatureDisplay}
        style={displayStyle}
      />
      <div className={styles.creatureLevel}>{creature.level}</div>
      {isSelected && (
        <CreatureStats
          creature={creature}
          statsMinimized={statsMinimized}
          setStatsMinimized={setStatsMinimized}
          user={user}
          handleBuy={handleBuy}
        />
      )}
    </div>
  );
}

function PairContainer({
  creatureA,
  creatureB,
  isSelected,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  attackHighlights,
  separator,
}) {
  return (
    <div className={styles.arenaMatchContainer}>
      <CreatureBox
        creature={creatureA}
        small
        isSelected={isSelected === creatureA?.uuid}
        onSelect={onSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        attackFlash={!!attackHighlights[creatureA?.uuid]}
      />
      <div className={styles.separator}>{separator}</div>
      {creatureB ? (
        <CreatureBox
          creature={creatureB}
          small
          isSelected={isSelected === creatureB?.uuid}
          onSelect={onSelect}
          statsMinimized={statsMinimized}
          setStatsMinimized={setStatsMinimized}
          attackFlash={!!attackHighlights[creatureB?.uuid]}
        />
      ) : (
        <div
          className={`${styles.creatureBox} ${styles.smallBox}`}
          style={{ opacity: 0.5 }}>
          <img
            src={CREATURE_UNKNOWN}
            alt="partner"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      )}
    </div>
  );
}

function renderPairedCreatures({
  creatures,
  partnerField,
  selectedCreatureUuid,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  attackHighlights,
  separator,
}) {
  const list = creatures.filter(
    c => c.room === "Arena" || c.room === "Breeding" || c.isOpponent
  );
  const visited = new Set();
  const result = [];

  list.forEach(creature => {
    if (visited.has(creature.uuid)) return;
    if (!creature[partnerField]) {
      result.push(
        <PairContainer
          key={creature.uuid}
          creatureA={creature}
          creatureB={null}
          isSelected={selectedCreatureUuid}
          onSelect={onSelect}
          statsMinimized={statsMinimized}
          setStatsMinimized={setStatsMinimized}
          attackHighlights={attackHighlights}
          separator={separator}
        />
      );
      visited.add(creature.uuid);
    } else {
      const partner = list.find(c => c.uuid === creature[partnerField]);
      if (!partner) {
        result.push(
          <PairContainer
            key={creature.uuid}
            creatureA={creature}
            creatureB={null}
            isSelected={selectedCreatureUuid}
            onSelect={onSelect}
            statsMinimized={statsMinimized}
            setStatsMinimized={setStatsMinimized}
            attackHighlights={attackHighlights}
            separator={separator}
          />
        );
        visited.add(creature.uuid);
      } else {
        const pairKey = creature.uuid + "_" + partner.uuid;
        if (!visited.has(creature.uuid) && !visited.has(partner.uuid)) {
          result.push(
            <PairContainer
              key={pairKey}
              creatureA={creature}
              creatureB={partner}
              isSelected={selectedCreatureUuid}
              onSelect={onSelect}
              statsMinimized={statsMinimized}
              setStatsMinimized={setStatsMinimized}
              attackHighlights={attackHighlights}
              separator={separator}
            />
          );
          visited.add(creature.uuid);
          visited.add(partner.uuid);
        }
      }
    }
  });
  return result;
}

function renderArenaCreatures(
  creatures,
  selectedCreatureUuid,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  attackHighlights
) {
  return renderPairedCreatures({
    creatures,
    partnerField: "arenaOpponent",
    selectedCreatureUuid,
    onSelect,
    statsMinimized,
    setStatsMinimized,
    attackHighlights,
    separator: "VS",
  });
}

function renderBreedingCreatures(
  creatures,
  selectedCreatureUuid,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  attackHighlights
) {
  return renderPairedCreatures({
    creatures,
    partnerField: "breedingPartner",
    selectedCreatureUuid,
    onSelect,
    statsMinimized,
    setStatsMinimized,
    attackHighlights,
    separator: "+",
  });
}

function renderQuestCreatures(
  creatures,
  selectedCreatureUuid,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  attackHighlights,
  helpWithQuest
) {
  const questMap = new Map();
  creatures.forEach(creature => {
    if (creature.questId) {
      if (!questMap.has(creature.questId)) {
        questMap.set(creature.questId, []);
      }
      questMap.get(creature.questId).push(creature);
    }
  });
  const result = [];
  for (const [questId, group] of questMap.entries()) {
    const groupKey = "quest_group_" + questId;
    const firstCreature = group[0];
    let currentProgress = 0;
    let requiredProgress = 1;
    if (firstCreature.quest) {
      currentProgress = firstCreature.quest.currentProgress;
      requiredProgress = firstCreature.quest.requiredProgress || 1;
    }
    const questPercent = Math.floor((currentProgress / requiredProgress) * 100);

    result.push(
      <div
        key={groupKey}
        className={styles.questBox}
        onClick={e => {
          e.stopPropagation();
          if (selectedCreatureUuid) {
            helpWithQuest(questId);
          }
        }}>
        <div className={styles.questBoxContent}>
          <div className={styles.verticalProgressBar}>
            <div
              className={styles.verticalProgressFillGreen}
              style={{ height: `${questPercent}%` }}
            />
          </div>
          {group.length === 1 ? (
            <CreatureBox
              key={group[0].uuid}
              creature={group[0]}
              isSelected={selectedCreatureUuid === group[0].uuid}
              onSelect={onSelect}
              statsMinimized={statsMinimized}
              setStatsMinimized={setStatsMinimized}
              attackFlash={false}
            />
          ) : (
            <div style={{ display: "flex", gap: "5px" }}>
              {group.map(c => (
                <CreatureBox
                  key={c.uuid}
                  creature={c}
                  small
                  isSelected={selectedCreatureUuid === c.uuid}
                  onSelect={onSelect}
                  statsMinimized={statsMinimized}
                  setStatsMinimized={setStatsMinimized}
                  attackFlash={false}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  const noQuest = creatures.filter(c => !c.questId);
  noQuest.forEach(c => {
    result.push(
      <CreatureBox
        key={c.uuid}
        creature={c}
        isSelected={selectedCreatureUuid === c.uuid}
        onSelect={onSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        attackFlash={false}
      />
    );
  });
  return result;
}

function ProgressAssignBox({
  item,
  selectedCreatureUuid,
  onAssignCreature,
  assignedCreatures,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  selectedItemId,
  setSelectedItemId,
  image,
  getTitle,
  getProgressPercent,
  getTooltipContent,
  showLevel,
  levelValue,
}) {
  const showTooltip = selectedItemId === item.id;
  const progressPercent = getProgressPercent(item);

  function handleClick(e) {
    e.stopPropagation();
    if (selectedCreatureUuid) {
      onAssignCreature(item.id);
      setSelectedItemId(null);
    } else {
      if (showTooltip) {
        setSelectedItemId(null);
      } else {
        setSelectedItemId(item.id);
      }
    }
  }

  return (
    <div className={styles.progressAssignBox}>
      <div
        className={styles.assignBoxHeader}
        onClick={handleClick}>
        <div className={styles.verticalProgressBar}>
          <div
            className={styles.verticalProgressFillGreen}
            style={{ height: `${progressPercent}%` }}
          />
        </div>
        <div
          style={{
            border: "1px solid white",
            borderRadius: 5,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}>
          <img
            src={image}
            alt="progress"
            style={{ width: 30, height: 30 }}
          />
          {showLevel && (
            <div className={styles.itemLevelLabel}>LV{levelValue}</div>
          )}
          {showTooltip && !selectedCreatureUuid && (
            <div className={styles.itemStats}>{getTooltipContent(item)}</div>
          )}
        </div>
      </div>
      <div className={styles.assignBoxCreatures}>
        {assignedCreatures.map(creature => (
          <CreatureBox
            key={creature.uuid}
            creature={creature}
            small
            isSelected={selectedCreatureUuid === creature.uuid}
            onSelect={onSelect}
            statsMinimized={statsMinimized}
            setStatsMinimized={setStatsMinimized}
            attackFlash={false}
          />
        ))}
      </div>
    </div>
  );
}

function TaskBox({
  task,
  selectedCreatureUuid,
  onAssignCreature,
  assignedCreatures,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  selectedTaskId,
  setSelectedTaskId,
}) {
  return (
    <ProgressAssignBox
      item={task}
      selectedCreatureUuid={selectedCreatureUuid}
      onAssignCreature={onAssignCreature}
      assignedCreatures={assignedCreatures}
      onSelect={onSelect}
      statsMinimized={statsMinimized}
      setStatsMinimized={setStatsMinimized}
      selectedItemId={selectedTaskId}
      setSelectedItemId={setSelectedTaskId}
      image={CREATURE_UNKNOWN}
      getTitle={t => t.name}
      getProgressPercent={t =>
        Math.floor((t.currentProgress / t.requiredProgress) * 100)
      }
      getTooltipContent={t =>
        `${t.name}\nProgress: ${t.currentProgress}/${t.requiredProgress}`
      }
      showLevel={false}
      levelValue={0}
    />
  );
}

function InitiativeBox({
  initiative,
  selectedCreatureUuid,
  onAssignCreature,
  assignedCreatures,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  selectedInitiativeId,
  setSelectedInitiativeId,
}) {
  return (
    <ProgressAssignBox
      item={initiative}
      selectedCreatureUuid={selectedCreatureUuid}
      onAssignCreature={onAssignCreature}
      assignedCreatures={assignedCreatures}
      onSelect={onSelect}
      statsMinimized={statsMinimized}
      setStatsMinimized={setStatsMinimized}
      selectedItemId={selectedInitiativeId}
      setSelectedItemId={setSelectedInitiativeId}
      image={CREATURE_UNKNOWN}
      getTitle={i => i.name}
      getProgressPercent={i =>
        Math.floor((i.currentProgress / i.requiredProgress) * 100)
      }
      getTooltipContent={i =>
        `${i.name}\n\n${i.effect}\n\nProgress: ${i.currentProgress}/${i.requiredProgress}`
      }
      showLevel={true}
      levelValue={initiative.level}
    />
  );
}

function ZoneBox({
  zoneId,
  zoneData,
  zoneIcon,
  zoneTitle,
  zoneProgress,
  selectedCreatureUuid,
  onAssignCreature,
  assignedCreatures,
  onSelect,
  statsMinimized,
  setStatsMinimized,
  selectedZoneId,
  setSelectedZoneId,
}) {
  const progressPercent = Math.floor((zoneProgress / 100) * 100);
  const showTooltip = selectedZoneId === zoneId;

  function handleClick(e) {
    e.stopPropagation();
    if (selectedCreatureUuid) {
      onAssignCreature(zoneId);
      setSelectedZoneId(null);
    } else {
      if (showTooltip) {
        setSelectedZoneId(null);
      } else {
        setSelectedZoneId(zoneId);
      }
    }
  }

  return (
    <div className={styles.progressAssignBox}>
      <div
        className={styles.assignBoxHeader}
        onClick={handleClick}>
        <div className={styles.verticalProgressBar}>
          <div
            className={styles.verticalProgressFillGreen}
            style={{ height: `${progressPercent}%` }}
          />
        </div>
        <div
          style={{
            border: "1px solid white",
            borderRadius: 5,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}>
          <img
            src={zoneIcon}
            alt="zone"
            style={{ width: 30, height: 30 }}
          />
          {showTooltip && !selectedCreatureUuid && (
            <div className={styles.itemStats}>
              {zoneTitle}
              <br />
              Progress: {zoneProgress}/100
            </div>
          )}
        </div>
      </div>
      <div className={styles.assignBoxCreatures}>
        {assignedCreatures.map(creature => (
          <CreatureBox
            key={creature.uuid}
            creature={creature}
            small
            isSelected={selectedCreatureUuid === creature.uuid}
            onSelect={onSelect}
            statsMinimized={statsMinimized}
            setStatsMinimized={setStatsMinimized}
            attackFlash={false}
          />
        ))}
      </div>
    </div>
  );
}

function ExplorationContainer({
  explorationZones,
  creatures,
  selectedCreatureUuid,
  onZoneAssign,
  onCreatureSelect,
  statsMinimized,
  setStatsMinimized,
}) {
  const [selectedZoneId, setSelectedZoneId] = useState(null);

  return (
    <div className={styles.explorationZoneContainer}>
      <ZoneBox
        zoneId="forest"
        zoneData={explorationZones.forest}
        zoneIcon={ICON_FRUIT}
        zoneTitle="Forest"
        zoneProgress={explorationZones.forest.currentProgress}
        selectedCreatureUuid={selectedCreatureUuid}
        onAssignCreature={onZoneAssign}
        assignedCreatures={creatures.filter(
          c => c.explorationZone === "forest"
        )}
        onSelect={onCreatureSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
      <ZoneBox
        zoneId="cave"
        zoneData={explorationZones.cave}
        zoneIcon={ICON_MUSHROOM}
        zoneTitle="Cave"
        zoneProgress={explorationZones.cave.currentProgress}
        selectedCreatureUuid={selectedCreatureUuid}
        onAssignCreature={onZoneAssign}
        assignedCreatures={creatures.filter(c => c.explorationZone === "cave")}
        onSelect={onCreatureSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
      <ZoneBox
        zoneId="volcano"
        zoneData={explorationZones.volcano}
        zoneIcon={ICON_FLAME}
        zoneTitle="Volcano"
        zoneProgress={explorationZones.volcano.currentProgress}
        selectedCreatureUuid={selectedCreatureUuid}
        onAssignCreature={onZoneAssign}
        assignedCreatures={creatures.filter(
          c => c.explorationZone === "volcano"
        )}
        onSelect={onCreatureSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
      <ZoneBox
        zoneId="tundra"
        zoneData={explorationZones.tundra}
        zoneIcon={ICON_ICE}
        zoneTitle="Tundra"
        zoneProgress={explorationZones.tundra.currentProgress}
        selectedCreatureUuid={selectedCreatureUuid}
        onAssignCreature={onZoneAssign}
        assignedCreatures={creatures.filter(
          c => c.explorationZone === "tundra"
        )}
        onSelect={onCreatureSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
      <ZoneBox
        zoneId="desert"
        zoneData={explorationZones.desert}
        zoneIcon={ICON_SAND}
        zoneTitle="Desert"
        zoneProgress={explorationZones.desert.currentProgress}
        selectedCreatureUuid={selectedCreatureUuid}
        onAssignCreature={onZoneAssign}
        assignedCreatures={creatures.filter(
          c => c.explorationZone === "desert"
        )}
        onSelect={onCreatureSelect}
        statsMinimized={statsMinimized}
        setStatsMinimized={setStatsMinimized}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
    </div>
  );
}

function RoomContainer({
  roomName,
  backgroundColor,
  creatures,
  onRoomClick,
  selectedCreatureUuid,
  onCreatureSelect,
  arenaWarning,
  setArenaWarning,
  crusherWarning,
  setCrusherWarning,
  gold,
  goldSacks,
  essence,
  selectedItem,
  setSelectedItem,
  statsMinimized,
  setStatsMinimized,
  taskBoardQuests,
  onAssignTaskCreature,
  selectedTaskId,
  setSelectedTaskId,
  attackHighlights,
  playerLevel,
  minesProgress,
  requiredMinesProgress,
  helpWithQuest,
  researchInitiatives,
  onAssignResearchCreature,
  selectedInitiativeId,
  setSelectedInitiativeId,
  limitDust,
  fruit,
  mushroom,
  flame,
  ice,
  sand,
  explorationZones,
  onAssignCreatureToZone,
}) {
  if (roomName === "Inventory") {
    return (
      <div
        className={styles.roomContainer}
        style={{ backgroundColor }}>
        <h3>{roomName}</h3>
        <div style={{ fontStyle: "italic", fontSize: "small" }}>
          {getRoomDescription(roomName)}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
            maxWidth: "330px",
          }}>
          {playerLevel > 0 && (
            <div
              className={
                selectedItem === "playerLevel"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "playerLevel") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("playerLevel");
                }
              }}>
              <img
                src={ICON_LEVEL}
                alt="playerLevel"
                style={{ width: 30, height: 30 }}
              />
              <div>{playerLevel}</div>
              {selectedItem === "playerLevel" && (
                <div className={styles.itemStats}>
                  <div>
                    Player Level - Wave it Around Like a Sign of Authority!
                  </div>
                </div>
              )}
            </div>
          )}
          {gold > 0 && (
            <div
              className={
                selectedItem === "gold"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "gold") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("gold");
                }
              }}>
              <img
                src={ICON_GOLD}
                alt="gold"
                style={{ width: 30, height: 30 }}
              />
              <div>{gold}</div>
              {selectedItem === "gold" && (
                <div className={styles.itemStats}>
                  <div>Gold - For Buying Stuff</div>
                </div>
              )}
            </div>
          )}
          {goldSacks > 0 && (
            <div
              className={
                selectedItem === "goldSacks"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "goldSacks") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("goldSacks");
                }
              }}>
              <img
                src={ICON_SACK}
                alt="gold sacks"
                style={{ width: 30, height: 30 }}
              />
              <div>{goldSacks}</div>
              {selectedItem === "goldSacks" && (
                <div className={styles.itemStats}>
                  <div>Gold Sacks - For Holding More Gold</div>
                </div>
              )}
            </div>
          )}
          {essence > 0 && (
            <div
              className={
                selectedItem === "essence"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "essence") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("essence");
                }
              }}>
              <img
                src={ICON_ESSENCE}
                alt="essence"
                style={{ width: 30, height: 30 }}
              />
              <div>{essence}</div>
              {selectedItem === "essence" && (
                <div className={styles.itemStats}>
                  <div>Essences - Use on a Creature to Level Up Instantly</div>
                </div>
              )}
            </div>
          )}
          {limitDust > 0 && (
            <div
              className={
                selectedItem === "limitDust"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "limitDust") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("limitDust");
                }
              }}>
              <img
                src={ICON_LIMIT_DUST}
                alt="limit dust"
                style={{ width: 30, height: 30 }}
              />
              <div>{limitDust}</div>
              {selectedItem === "limitDust" && (
                <div className={styles.itemStats}>
                  <div>Limit Dust - Use on a Creature to Raise Max Level</div>
                </div>
              )}
            </div>
          )}
          {fruit > 0 && (
            <div
              className={
                selectedItem === "fruit"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "fruit") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("fruit");
                }
              }}>
              <img
                src={ICON_FRUIT}
                alt="fruit"
                style={{ width: 30, height: 30 }}
              />
              <div>{fruit}</div>
              {selectedItem === "fruit" && (
                <div className={styles.itemStats}>
                  <div>Fruit - Collected from the Forest</div>
                </div>
              )}
            </div>
          )}
          {mushroom > 0 && (
            <div
              className={
                selectedItem === "mushroom"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "mushroom") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("mushroom");
                }
              }}>
              <img
                src={ICON_MUSHROOM}
                alt="mushroom"
                style={{ width: 30, height: 30 }}
              />
              <div>{mushroom}</div>
              {selectedItem === "mushroom" && (
                <div className={styles.itemStats}>
                  <div>Mushroom - Collected from the Cave</div>
                </div>
              )}
            </div>
          )}
          {flame > 0 && (
            <div
              className={
                selectedItem === "flame"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "flame") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("flame");
                }
              }}>
              <img
                src={ICON_FLAME}
                alt="flame"
                style={{ width: 30, height: 30 }}
              />
              <div>{flame}</div>
              {selectedItem === "flame" && (
                <div className={styles.itemStats}>
                  <div>Flame - Collected from the Volcano</div>
                </div>
              )}
            </div>
          )}
          {ice > 0 && (
            <div
              className={
                selectedItem === "ice"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "ice") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("ice");
                }
              }}>
              <img
                src={ICON_ICE}
                alt="ice"
                style={{ width: 30, height: 30 }}
              />
              <div>{ice}</div>
              {selectedItem === "ice" && (
                <div className={styles.itemStats}>
                  <div>Ice - Collected from the Tundra</div>
                </div>
              )}
            </div>
          )}
          {sand > 0 && (
            <div
              className={
                selectedItem === "sand"
                  ? `${styles.itemContainer} ${styles.selectedItem}`
                  : styles.itemContainer
              }
              onClick={e => {
                e.stopPropagation();
                if (selectedItem === "sand") {
                  setSelectedItem(null);
                } else {
                  setSelectedItem("sand");
                }
              }}>
              <img
                src={ICON_SAND}
                alt="sand"
                style={{ width: 30, height: 30 }}
              />
              <div>{sand}</div>
              {selectedItem === "sand" && (
                <div className={styles.itemStats}>
                  <div>Sand - Collected from the Desert</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  if (roomName === "Task Board") {
    return (
      <div
        className={styles.roomContainer}
        style={{ backgroundColor }}>
        <h3>{roomName}</h3>
        <div style={{ fontStyle: "italic", fontSize: "small" }}>
          {getRoomDescription(roomName)}
        </div>
        <div>
          {taskBoardQuests.map(task => {
            const assignedCreatures = creatures.filter(
              c => c.questId === task.id
            );
            return (
              <TaskBox
                key={task.id}
                task={task}
                selectedCreatureUuid={selectedCreatureUuid}
                onAssignCreature={onAssignTaskCreature}
                assignedCreatures={assignedCreatures}
                onSelect={onCreatureSelect}
                statsMinimized={statsMinimized}
                setStatsMinimized={setStatsMinimized}
                selectedTaskId={selectedTaskId}
                setSelectedTaskId={setSelectedTaskId}
              />
            );
          })}
        </div>
      </div>
    );
  }
  if (roomName === "Mines") {
    const progressPercent = Math.floor(
      (minesProgress / requiredMinesProgress) * 100
    );
    return (
      <div
        className={styles.roomContainer}
        onClick={() => onRoomClick(roomName)}
        style={{ backgroundColor }}>
        <h3>{roomName}</h3>
        <div style={{ fontStyle: "italic", fontSize: "small" }}>
          {getRoomDescription(roomName)}
        </div>
        <div className={styles.verticalProgressBarMines}>
          <div
            className={styles.verticalProgressFillGold}
            style={{ height: `${progressPercent}%` }}
          />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {creatures.map(creature => (
            <CreatureBox
              key={creature.uuid}
              creature={creature}
              isSelected={selectedCreatureUuid === creature.uuid}
              onSelect={onCreatureSelect}
              statsMinimized={statsMinimized}
              setStatsMinimized={setStatsMinimized}
              attackFlash={!!attackHighlights[creature.uuid]}
            />
          ))}
        </div>
      </div>
    );
  }
  if (roomName === "Research Lab") {
    return (
      <div
        className={styles.roomContainer}
        style={{ backgroundColor }}>
        <h3>{roomName}</h3>
        <div style={{ fontStyle: "italic", fontSize: "small" }}>
          {getRoomDescription(roomName)}
        </div>
        <div>
          {researchInitiatives.map(initiative => {
            const assignedCreatures = creatures.filter(
              c => c.researchId === initiative.id
            );
            return (
              <InitiativeBox
                key={initiative.id}
                initiative={initiative}
                selectedCreatureUuid={selectedCreatureUuid}
                onAssignCreature={onAssignResearchCreature}
                assignedCreatures={assignedCreatures}
                onSelect={onCreatureSelect}
                statsMinimized={statsMinimized}
                setStatsMinimized={setStatsMinimized}
                selectedInitiativeId={selectedInitiativeId}
                setSelectedInitiativeId={setSelectedInitiativeId}
              />
            );
          })}
        </div>
      </div>
    );
  }
  if (roomName === "Exploration") {
    return (
      <div
        className={styles.roomContainer}
        style={{ backgroundColor }}>
        <h3>{roomName}</h3>
        <div style={{ fontStyle: "italic", fontSize: "small" }}>
          {getRoomDescription(roomName)}
        </div>
        <ExplorationContainer
          explorationZones={explorationZones}
          creatures={creatures}
          selectedCreatureUuid={selectedCreatureUuid}
          onZoneAssign={onAssignCreatureToZone}
          onCreatureSelect={onCreatureSelect}
          statsMinimized={statsMinimized}
          setStatsMinimized={setStatsMinimized}
        />
      </div>
    );
  }
  return (
    <div
      className={styles.roomContainer}
      onClick={() => onRoomClick(roomName)}
      style={{ backgroundColor }}>
      <h3>{roomName}</h3>
      <div style={{ fontStyle: "italic", fontSize: "small" }}>
        {getRoomDescription(roomName)}
      </div>
      {roomName !== "Arena" &&
        roomName !== "Breeding" &&
        roomName !== "Market" &&
        roomName !== "Quest" &&
        roomName !== "Task Board" &&
        roomName !== "Research Lab" &&
        roomName !== "Exploration" && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {creatures
              .filter(c => !c.isOpponent)
              .map(creature => (
                <CreatureBox
                  key={creature.uuid}
                  creature={creature}
                  isSelected={selectedCreatureUuid === creature.uuid}
                  onSelect={onCreatureSelect}
                  statsMinimized={statsMinimized}
                  setStatsMinimized={setStatsMinimized}
                  attackFlash={!!attackHighlights[creature.uuid]}
                />
              ))}
          </div>
        )}
      {roomName === "Arena" && (
        <div>
          <div style={{ display: "grid", rowGap: "10px" }}>
            {creatures.filter(c => c.room === "Arena" || c.isOpponent).length >
              0 &&
              renderArenaCreatures(
                creatures,
                selectedCreatureUuid,
                onCreatureSelect,
                statsMinimized,
                setStatsMinimized,
                attackHighlights
              )}
          </div>
          <div style={{ position: "absolute", bottom: "5px", left: "5px" }}>
            <input
              type="checkbox"
              checked={arenaWarning}
              onChange={e => setArenaWarning(e.target.checked)}
            />
            <span style={{ marginLeft: "4px", fontSize: "12px" }}>
              Warn before arena
            </span>
          </div>
        </div>
      )}
      {roomName === "Breeding" && (
        <div>
          <div style={{ display: "grid", rowGap: "10px" }}>
            {creatures.filter(c => c.room === "Breeding").length > 0 &&
              renderBreedingCreatures(
                creatures,
                selectedCreatureUuid,
                onCreatureSelect,
                statsMinimized,
                setStatsMinimized,
                attackHighlights
              )}
          </div>
        </div>
      )}
      {roomName === "Crusher" && (
        <div style={{ position: "absolute", bottom: "5px", left: "5px" }}>
          <input
            type="checkbox"
            checked={crusherWarning}
            onChange={e => setCrusherWarning(e.target.checked)}
          />
          <span style={{ marginLeft: "4px", fontSize: "12px" }}>
            Warn before crushing
          </span>
        </div>
      )}
      {roomName === "Market" && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", margin: 5 }}>
            <button
              className={styles.buyRandomButton}
              onClick={e => {
                e.stopPropagation();
                onRoomClick("buyRandom");
              }}>
              <img
                src={CREATURE_RANDOM}
                style={{ width: "30px", height: "30px" }}
                alt="Hire Random"
              />
            </button>
            <div style={{ textAlign: "center" }}>100</div>
          </div>
          {creatures
            .filter(creature => creature.maxLevel <= playerLevel)
            .map(creature => (
              <CreatureBox
                key={creature.uuid}
                creature={creature}
                isSelected={selectedCreatureUuid === creature.uuid}
                onSelect={onCreatureSelect}
                statsMinimized={statsMinimized}
                setStatsMinimized={setStatsMinimized}
                attackFlash={!!attackHighlights[creature.uuid]}
              />
            ))}
        </div>
      )}
      {roomName === "Quest" && (
        <div
          style={{ display: "grid", rowGap: "10px" }}
          onClick={() => onRoomClick(roomName)}>
          {renderQuestCreatures(
            creatures.filter(c => !c.isOpponent),
            selectedCreatureUuid,
            onCreatureSelect,
            statsMinimized,
            setStatsMinimized,
            attackHighlights,
            helpWithQuest
          )}
        </div>
      )}
    </div>
  );
}

export default function DungeonEnjoyers() {
  const user = useSelector(store => store.user);
  const [ws, setWs] = useState(null);
  const [gold, setGold] = useState(0);
  const [goldSacks, setGoldSacks] = useState(0);
  const [essence, setEssence] = useState(0);
  const [limitDust, setLimitDust] = useState(0);
  const [fruit, setFruit] = useState(0);
  const [mushroom, setMushroom] = useState(0);
  const [flame, setFlame] = useState(0);
  const [ice, setIce] = useState(0);
  const [sand, setSand] = useState(0);
  const [playerCreatures, setPlayerCreatures] = useState([]);
  const [selectedCreatureUuid, setSelectedCreatureUuid] = useState(null);
  const [crusherWarning, setCrusherWarning] = useState(true);
  const [arenaWarning, setArenaWarning] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [statsMinimized, setStatsMinimized] = useState(false);
  const [taskBoardQuests, setTaskBoardQuests] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [researchInitiatives, setResearchInitiatives] = useState([]);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState(null);
  const [rooms, setRooms] = useState(defaultRooms);
  const [unlockedRooms, setUnlockedRooms] = useState(defaultUnlockedRooms);
  const [serverRoomOrder, setServerRoomOrder] = useState([]);
  const [arenaAttackHighlights, setArenaAttackHighlights] = useState({});
  const [playerLevel, setPlayerLevel] = useState(1);
  const [minesProgress, setMinesProgress] = useState(0);
  const [requiredMinesProgress, setRequiredMinesProgress] = useState(100);

  // exploration
  const [explorationZones, setExplorationZones] = useState({
    forest: { currentProgress: 0 },
    cave: { currentProgress: 0 },
    volcano: { currentProgress: 0 },
    tundra: { currentProgress: 0 },
    desert: { currentProgress: 0 },
  });

  useEffect(() => {
    let socket;
    let environment;
    if (location.href.includes("localhost")) {
      environment = "localhost";
    } else if (location.href.includes("192.168.0")) {
      environment = "localNetwork";
    } else if (location.href.includes("2nguyen.dev")) {
      environment = "production";
    }
    switch (environment) {
      case "localhost":
        socket = new WebSocket("ws://localhost:5000/dungeon-enjoyers");
        break;
      case "localNetwork":
        socket = new WebSocket("ws://192.168.0.7:5000/dungeon-enjoyers");
        break;
      case "production":
        socket = new WebSocket("wss://2nguyen.dev/dungeon-enjoyers");
        break;
      default:
        return;
    }
    window.dungeonEnjoyersSocket = socket;
    setWs(socket);
    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          type: "CONNECT",
          userId: user.id,
          username: user.username,
        })
      );
    };
    socket.onclose = () => {
      setWs(null);
    };
    socket.onmessage = event => {
      const data = JSON.parse(event.data);
      if (data.type === "UPDATE_GOLD") {
        setGold(data.gold);
        if (data.goldSacks !== undefined) {
          setGoldSacks(data.goldSacks);
        }
        if (data.essence !== undefined) {
          setEssence(data.essence);
        }
        if (data.playerLevel !== undefined) {
          setPlayerLevel(data.playerLevel);
        }
        if (data.minesProgress !== undefined) {
          setMinesProgress(data.minesProgress);
        }
        if (data.requiredMinesProgress !== undefined) {
          setRequiredMinesProgress(data.requiredMinesProgress);
        }
        if (data.limitDust !== undefined) {
          setLimitDust(data.limitDust);
        }
        if (data.fruit !== undefined) {
          setFruit(data.fruit);
        }
        if (data.mushroom !== undefined) {
          setMushroom(data.mushroom);
        }
        if (data.flame !== undefined) {
          setFlame(data.flame);
        }
        if (data.ice !== undefined) {
          setIce(data.ice);
        }
        if (data.sand !== undefined) {
          setSand(data.sand);
        }
      }
      if (data.type === "UPDATE_CREATURES") {
        setPlayerCreatures(data.creatures);
      }
      if (data.type === "UPDATE_UNLOCKED_ROOMS") {
        setUnlockedRooms(data.rooms);
      }
      if (data.type === "ERROR") {
        Swal.fire({
          title: "Error",
          text: data.message,
          icon: "error",
        });
      }
      if (data.type === "UPDATE_TASK_BOARD_QUESTS") {
        setTaskBoardQuests(data.quests);
      }
      if (data.type === "UPDATE_ROOM_ORDER") {
        setServerRoomOrder(data.roomOrder || []);
      }
      if (data.type === "ARENA_ATTACK") {
        setArenaAttackHighlights(prev => {
          const attacker = data.attackerUuid;
          const newMap = { ...prev };
          newMap[attacker] = true;
          return newMap;
        });
        setTimeout(() => {
          setArenaAttackHighlights(prev => {
            const attacker = data.attackerUuid;
            const newMap = { ...prev };
            delete newMap[attacker];
            return newMap;
          });
        }, 1000);
      }
      if (data.type === "UPDATE_RESEARCH_INITIATIVES") {
        setResearchInitiatives(data.researchInitiatives);
      }
      if (data.type === "UPDATE_EXPLORATION_ZONES") {
        setExplorationZones(data.explorationZones);
      }
    };
    return () => {
      if (socket) socket.close();
    };
  }, [user]);

  useEffect(() => {
    if (!serverRoomOrder || !serverRoomOrder.length) return;
    const newRooms = [...rooms].sort((a, b) => {
      let iA = serverRoomOrder.indexOf(a.name);
      let iB = serverRoomOrder.indexOf(b.name);
      if (iA === -1) iA = 9999;
      if (iB === -1) iB = 9999;
      return iA - iB;
    });
    setRooms(newRooms);
  }, [serverRoomOrder]);

  function helpWithQuest(questId) {
    if (!selectedCreatureUuid || !ws) return;
    const helper = playerCreatures.find(c => c.uuid === selectedCreatureUuid);
    if (!helper) return;
    if (helper.questId) return;
    ws.send(
      JSON.stringify({
        type: "HELP_CREATURE_QUEST",
        userId: user.id,
        creatureUuid: selectedCreatureUuid,
        questId,
      })
    );
    setSelectedCreatureUuid(null);
  }

  function onAssignTaskCreature(taskId) {
    if (!selectedCreatureUuid || !ws) return;
    ws.send(
      JSON.stringify({
        type: "HELP_CREATURE_TASK",
        userId: user.id,
        creatureUuid: selectedCreatureUuid,
        taskId,
      })
    );
    setSelectedCreatureUuid(null);
    setSelectedTaskId(null);
  }

  function onAssignResearchCreature(initiativeId) {
    if (!selectedCreatureUuid || !ws) return;
    ws.send(
      JSON.stringify({
        type: "HELP_CREATURE_RESEARCH",
        userId: user.id,
        creatureUuid: selectedCreatureUuid,
        initiativeId,
      })
    );
    setSelectedCreatureUuid(null);
    setSelectedInitiativeId(null);
  }

  function onAssignCreatureToZone(zoneId) {
    if (!selectedCreatureUuid || !ws) return;
    ws.send(
      JSON.stringify({
        type: "HELP_CREATURE_EXPLORATION",
        userId: user.id,
        creatureUuid: selectedCreatureUuid,
        zoneId,
      })
    );
    setSelectedCreatureUuid(null);
  }

  function handleSpecialRoomWarnings(room) {
    if (!selectedCreatureUuid || !ws) return;
    if (room === "Market" && playerLevel < 3) {
      Swal.fire({
        title: "Cannot Sell Yet",
        text: "You must be at least level 3 to sell a creature on the Market!",
        icon: "error",
      });
      return;
    }
    if (room === "Crusher" && crusherWarning) {
      Swal.fire({
        title: "Are you sure?",
        text: "This creature will be crushed after 10 seconds and removed forever!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, place in Crusher",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          sendCreatureRoom(room);
        }
      });
      return;
    }
    if (room === "Arena" && arenaWarning) {
      Swal.fire({
        title: "Enter the Arena?",
        text: "This creature will not be retrievable until it is defeated!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, place in Arena",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          sendCreatureRoom(room);
        }
      });
      return;
    }
    if (room === "Market") {
      Swal.fire({
        title: "List Creature for Sale",
        text: "Enter the gold price for this creature:",
        input: "number",
        inputAttributes: {
          min: 1,
          step: 1,
        },
        showCancelButton: true,
        confirmButtonText: "List for Sale",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          const price = parseInt(result.value, 10);
          if (price > 0) {
            sendCreatureRoom(room, price);
          }
        }
      });
      return;
    }
    sendCreatureRoom(room);
  }

  function placeCreatureInRoom(room) {
    if (room === "buyRandom") {
      if (!ws) return;
      window.dungeonEnjoyersSocket.send(
        JSON.stringify({
          type: "BUY_CREATURE",
          userId: user.id,
        })
      );
      return;
    }
    if (!selectedCreatureUuid || !ws) return;
    if (room === "Inventory") return;
    const creature = playerCreatures.find(c => c.uuid === selectedCreatureUuid);
    if (!creature) return;
    if (creature.ownerId !== user.id) return;
    if (room === "Breeding") {
      if (creature.bredAmount >= creature.breedLimit) {
        Swal.fire({
          title: "Cannot breed",
          text: "This creature has reached its breed limit.",
          icon: "error",
        });
        return;
      }
    }
    if (creature.room === "Arena" && room !== "Arena") return;
    if (creature.room === "Arena" && room === "Arena") return;
    if (creature.room === "Quest" && room !== "Quest" && creature.questId) {
      Swal.fire({
        title: "Abandon quest?",
        text: "The quest will lose all progress if you move this creature.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          handleSpecialRoomWarnings(room);
        }
      });
    } else {
      handleSpecialRoomWarnings(room);
    }
  }

  function sendCreatureRoom(room, marketPrice) {
    if (!selectedCreatureUuid || !ws) return;
    ws.send(
      JSON.stringify({
        type: "PLACE_CREATURE_IN_ROOM",
        userId: user.id,
        creatureUuid: selectedCreatureUuid,
        room,
        marketPrice: marketPrice || 0,
      })
    );
    setSelectedCreatureUuid(null);
  }

  function handleCreatureClick(creatureUuid) {
    const clickedCreature = playerCreatures.find(c => c.uuid === creatureUuid);
    if (!clickedCreature) return;
    if (selectedItem === "essence") {
      if (!ws || essence <= 0) return;
      if (clickedCreature.isEgg) {
        Swal.fire({
          title: "Cannot Use Essence",
          text: "Essences cannot be used on an egg.",
          icon: "error",
        });
        return;
      }
      if (clickedCreature.ownerId !== user.id) return;
      if (
        ["Arena", "Quest", "Crusher", "Breeding", "Market"].includes(
          clickedCreature.room
        )
      ) {
        Swal.fire({
          title: "Cannot Use Essence",
          text: "Essences cannot be used on a creature in Arena, Quest, Crusher, Breeding, or Market.",
          icon: "error",
        });
        return;
      }
      ws.send(
        JSON.stringify({
          type: "USE_ESSENCE_ON_CREATURE",
          userId: user.id,
          creatureUuid,
        })
      );
      setSelectedItem(null);
      return;
    }
    if (selectedItem === "limitDust") {
      if (!ws || limitDust <= 0) return;
      if (clickedCreature.isEgg) {
        Swal.fire({
          title: "Cannot Use Limit Dust",
          text: "Limit Dust cannot be used on an egg.",
          icon: "error",
        });
        return;
      }
      if (clickedCreature.ownerId !== user.id) return;
      if (
        ["Arena", "Quest", "Crusher", "Breeding", "Market"].includes(
          clickedCreature.room
        )
      ) {
        Swal.fire({
          title: "Cannot Use Limit Dust",
          text: "Limit Dust cannot be used on a creature in Arena, Quest, Crusher, Breeding, or Market.",
          icon: "error",
        });
        return;
      }
      ws.send(
        JSON.stringify({
          type: "USE_LIMIT_DUST_ON_CREATURE",
          userId: user.id,
          creatureUuid,
        })
      );
      setSelectedItem(null);
      return;
    }
    if (selectedCreatureUuid === creatureUuid) {
      setSelectedCreatureUuid(null);
      return;
    }
    const currentlySelected = playerCreatures.find(
      c => c.uuid === selectedCreatureUuid
    );
    if (
      currentlySelected &&
      !currentlySelected.questId &&
      clickedCreature &&
      clickedCreature.questId &&
      clickedCreature.room === "Quest"
    ) {
      helpWithQuest(clickedCreature.questId);
    } else {
      setSelectedCreatureUuid(creatureUuid);
    }
  }

  function handleDragStart(e, index) {
    e.dataTransfer.setData("text/plain", index);
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, dropIndex) {
    const dragIndex = e.dataTransfer.getData("text/plain");
    if (dragIndex === "") return;
    const updatedRooms = [...rooms];
    const [draggedRoom] = updatedRooms.splice(dragIndex, 1);
    updatedRooms.splice(dropIndex, 0, draggedRoom);
    setRooms(updatedRooms);
    if (ws) {
      ws.send(
        JSON.stringify({
          type: "UPDATE_ROOM_ORDER",
          userId: user.id,
          roomOrder: updatedRooms.map(r => r.name),
        })
      );
    }
  }

  return (
    <div style={{ color: "white" }}>
      <div className={styles.roomGrid}>
        {rooms.map((room, index) => {
          if (!unlockedRooms.includes(room.name)) {
            return null;
          }
          const creatures = playerCreatures.filter(
            c =>
              c.room === room.name ||
              (room.name === "Arena" && c.isOpponent) ||
              (room.name === "Market" && c.room === "Market") ||
              (room.name === "Exploration" && c.room === "Exploration")
          );
          return (
            <div
              key={room.name}
              draggable
              onDragStart={e => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={e => handleDrop(e, index)}>
              <RoomContainer
                roomName={room.name}
                backgroundColor={room.backgroundColor}
                creatures={creatures}
                onRoomClick={placeCreatureInRoom}
                selectedCreatureUuid={selectedCreatureUuid}
                onCreatureSelect={handleCreatureClick}
                arenaWarning={arenaWarning}
                setArenaWarning={setArenaWarning}
                crusherWarning={crusherWarning}
                setCrusherWarning={setCrusherWarning}
                gold={gold}
                goldSacks={goldSacks}
                essence={essence}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                statsMinimized={statsMinimized}
                setStatsMinimized={setStatsMinimized}
                taskBoardQuests={taskBoardQuests}
                onAssignTaskCreature={onAssignTaskCreature}
                selectedTaskId={selectedTaskId}
                setSelectedTaskId={setSelectedTaskId}
                attackHighlights={arenaAttackHighlights}
                playerLevel={playerLevel}
                minesProgress={minesProgress}
                requiredMinesProgress={requiredMinesProgress}
                helpWithQuest={helpWithQuest}
                researchInitiatives={researchInitiatives}
                onAssignResearchCreature={onAssignResearchCreature}
                selectedInitiativeId={selectedInitiativeId}
                setSelectedInitiativeId={setSelectedInitiativeId}
                limitDust={limitDust}
                fruit={fruit}
                mushroom={mushroom}
                flame={flame}
                ice={ice}
                sand={sand}
                explorationZones={explorationZones}
                onAssignCreatureToZone={onAssignCreatureToZone}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
