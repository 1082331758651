import React from "react";
import styles from "./DungenEnjoyers.module.scss";

export default function CreatureStats({
  creature,
  statsMinimized,
  setStatsMinimized,
  user,
  handleBuy,
}) {
  return (
    <div
      className={styles.creatureStats}
      style={{
        maxHeight: statsMinimized ? 55 : 450,
        overflowY: statsMinimized ? "hidden" : "auto",
      }}>
      <div className={styles.creatureStatsHeader}>
        <div style={{ fontWeight: "bold" }}>
          {creature.type.toUpperCase()}{" "}
          {creature.gender === "male"
            ? " ♂"
            : creature.gender === "female"
            ? " ♀"
            : ""}{" "}
          (LV{creature.level})
        </div>
        <button
          className={styles.toggleButton}
          onClick={e => {
            e.stopPropagation();
            setStatsMinimized(!statsMinimized);
          }}>
          {statsMinimized ? "+" : "-"}
        </button>
      </div>
      {!statsMinimized && (
        <div className={styles.creatureStatsContent}>
          <div>Manager: {creature.managerName || "???"}</div>

          {creature.managerName !== creature.originalManagerName && (
            <div>Original Manager: {creature.originalManagerName || "???"}</div>
          )}

          <div>
            Level (Max Level): {creature.level} / {creature.maxLevel}
          </div>
          <div>
            Current XP / XP to Level: {creature.currentXP} /{" "}
            {creature.requiredXP}
          </div>
          <div>
            HP: {creature.currentHealth} / {creature.maximumHealth}
          </div>
          <div>
            MP: {creature.currentMana} / {creature.maximumMana}
          </div>
          <div>ATK: {creature.attack}</div>
          <div>STR: {creature.strength}</div>
          <div>DEX: {creature.dexterity}</div>
          <div>WIS: {creature.wisdom}</div>
          <div>LUK: {creature.luck}</div>
          <div>Attack Type: {creature.attackType}</div>
          <div>Happiness: {creature.happiness}</div>
          {creature.breedingPartner && creature.totalBreedingTime > 0 && (
            <div>
              Breeding: {creature.currentBreedingTime}/
              {creature.totalBreedingTime}
            </div>
          )}
          {creature.isEgg && (
            <div>
              Incubation: {creature.currentIncubation}/
              {creature.requiredIncubation}
            </div>
          )}
          <div>
            Breed Amount (Limit): {creature.bredAmount} / {creature.breedLimit}
          </div>
          <br />

          {creature.quest && (
            <>
              <div>Quest: {creature.quest.name}</div>
              <div>
                Progress: {creature.quest.currentProgress}/
                {creature.quest.requiredProgress}
              </div>
            </>
          )}

          {creature.room === "Arena" && (
            <div>
              Attack Counter: {creature.currentAttackCounter} /{" "}
              {creature.requiredAttackCounter}
            </div>
          )}
          <div>Arena Wins: {creature.arenaWins || 0}</div>
          <div>Arena Losses: {creature.arenaLosses || 0}</div>
          <div>Highest Win Streak: {creature.highestWinStreak || 0}</div>
          {creature.arenaOpponent && <div>Matched in battle!</div>}
          {!creature.arenaOpponent && creature.room === "Arena" && (
            <div>Currently waiting to match...</div>
          )}
          {creature.room === "Market" && creature.marketPrice > 0 && (
            <div>Price: {creature.marketPrice} gold</div>
          )}
          {creature.room === "Market" &&
            creature.marketPrice > 0 &&
            creature.ownerId !== user.id && (
              <button onClick={handleBuy}>Buy</button>
            )}
        </div>
      )}
    </div>
  );
}
