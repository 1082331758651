import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Phaser from "phaser";
import NotForagerMainMenuScene from "./NotForagerMainMenuScene.js";
import NotForagerWorldScene from "./NotForagerWorldScene.js";
import "./NotForager.css";

const NotForager = () => {
  const user = useSelector(store => store.user);

  useEffect(() => {
    const mainMenuScene = new NotForagerMainMenuScene(user);

    const config = {
      type: Phaser.AUTO,
      width: 1080,
      height: 720,
      physics: {
        default: "matter",
      },
      scene: [mainMenuScene, NotForagerWorldScene],
    };

    const game = new Phaser.Game(config);

    return () => {
      game.destroy(true);
    };
  }, [user]);

  return (
    <div
      id="phaser-game"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default NotForager;
