export const localProfaneWords = [
  "ass",
  "asshole",
  "assholes",
  "bitch",
  "bitches",
  "cock",
  "cockhead",
  "cockheads",
  "cum",
  "cunt",
  "cunts",
  "dick",
  "fag",
  "faggot",
  "faggots",
  "fuck",
  "fucker",
  "fuckers",
  "fucking",
  "fucks",
  "gay",
  "gays",
  "homo",
  "homosexual",
  "homosexuals",
  "jizz",
  "lesbian",
  "lesbians",
  "nigger",
  "niggers",
  "nigga",
  "niggas",
  "niggle",
  "niggles",
  "nipple",
  "nipples",
  "pussy",
  "pussies",
  "retard",
  "retards",
  "scrotum",
  "scrotums",
  "shit",
  "slut",
  "sluts",
  "twat",
  "twats",
  "vagina",
  "vaginas",
  "wank",
  "wanks",
  "wetback",
  "wetbacks",
  "whore",
  "whores",
];
