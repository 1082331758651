import React from "react";

function PlantExchangeMarket({ market, globalScore, onBuyMarketSeed }) {
  return (
    <div
      style={{ border: "1px solid black", padding: "10px", marginTop: "20px" }}>
      <h2>Market</h2>
      {market.length === 0 && <div>No seeds on the market</div>}
      {market.map(listing => (
        <div
          key={listing.id}
          style={{
            marginBottom: "10px",
            backgroundColor: listing.seed.hexColor,
            color: "black",
          }}>
          <div>
            Seed: {listing.seed.name} | Quality: {listing.seed.quality} | Grow
            Time: {listing.seed.requiredGrowTime} | Harvest:{" "}
            {listing.seed.seedHarvestAmount} | Listed by: {listing.ownerName} |
            Price: {listing.price} |{" "}
            <button onClick={() => onBuyMarketSeed(listing.id)}>Buy</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlantExchangeMarket;
